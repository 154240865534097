import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Content from "../../../../ui/Content/Content";
import ContentBlock from "../../../../ui/ContentBlock/ContentBlock";
import Label from "../../../../ui/FormField/Label";
import SingleValueForm from "../../../../hocs/SingleValueForm/SingleValueForm";
import PasswordInput from "../../../../ui/FormField/PasswordInput";
import Button from "../../../../ui/Button/Button";

const LoginSecurityCode = ({ children, deviceName, onSubmit }) => (
  <SingleValueForm
    onSubmit={onSubmit}
    render={({ invalid, value, onChange }) => (
      <Content data-testid="security-code-screen">
        <ContentBlock>
          <Label forId={"security-code"}>
            <FormattedMessage
              defaultMessage="Enter your {deviceName} code"
              values={{
                deviceName
              }}
            />
          </Label>
          <PasswordInput
            data-testid="security-code"
            id="security-code"
            onChange={event => onChange(event.target.value)}
            value={value}
            invalid={invalid}
            autoFocus
            size="large"
          />
        </ContentBlock>
        <ContentBlock>
          <Button size="large" type="submit">
            <FormattedMessage defaultMessage="Continue" />
          </Button>
        </ContentBlock>
        {children && <ContentBlock>{children}</ContentBlock>}
      </Content>
    )}
  />
);

LoginSecurityCode.displayName = "LoginSecurityCode";

LoginSecurityCode.propTypes = {
  deviceName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default LoginSecurityCode;
