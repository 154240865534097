import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import COLORS from "../../../constants/colors";
import FONTS from "../../../constants/fonts";
import SIZES from "../../../constants/sizes";
import getSizingDefinitions from "../../utils/getSizingDefinitions";

const heightMap = {
  [SIZES.MEDIUM]: 28,
  [SIZES.LARGE]: 38,
  [SIZES.BIG]: 38
};

const StyledButton = styled.button`
  ${props => getSizingDefinitions(props.size, heightMap)};
  background-color: transparent;
  padding: 0;
  border: none;
  border-radius: 2px;
  color: ${COLORS.TEXT_COLORS.LINK};
  cursor: pointer;
  font-family: ${FONTS.FONT_FAMILY.DEFAULT};
  line-height: 1;
  box-sizing: content-box;
  text-align: left;
  padding: 4px 0;

  &:hover,
  &:focus {
    color: ${COLORS.ACTION_COLORS.BLUE_MAIN_HOVER};
  }

  &:active {
    color: ${COLORS.ACTION_COLORS.BLUE_MAIN_ACTIVE};
  }
`;

const LinkButton = ({ size = SIZES.MEDIUM, ...props }) => <StyledButton type="button" {...props} size={size} />;

LinkButton.propTypes = {
  /** onClick handler function */
  onClick: PropTypes.func.isRequired,
  /** link button text size */
  size: PropTypes.oneOf([SIZES.MEDIUM, SIZES.LARGE])
};

export default LinkButton;
