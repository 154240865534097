import React, { Component } from "react";
import PropTypes from "prop-types";

import ScanBarCode from "./ScanBarCode";
import CopyCodeManually from "./CopyCodeManually";

class PairProtectOrAuthenticator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showScanBarCode: true
    };
  }

  toggleQR = event => {
    event.preventDefault();
    this.setState({
      showScanBarCode: !this.state.showScanBarCode
    });
  };

  render = () => {
    const { code, qrLink, onContinue, appName, condensedCode } = this.props;
    return this.state.showScanBarCode ? (
      <ScanBarCode
        appName={appName}
        code={qrLink}
        onCantScan={this.toggleQR}
        onContinue={onContinue}
      />
    ) : (
      <CopyCodeManually
        appName={appName}
        code={code}
        condensedCode={condensedCode}
        onScanBarCode={this.toggleQR}
        onContinue={onContinue}
      />
    );
  };
}

PairProtectOrAuthenticator.displayName = "PairProtectOrAuthenticator";

PairProtectOrAuthenticator.propTypes = {
  appName: PropTypes.string.isRequired,
  qrLink: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  condensedCode: PropTypes.bool
};

export default PairProtectOrAuthenticator;
