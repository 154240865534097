import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Input from "./Input";
import Icon from "../Icon/Icon";
import SIZES from "../../../constants/sizes";
import COLORS from "../../../constants/colors";
import { defineMessages, injectIntl } from "react-intl";

const messages = defineMessages({
  confirm: {
    defaultMessage: "confirm"
  },
  revert: {
    defaultMessage: "revert"
  },
  cancel: {
    defaultMessage: "cancel"
  }
});

const EditContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 4px;
  width: 100%;
`;

const ControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const buttonSizeMap = {
  [SIZES.MEDIUM]: 32,
  [SIZES.LARGE]: 42,
  [SIZES.BIG]: 42
};

const Button = styled.button`
  height: ${props => buttonSizeMap[props.size]}px;
  width: ${props => buttonSizeMap[props.size]}px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  border: none;
  padding: 3px;
  cursor: pointer;

  & img {
    cursor: pointer;
  }
`;

const CheckmarkButton = styled(Button)`
  background-color: #5da40d;
`;

const CloseButton = styled(Button)`
  background-color: #d62a1b;
`;

const RevertButton = styled(Button)`
  background-color: ${COLORS.ACTION_COLORS.BLUE_MAIN};
`;

class InlineTextEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updateValue: props.value
    };

    this.containerRef = createRef();
  }

  // This ensures that the event listener is only active when the component is in edit mode
  updateListener = editMode => {
    if (editMode) {
      document.addEventListener("mousedown", this.handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", this.handleOutsideClick);
    }
  };

  // Originally, this component used the `onblur` property to fire a callback when focus was removed from
  // the input element. However, when doing so in Firefox and IE11, the `event.relatedTarget` would be null
  // if the object being clicked on didn't gain focus. Because of that, the component couldn't check if
  // the click was directed at one of the InlineTextEdit buttons, which meant that they would never fire.
  // It isn't as clean, but by using the mousedown event, we can handle lost focus from the input in Firefox/IE11.
  handleOutsideClick = event => {
    if (this.containerRef && !this.containerRef.current.contains(event.target)) {
      this.props.saveOnBlur
        ? this.props.onSave && this.props.onSave(this.state.updateValue)
        : this.props.onCancel && this.props.onCancel();
      }
  }

  componentDidUpdate = prevProps => {
    const newEditMode = this.props.editMode;
    
    if (prevProps.editMode !== newEditMode) {
      this.setState({ updateValue: this.props.value });
      this.updateListener(newEditMode);
    }
  };

  componentDidMount = () => {
    this.updateListener(this.props.editMode);
  }

  componentWillUnmount = () => {
    this.updateListener(false);
  }

  onKeyDown = ({ key }) => {
    const { onSave, onCancel } = this.props;
    if (key === "Enter") {
      onSave && onSave(this.state.updateValue);
    }
    if (key === "Escape") {
      onCancel && onCancel();
    }
  };

  render = () => {
    const {
      id,
      value,
      editMode,
      render,
      onSave,
      onCancel,
      onRevert,
      size,
      intl,
      ...inputProps
    } = this.props;

    const updateValue = this.state.updateValue;

    if (editMode) {
      return (
        <EditContainer ref={this.containerRef}>
          <Input
            id={id}
            value={updateValue}
            onChange={event =>
              this.setState({ updateValue: event.target.value })
            }
            onKeyDown={this.onKeyDown}
            {...inputProps}
            size={size}
          />
          <ControlContainer>
            <CheckmarkButton
              onClick={() => onSave && onSave(updateValue)}
              size={size}
            >
              <Icon
                icon="Checkmark-16-thick"
                alt={intl.formatMessage(messages.confirm)}
                size={"16px"}
              />
            </CheckmarkButton>
            <CloseButton onClick={() => onCancel && onCancel()} size={size}>
              <Icon
                icon="Close-16-thick"
                alt={intl.formatMessage(messages.cancel)}
                size={"16px"}
              />
            </CloseButton>
            {onRevert && (
              <RevertButton onClick={() => onRevert && onRevert()} size={size}>
                <Icon
                  icon="Revert-16"
                  alt={intl.formatMessage(messages.revert)}
                  size={"16px"}
                />
              </RevertButton>
            )}
          </ControlContainer>
        </EditContainer>
      );
    }

    return render(value);
  };
}

InlineTextEdit.defaultProps = {
  saveOnBlur: false,
  autoFocus: false,
  disabled: false,
  invalid: false,
  autoComplete: "off",
  autoCorrect: "off",
  spellCheck: "false",
  large: false,
  maxLength: 255,
  focused: false,
  locked: false,
  size: SIZES.MEDIUM
};

InlineTextEdit.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  editMode: PropTypes.bool.isRequired,
  render: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onRevert: PropTypes.func,
  saveOnBlur: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoCorrect: PropTypes.string,
  spellCheck: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  autoCapitalize: PropTypes.string,
  size: PropTypes.oneOf([SIZES.MEDIUM, SIZES.LARGE, SIZES.BIG]),
  focused: PropTypes.bool,
  locked: PropTypes.bool
};

export default injectIntl(InlineTextEdit);
