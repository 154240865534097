import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { defineMessages, injectIntl } from "react-intl";
import COLORS from "../../../constants/colors";
import ToggleTooltip from "../../ui/Tooltip/ToggleTooltip";
import Icon from "../../ui/Icon/Icon";

const ICON_IMAGE_SIZE = 24;
const ICON_MARGIN = 16;
const LIST_HEIGHT = 52;
const COLUMN_VISIBLE_ROWS = 2;
const LOGIN_FACTOR_LOGO = "Key-24";
const PW_RESET_FACTOR_LOGO = "Reset-Password-24";
const APP_FACTOR_LOGO = "App-24";

const messages = defineMessages({
  loginFactor: {
    defaultMessage: "This factor is used for login."
  },
  pwResetFactor: {
    defaultMessage: "This factor is used for password reset."
  },
  appFactor: {
    defaultMessage: "This factor is used for app."
  }
});

const Container = styled.a`
  display: flex;
  flex-direction: row;
  width: auto;
  height: ${LIST_HEIGHT}px;
  cursor: pointer;
  border-bottom: 1px solid ${COLORS.SEPARATOR_COLORS.REGULAR};
  font-size: 16px;
  color: ${COLORS.TEXT_COLORS.DEFAULT};
  padding-right: 24px;
  padding-left: 24px;
  flex-shrink: 0;
  outline-offset: -5px;
  text-decoration: none;
  align-items: center;

  &:hover {
    background-color: ${COLORS.ONELOGIN_GRAY_COLORS.ONELOGIN_GRAY_LIGHT};
  }

  &:first-child {
    border-top: 1px solid ${COLORS.SEPARATOR_COLORS.MEDIUM};
  }

  &:last-child {
    border-bottom: 1px solid ${COLORS.SEPARATOR_COLORS.MEDIUM};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex: 0 0 ${ICON_IMAGE_SIZE + ICON_MARGIN}px;
`;

const DeviceIcon = styled.img`
  height: ${ICON_IMAGE_SIZE}px;
  width: ${ICON_IMAGE_SIZE}px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  padding-right: ${ICON_MARGIN}px;
  overflow: hidden;
  max-height: 40px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: ${COLUMN_VISIBLE_ROWS};
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const FactorIcons = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  height: 100%;
`;

const FactorListItem = ({
  icon,
  text,
  onClick,
  isLoginFactor,
  isPasswordResetFactor,
  isAppFactor,
  isLastItem,
  intl
}) => (
  <Container
    href="#"
    onClick={event => {
      event.preventDefault();
      onClick();
    }}
    tabIndex="0"
  >
    {icon && (
      <IconWrapper>
        <DeviceIcon src={icon} alt={text} />
      </IconWrapper>
    )}
    <TextWrapper title={text}>{text}</TextWrapper>
    <FactorIcons>
      {isLoginFactor && (
        <ToggleTooltip
          dir={isLastItem ? "top" : "bottom"}
          lean="left"
          info={intl.formatMessage(messages.loginFactor)}
        >
          <Icon name={LOGIN_FACTOR_LOGO} size="24px" />
        </ToggleTooltip>
      )}
      {isPasswordResetFactor && (
        <ToggleTooltip
          dir={isLastItem ? "top" : "bottom"}
          lean="left"
          info={intl.formatMessage(messages.pwResetFactor)}
        >
          <Icon name={PW_RESET_FACTOR_LOGO} size="24px" />
        </ToggleTooltip>
      )}
      {isAppFactor && (
        <ToggleTooltip
          dir={isLastItem ? "top" : "bottom"}
          lean="left"
          info={intl.formatMessage(messages.appFactor)}
        >
          <Icon name={APP_FACTOR_LOGO} size="24px" />
        </ToggleTooltip>
      )}
    </FactorIcons>
  </Container>
);

FactorListItem.propTypes = {
  /** Optional icon to render */
  icon: PropTypes.string,
  /** Text displayed in item */
  text: PropTypes.string.isRequired,
  /** On item click handler */
  onClick: PropTypes.func.isRequired,
  /** Show login factor icon */
  isLoginFactor: PropTypes.bool,
  /** Show password reset factor icon */
  isPasswordResetFactor: PropTypes.bool,
  /** Show app factor icon */
  isAppFactor: PropTypes.bool
};

FactorListItem.defaultProps = {
  configureIcon: "Plus-24"
};

export default injectIntl(FactorListItem);
