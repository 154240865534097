import React, { Children } from "react";
import styled from "styled-components";
import COLORS from "../../../constants/colors";

const StyledList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
`;

const StyledItem = styled.li`
  flex-basis: auto;
  padding: 0 16px;
  text-align: center;
  position: relative;

  &:not(:first-child)::after {
    content: "";
    background: ${COLORS.SEPARATOR_COLORS.MEDIUM};
    position: absolute;
    bottom: 25%;
    left: 0;
    height: 50%;
    width: 1px;
  }
`;

const LinksList = ({ children }) => (
  <StyledList>
    {Children.map(children, child => child && <StyledItem>{child}</StyledItem>)}
  </StyledList>
);

LinksList.propTypes = {};

export default LinksList;
