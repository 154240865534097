import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import COLORS from "../../../constants/colors";

import { svgList } from "../Icon/svgList";

const ICON_IMAGE_SIZE = 24;
const ICON_MARGIN = 16;
const LIST_HEIGHT = 56;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  height: ${LIST_HEIGHT}px;
  cursor: pointer;
  border-bottom: 1px solid ${COLORS.SEPARATOR_COLORS.REGULAR};
  font-size: 16px;
  color: ${COLORS.TEXT_COLORS.DEFAULT};
  overflow: hidden;
  padding-right: 24px;
  padding-left: 24px;
  flex-shrink: 0;

  &:hover {
    background-color: ${COLORS.ONELOGIN_GRAY_COLORS.ONELOGIN_GRAY_LIGHT};
  }

  &:first-child {
    border-top: 1px solid ${COLORS.SEPARATOR_COLORS.MEDIUM};
  }

  &:last-child {
    border-bottom: 1px solid ${COLORS.SEPARATOR_COLORS.MEDIUM};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex: 0 0 ${ICON_IMAGE_SIZE + ICON_MARGIN}px;
`;

const Icon = styled.img`
  height: ${ICON_IMAGE_SIZE}px;
  width: ${ICON_IMAGE_SIZE}px;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 auto;
  padding-right: ${ICON_MARGIN}px;
`;

const Configure = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  height: 100%;
`;

const ConfigureText = styled.div`
  align-self: center;
  padding-left: 4px;
  font-size: 14px;
`;

const AddIcon = styled.img.attrs(props => ({
  src: props.icon
}))`
  width: ${props => (props.big ? 24 : 16)}px;
  height: ${props => (props.big ? 24 : 16)}px;
`;

const ListItem = ({
  icon,
  text,
  showConfigure,
  configureIcon,
  showConfigureText,
  onClick
}) => (
  <Container onClick={onClick}>
    {icon && (
      <IconWrapper>
        <Icon src={icon} alt={text} />
      </IconWrapper>
    )}
    <TextWrapper>{text}</TextWrapper>
    {showConfigure && (
      <Configure>
        <AddIcon big={!showConfigureText} icon={svgList[configureIcon]} />
        {showConfigureText && (
          <ConfigureText>
            <FormattedMessage defaultMessage="Configure" />
          </ConfigureText>
        )}
      </Configure>
    )}
  </Container>
);

ListItem.propTypes = {
  /** Optional icon to render */
  icon: PropTypes.string,
  /** Text displayed in item */
  text: PropTypes.string.isRequired,
  /** Show +configure icon and text */
  showConfigure: PropTypes.bool,
  /** Change configure icon */
  configureIcon: PropTypes.string,
  /** Show configure text next to icon */
  showConfigureText: PropTypes.bool,
  /** On item click handler */
  onClick: PropTypes.func.isRequired
};

ListItem.defaultProps = {
  configureIcon: "Plus-24"
};

export default ListItem;
