import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { readableColor } from "../../utils/colorUtils";

import { defineMessages, injectIntl } from "react-intl";

import { svgList } from "../Icon/svgList";

import COLORS from "../../../constants/colors";

const CLOSE_ICON_SIZE = 32;
const HEADER_CONTENT_PADDING = 16;

const messages = defineMessages({
  closeDialog: {
    defaultMessage: "Close dialog"
  }
});

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 58px;
  justify-content: left;
  align-items: center;
  color: #3f4040;
  padding: 0 8px;
  position: relative;

  border-bottom: solid 1px ${COLORS.SEPARATOR_COLORS.MEDIUM};

  @media screen and (max-width: ${props => props.breakpoint}px) {
    background-color: ${props => props.theme.primaryColor};
    color: ${props => props.fontColor};
    border-bottom: none;
    padding: 0 10px;
    height: 52px;
    flex-direction: row-reverse;
  }
`;

const CONTENT_FONT_SIZE = 22;
const CONTENT_LINE_HEIGHT = 1.18;
const CONTENT_VISIBLE_ROWS = 2;

const Content = styled.div`
  flex-grow: 1;
  font-size: ${CONTENT_FONT_SIZE}px;
  line-height: ${CONTENT_LINE_HEIGHT};
  padding: 0 ${HEADER_CONTENT_PADDING}px;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${CONTENT_VISIBLE_ROWS};
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: ${CONTENT_FONT_SIZE *
  CONTENT_LINE_HEIGHT *
  CONTENT_VISIBLE_ROWS}px; //fallback for browsers not supporting -webkit-line-clamp (i.e. IE)

  @media screen and (max-width: ${props => props.breakpoint}px) {
    font-size: 18px;
    text-align: center;
    padding: 0 8px;
    margin-right: ${props => props.hasClose && `${CLOSE_ICON_SIZE}px`};
  }
`;

const CloseIconWrapper = styled.button`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  box-sizing: initial;
  background: none;
  border: none;
  padding: 0;
  background-image: url(${svgList["Close-16"]});
  background-repeat: no-repeat;
  background-position: 8px;
  min-width: ${CLOSE_ICON_SIZE}px;
  height: ${CLOSE_ICON_SIZE}px;

  @media screen and (max-width: ${props => props.breakpoint}px) {
    background-image: ${props => 
      props.showDarkIcon
        ? `url(${svgList["Chevron_left-16"]});`
        : `url(${svgList["Chevron_left-16-white"]});`}
  }
`;

export const DialogHeader = ({
  onClose,
  breakpoint,
  children,
  theme,
  intl
}) => {
  const fontColor = readableColor(theme.primaryColor);

  return (
    <HeaderContainer fontColor={fontColor} breakpoint={breakpoint}>
      <Content hasClose={!!onClose} breakpoint={breakpoint}>
        {children}
      </Content>

      {onClose && (
        <CloseIconWrapper
          breakpoint={breakpoint}
          data-testid="close-dialog-button"
          aria-label={intl.formatMessage(messages.closeDialog)}
          showDarkIcon={fontColor === "#000"}
          onClick={onClose}
        />
      )}
    </HeaderContainer>
  );
};

DialogHeader.propTypes = {
  onClose: PropTypes.func,
  breakpoint: PropTypes.number
};

export default withTheme(injectIntl(DialogHeader));
