import React from "react";
import styled from "styled-components";
import { bool, string } from "prop-types";

import { Loading } from "../Loading/Loading";

const StyledLoading = styled(Loading)(props =>
  props.collapsed && `
    position: unset;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    transform: unset;
  `
);

/**
 * Wraps Loading component to control display of content or loader based on `loading` property.
 * @param {boolean} loading - [optional] describes if the content is loading
 * @param {boolean} collapsed - [optional] describes if the loading container is collapsed
 * @param {string} msg - [optional] the message to display with the loading animation
 * @returns {JSX.Element} the loading container when loading, children when not loading
 * @example
 *   <Load loading={loading} msg="The page is loading..." collapsed={true}>
 *     <div>Content that will render when loading == true</div>
 *   </Load>
 * @type {{( props: { loading?: boolean, collapsed?: boolean, msg?: string }): JSX.Element}}
 */
const Load = ({ loading, collapsed, msg, children, ...props }) => {
  if (!loading) return children;
  return (
    <div data-testid="load" {...props}>
      <StyledLoading msg={msg} collapsed={collapsed} />
    </div>
  );
};

Load.propTypes = {
  collapsed: bool,
  loading: bool.isRequired,
  msg: string
};

export default Load;
