import COLORS from "../../constants/colors"

const getBackgroundColor = ({ locked, disabled }) => {
  if (locked) {
    return "#edfaff";
  }
  if (disabled) {
    return "#f2f4f5";
  }

  return COLORS.STATUS_COLORS.WHITE;
};

export default getBackgroundColor;
