//TODO: breaking change?
import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// ui page modals dimmer transition visible active
const ModalDimmer = styled.div`
  top: 0em;
  left: 0em;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(45, 45, 45, 0.85);
  line-height: 1;
  animation-fill-mode: both;
  animation-duration: 0.5s;
  transition: background-color 0.5s linear;
  user-select: none;
  z-index: 1000;

  opacity: 1;

  position: fixed;
  transform-style: "";
  perspective: 2000px;
  transform-origin: center center;

  display: flex;
  justify-content: center;
  align-items: center;
`;

class Dimmer extends Component {
  constructor(props) {
    super(props);
    this.dimmerRef = React.createRef();
  }

  onClickHandler = event => {
    if (this.dimmerRef && this.dimmerRef.current.contains(event.target)) {
      event.stopPropagation();
      this.props.onClick && this.props.onClick(event);
    }
  };

  render = () => {
    const { "data-testid": dataTestId, children } = this.props;
    return (
      <ModalDimmer onClick={this.onClickHandler} ref={this.dimmerRef} data-testid={dataTestId}>
        {children}
      </ModalDimmer>
    );
  };
}

Dimmer.displayName = "Dimmer";

Dimmer.propTypes = {
  onClose: PropTypes.func
};

export default Dimmer;
