import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import COLORS from "../../../../../constants/colors";
import FONTS from "../../../../../constants/fonts";

// if the react-phone-input-2 version goes up, please copy its style.css from react-phone-input-2/lib
// and paste to PhoneNumberInput.styles.js in this folder
import phoneNumberInputStyles from "./PhoneNumberInput.styles";

// this component is still exported as default from react-phone-input-2
import PI from "react-phone-input-2";

/**
 * Default import of react-phone-input-2 was creating issue
 */
const PhoneInput = PI.default ? PI.default : PI;

const phoneInputStyle = {
  lineHeight: "normal",
  height: "38px",
  borderRadius: "2px",
  width: "100%",
  color: COLORS.GRAY_COLORS.GRAY_35,
  fontFamily: FONTS.FONT_FAMILY.DEFAULT
};

const countrySelectorStyle = {
  backgroundColor: "white",
  width: "48px"
};

const PhoneInputWrapper = styled.div`
  ${phoneNumberInputStyles}

  .react-tel-input {
    .form-control {
      line-height: normal;
      height: 38px;
      border-radius: 2px;
      width: 100%;
      padding-left: 66px;
      border-color: ${COLORS.GRAY_COLORS.GRAY_88};
    }

    .flag-dropdown {
      background-color: white;
      width: 54px;
      border-color: ${COLORS.GRAY_COLORS.GRAY_88};

      .selected-flag {
        padding-left: 12px;
        z-index: inherit;
        .arrow {
          left: 24px;
        }
      }
    }
  }
`;

const PhoneNumberInput = ({
  onChange,
  value,
  defaultCountry,
  disabled,
  placeholder = null
}) => (
  <PhoneInputWrapper>
    <PhoneInput
      id="phone-number"
      country={defaultCountry}
      value={value}
      onChange={(value, data, event, formattedValue) => {
        /**
         * Since the formatted value is expected everywhere
         */
        onChange(formattedValue);
      }}
      buttonStyle={countrySelectorStyle}
      inputStyle={phoneInputStyle}
      // disableAreaCodes={true}
      enableAreaCodes={true}
      disabled={disabled}
      placeholder={placeholder}
      enableSearch={true}
      autoFormat={true}
      countryCodeEditable={false}
    />
  </PhoneInputWrapper>
);

PhoneNumberInput.displayName = "PhoneNumberInput";

PhoneNumberInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  defaultCountry: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string
};

export default PhoneNumberInput;
