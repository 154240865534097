import React, { forwardRef } from "react";
import { string, func, number, oneOfType } from "prop-types";
import styled from "styled-components";

import Icon from "../Icon/Icon";
import COLORS from "../../../constants/colors";

const StyledDiv = styled.div`
  position: relative;
  box-sizing: border-box;
  border: 1px solid ${COLORS.TEXTFIELD_COLORS.BORDER};
  border-radius: 2px;
  padding: 9px 28px 8px 12px;
  height: 38px;
  line-height: normal;
  font-family: Roboto, "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
  font-size: 14px;
  color: ${COLORS.TEXT_COLORS.TITLE};
  background-color: ${COLORS.WHITE};
  letter-spacing: 0.2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  user-select: none;
  &[data-hasvalue=false] {
    color: ${COLORS.TEXT_COLORS.PLACEHOLDER};
  }
`;

const AllowSelect = styled.span`
  user-select: text;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  right: 8px;
  top: 11px;
  height: 50%;
`;

/**
 * A selectable form field component
 * @param {String|Number} id - a string or number given to the component
 * @param {String|Number} value [optional] - a string or number that represents the value of the select field
 * @param {String} placeholder [optional] - the placeholder that renders when there is no value
 * @param {Function} onKeyDown [optional] - a function that runs when any keyDown event is triggered on the select field element
 * @returns {JSX.Element} a select field displaying the component's value or placeholder
 * @example
 *   <SelectField
 *     id="select-field"
 *     value="I was selected"
 *     placeholder="Placeholder Text"
 *     onKeyDown={() => alert("KeyDown on SelectField")}
 *   />
 * @type {{( props: { id: (string|number), value?: (string|number), placeholder?: string,  onKeyDown?: function }): JSX.Element}}
 */
const SelectField = forwardRef(({ id, value, placeholder, onKeyDown }, ref) => {
  const hasValue = !!value || value === 0;
  return (
    <StyledDiv
      id={id}
      data-hasvalue={hasValue}
      role="button"
      aria-label={`${placeholder}: ${hasValue ? value : ""}`}
      tabIndex="0"
      onKeyDown={evt => onKeyDown && onKeyDown(evt)}
      ref={ref}
    >
      {hasValue ? <AllowSelect>{value}</AllowSelect> : placeholder}
      <StyledIcon name="Chevron_down-12" />
    </StyledDiv>
  );
});

SelectField.propTypes = {
  id: oneOfType([number, string]).isRequired,
  value: oneOfType([number, string]),
  placeholder: string,
  onKeyDown: func
};

export default SelectField;
