import styled from "styled-components";
import FONTS from "../../../constants/fonts";
import COLORS from "../../../constants/colors";

// icon
import { svgList } from "../Icon/svgList";

export const StyledCheckbox = styled.div`
  color: ${COLORS.GRAY_COLORS.GRAY_25};
  font-family: ${FONTS.FONT_FAMILY.DEFAULT};
  display: flex;
  align-items: center;
  padding: 4px 0;
  position: relative;
}`;

export const StyledInput = styled.input`
  width: 18px;
  height: 18px;
  margin: 0;
  -webkit-appearance: none;

  position: relative;
  background-color: #ffffff;
  border: solid 1px ${COLORS.GRAY_COLORS.GRAY_84};
  border-radius: 2px;
  cursor: pointer;
  padding: 1px;
  position: absolute;
  left: 0;
  top: 5px;

  &:checked:after {
    content: url( ${svgList["Checkmark-checkbox"]});
    
    background-color: ${COLORS.ACTION_COLORS.ONELOGIN_BLUE};
    border: solid 1px ${COLORS.ACTION_COLORS.ONELOGIN_BLUE};
    position: absolute;
    top: -1px;
    right: -1px;
    left: -1px;
    bottom: -1px;
    border-radius: 2px;
  }

  &:checked:focus:after {
    //for some reason input's outline is 1px higher than the checkbox itself. 
    //This hack makes the checkbox 1px higher in case it is focused, so there's no 1px white line
    bottom: -2px; 
  }
}`;

export const StyledLabel = styled.label`
  /*margin: 5px;*/
  display: inline-block;
  position: relative;
  padding-left: 26px;
  line-height: 20px;
  opacity: ${props => (props.disabled ? 0.3 : 1)};
}`;
