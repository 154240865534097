//IE11 doesn't support Event constructor, so we need to create event old way
export const createNewEvent = eventName => {
  var event;
  if (typeof Event === "function") {
    event = new Event(eventName);
  } else {
    event = document.createEvent("Event");
    event.initEvent(eventName, true, true);
  }
  return event;
};
