import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import SingleValueForm from "../../hocs/SingleValueForm/SingleValueForm";
import PasswordComplexity from "../../hocs/PasswordComplexity/PasswordComplexity";
import Content from "../../ui/Content/Content";
import ContentBlock from "../../ui/ContentBlock/ContentBlock";
import Label from "../../ui/FormField/Label";
import PasswordInput from "../../ui/FormField/PasswordInput";
import ToDoList from "../../ui/ToDoList/ToDoList";
import DialogFooter from "../../ui/DialogFooter/DialogFooter";
import Button from "../../ui/Button/Button";

const challengeMap = length => {
  return {
    length: (
      <FormattedMessage
        defaultMessage="Minimum {length} characters"
        values={{ length }}
      />
    ),
    upper: <FormattedMessage defaultMessage="1 Uppercase" />,
    lower: <FormattedMessage defaultMessage="1 lowercase" />,
    numbers: <FormattedMessage defaultMessage="1 number" />,
    specials: <FormattedMessage defaultMessage="1 special character" />
  };
};

const FIELDNAME = "New Password";

const NewPassword = ({ onSubmit, onCancel, rules }) => (
  <SingleValueForm
    onSubmit={onSubmit}
    render={({ value, onChange }) => (
      <PasswordComplexity
        rules={rules}
        value={value}
        render={({ invalid, completedChallenges, challenges }) => (
          <Fragment>
            <Content data-testid="reset-password-screen">
              <ContentBlock data-testid="reset-password-content">
                <Label forId="password-input">
                  <FormattedMessage defaultMessage={FIELDNAME} />
                </Label>
                <PasswordInput
                  data-testid="reset-password-input"
                  id="password-input"
                  onChange={e => onChange(e.target.value)}
                  autoFocus
                  value={value}
                />
              </ContentBlock>
              <ContentBlock>
                <ToDoList
                  list={challenges.map(challenge => ({
                    text: challengeMap(rules.length)[challenge],
                    complete: completedChallenges.includes(challenge)
                  }))}
                />
              </ContentBlock>
            </Content>
            <DialogFooter>
              <Button
                size="large"
                type="submit"
                look="primary"
                disabled={invalid}
              >
                <FormattedMessage defaultMessage="Submit" />
              </Button>
              <Button size="large" onClick={onCancel}>
                <FormattedMessage defaultMessage="Cancel" />
              </Button>
            </DialogFooter>
          </Fragment>
        )}
      />
    )}
  />
);

export default NewPassword;
