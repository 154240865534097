/**
 *  Primary colors
 *
 *  They are mostly used on Call to Action and brand centric elements
 */

// Black and White
const WHITE = "#FFFFFF";
const BLACK = "#000000";

// Action color
const ACTION_COLORS = {
  ONELOGIN_BLUE: "#016B91",
  ONELOGIN_BLUE_DARK: "#009ACC",
  BLUE_MAIN: "#016B91",
  BLUE_LIGHT: "#00BBFA",
  BLUE_MID: "#008FBF",
  BLUE_DARK: "#005E7D",
  BLUE_DARKER: "#00455C",

  // dropdown item highlight
  BLUE_ITEM_HIGHLIGHT: "#eefbff",
  // States of BLUE_MAIN
  BLUE_MAIN_HOVER: "#0891BF",
  BLUE_MAIN_ACTIVE: "#005875"
};

/**
 *  Secondary colors
 */

// OneLogin gray colors
const ONELOGIN_GRAY_COLORS = {
  ONELOGIN_BLACK: "#1C1F2A",
  ONELOGIN_GRAY_DARK: "#494B54",
  ONELOGIN_GRAY_MID: "#76787F",
  ONELOGIN_GRAY: "#A4A5A9",
  ONELOGIN_SILVER: "#D1D2D4",
  ONELOGIN_GRAY_LIGHT: "#F3F3F4"
};

// Grayscale (the number affixed is from the Brightness value of HSB in Sketch app)
const GRAY_COLORS = {
  GRAY_99: "#FAFCFC",
  GRAY_98: "#F7F9FA",
  GRAY_97: "#F5F7F7",
  GRAY_96: "#F2F4F5",
  GRAY_94: "#EDEFF0",
  GRAY_92: "#E8EAEB",
  GRAY_90: "#E3E5E6",
  GRAY_88: "#DEE0E0",
  GRAY_84: "#D4D6D6",
  GRAY_78: "#C5C6C7",
  GRAY_68: "#ACADAD",
  GRAY_55: "#8B8C8C",
  GRAY_45: "#727273",
  GRAY_35: "#585959",
  GRAY_25: "#3F4040",
  GRAY_18: "#2D2E2E"
};

/**
 *  Colors for status
 */

const STATUS_COLORS = {
  ERROR: "#D6291A",
  WARNING: "#F29B05",
  SUCCESS: "#5DA30D",
  INFO: ACTION_COLORS.BLUE_MID,
  PROGRESS: ACTION_COLORS.BLUE_MID,
  WHITE: WHITE,
  DARK_GRAY: GRAY_COLORS.GRAY_25
};

/**
 *  Typegraphy colors
 */

const TEXT_COLORS = {
  DEFAULT: GRAY_COLORS.GRAY_35,
  TITLE: GRAY_COLORS.GRAY_25,
  EMPHASIZED: GRAY_COLORS.GRAY_25,
  LINK: ACTION_COLORS.BLUE_MAIN,
  LABEL: GRAY_COLORS.GRAY_45,
  PLACEHOLDER: GRAY_COLORS.GRAY_68,
  DISABLE: GRAY_COLORS.GRAY_78,
  DESCRIPTION_SMALL: GRAY_COLORS.GRAY_55
};

/**
 *  Separator colors
 */

const SEPARATOR_COLORS = {
  REGULAR: GRAY_COLORS.GRAY_94,
  MEDIUM: GRAY_COLORS.GRAY_92,
  STRONG: GRAY_COLORS.GRAY_90,
  LIGHT: GRAY_COLORS.GRAY_84
};

/**
 *  Icon colors
 */

const ICON_COLORS = {
  NORMAL: GRAY_COLORS.GRAY_55,
  HOVER: ACTION_COLORS.BLUE_MAIN_HOVER,
  ACTIVE: ACTION_COLORS.BLUE_MAIN_ACTIVE,
  SELECTED: ACTION_COLORS.ONELOGIN_BLUE,
  WHITE: WHITE,
  DARK_GRAY: GRAY_COLORS.GRAY_25,
  PLACEHOLDER: GRAY_COLORS.GRAY_84
};

/**
 *  Form colors
 */

const TEXTFIELD_COLORS = {
  BORDER: GRAY_COLORS.GRAY_88,
  BORDER_HOVER: GRAY_COLORS.GRAY_78,
  BORDER_ACTIVE: ACTION_COLORS.ONELOGIN_BLUE,
  BORDER_DISABLED: GRAY_COLORS.GRAY_94,
  BACKGROUND_DISABLED: GRAY_COLORS.GRAY_96,
  BACKGROUND_LOCKED: GRAY_COLORS.GRAY_94
};

/* ----------------------------------------------------- */

/**
 *   Group all settings and export as single constant
 */

const COLORS = {
  WHITE: WHITE,
  BLACK: BLACK,
  ACTION_COLORS: ACTION_COLORS,
  ONELOGIN_GRAY_COLORS: ONELOGIN_GRAY_COLORS,
  GRAY_COLORS: GRAY_COLORS,
  STATUS_COLORS: STATUS_COLORS,
  TEXT_COLORS: TEXT_COLORS,
  SEPARATOR_COLORS: SEPARATOR_COLORS,
  ICON_COLORS: ICON_COLORS,
  TEXTFIELD_COLORS: TEXTFIELD_COLORS
};

export default COLORS;
