import { NavLink } from "react-router-dom";
import styled from "styled-components";
import COLORS from "../../../../constants/colors";

const SidebarLink = styled(NavLink)`
  display: block;
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 14px;
  padding-left: 25px;
  font-size: 14px;
  line-height: 16px;
  line-height: 1rem;
  color: ${COLORS.GRAY_COLORS.GRAY_35};
  text-decoration: none;

  &:before {
    display: block;
    content: "";
    position: absolute;
    top: 13px;
    left: 10px;
    width: 2px;
    height: 0;
    background-color: ${COLORS.ACTION_COLORS.ONELOGIN_BLUE};
    transition: height 0.4s ease-in-out;
  }

  &:hover {
    background-color: ${COLORS.ONELOGIN_GRAY_COLORS.ONELOGIN_GRAY_LIGHT};
  }

  &.active {
    font-weight: bold;
  }
  &.active::before {
    height: 16px;
  }

  .active:hover {
    background-color: transparent;
  }
`;

export default SidebarLink;
