import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Label from "../../../../ui/FormField/Label";
import Button from "../../../../ui/Button/Button";
import Content from "../../../../ui/Content/Content";
import ContentBlock from "../../../../ui/ContentBlock/ContentBlock";
import SingleValueForm from "../../../../hocs/SingleValueForm/SingleValueForm";
import PasswordInput from "../../../../ui/FormField/PasswordInput";

const LoginProvideAnswer = ({ question, onSubmit }) => (
  <SingleValueForm
    onSubmit={onSubmit}
    render={({ invalid, value, onChange }) => (
      <Content data-testid="provide-answer-screen">
        <ContentBlock>
          <Label forId="secure-answer">{question}</Label>
          <PasswordInput
            id="secure-answer"
            value={value}
            onChange={event => onChange(event.target.value)}
            autoFocus
            invalid={invalid}
            size="large"
          />
        </ContentBlock>
        <ContentBlock>
          <Button size="large" type="submit" look="primary">
            <FormattedMessage defaultMessage="Continue" />
          </Button>
        </ContentBlock>
      </Content>
    )}
  />
);

LoginProvideAnswer.displayName = "LoginProvideAnswer";

LoginProvideAnswer.propTypes = {
  question: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default LoginProvideAnswer;
