export const ARROW_DOWN = 40;
export const ARROW_UP = 38;
export const SPACE = 32;
export const ENTER = 13;
export const ESC = 27;
export const TAB = 9;

export default {
  ARROW_DOWN,
  ARROW_UP,
  SPACE,
  ENTER,
  ESC,
  TAB
};