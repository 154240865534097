const BREAKPOINTS = {
  SMALL_MODAL_SIZE: "376px",
  PHONE_LARGE: "480px",
  PORTAL_MIDDLE: "652px",
  MID_VIEW_LIMIT: "856px",
  WIDE_VIEW_LIMIT: "1060px",
  MODAL_HEIGHT_LIMIT: "400px"
};

export default BREAKPOINTS;
