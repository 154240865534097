import styled from "styled-components";
import FONTS from "../../../constants/fonts";
import COLORS from "../../../constants/colors";

export const StyledTag = styled.span`
  color: white;
  border-radius: 16px;
  background: ${COLORS.ACTION_COLORS.BLUE_DARKER};
  font-size: 13px;
  line-height: 14px;
  padding: 8px 12px;
  font-family: ${FONTS.FONT_FAMILY.DEFAULT};
}`;
