import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import Bullets from "../../../../ui/Bullets/Bullets";
import LinkButton from "../../../../ui/LinkButton/LinkButton";
import Button from "../../../../ui/Button/Button";
import Content from "../../../../ui/Content/Content";
import ContentBlock from "../../../../ui/ContentBlock/ContentBlock";
import QRCode from "qrcode.react";

const QRCODE_WIDTH = 120;

const QRCodeWrap = styled.div`
  margin-top: 32px;
  position: relative;
  left: 60px;
`;

const ScanBarCode = ({ appName, code, onContinue, onCantScan }) => (
  <Content data-testid="scan-bar-code-screen">
    <ContentBlock>
      <Bullets>
        <FormattedMessage
          defaultMessage="Open {appName}"
          values={{
            appName
          }}
        />
        <div>
          <FormattedMessage defaultMessage="Scan below QR Code" />
          <QRCodeWrap>
            <QRCode value={encodeURI(code)} size={QRCODE_WIDTH} />
          </QRCodeWrap>
        </div>
      </Bullets>
    </ContentBlock>
    {onCantScan && (
      <ContentBlock centered>
        <LinkButton onClick={onCantScan} size="large">
          <FormattedMessage defaultMessage="Can't scan the code?" />
        </LinkButton>
      </ContentBlock>
    )}
    {onContinue && (
      <ContentBlock>
        <Button
          size="large"
          look="primary"
          onClick={onContinue}
          data-testid="activate-button"
        >
          <FormattedMessage defaultMessage="Next" />
        </Button>
      </ContentBlock>
    )}
  </Content>
);

ScanBarCode.propTypes = {
  appName: PropTypes.string.isRequired,
  onContinue: PropTypes.func,
  onCantScan: PropTypes.func,
  code: PropTypes.string.isRequired
};

export default ScanBarCode;
