import Modal from "./Modal";
import DialogFooter from "../DialogFooter/DialogFooter";
import DialogHeader from "./DialogHeader";
import DialogContent from "./DialogContent";
import DialogNotification from "./DialogNotification";

Modal.Content = DialogContent;

Modal.Actions = DialogFooter;

Modal.Header = DialogHeader;

Modal.Notification = DialogNotification;

export default Modal;
