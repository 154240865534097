import React, { useEffect, createRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { find, propEq, sort } from "ramda";

import COLORS from "../../../constants/colors";
import Icon from "../Icon/Icon";
import TableRow from "./TableRow";
import useWindowSize from "../../utils/useWindowSizeHook";
import BREAKPOINTS from "../../../constants/breakpoints";

const CLIPPED_BUTTON_WIDTH = 24;

const TableContainer = styled.table`
  width: 100%;
  margin-bottom: 16px;

  @media screen and (max-width: ${BREAKPOINTS.PORTAL_MIDDLE}) {
    margin-bottom: 0; //TODO?
  }
`;

const NoDataContent = styled.td`
  text-align: center;
  font-size: 13px;
  color: ${COLORS.GRAY_COLORS.GRAY_68};
  height: 50px;
  border: 1px solid ${COLORS.GRAY_COLORS.GRAY_94};
  border-top: none;
`;

const NoDataTableRow = styled.tr`
  width: 100%;
  background-color: ${COLORS.WHITE};
  border-top: 0;
`;

const TableClippedContainer = styled.button`
  background: none;
  border: none;
  padding: 0;
  height: 24px;
  width: ${CLIPPED_BUTTON_WIDTH}px;
`;

const TableClippedContainerPlaceholder = styled.div`
  width: ${CLIPPED_BUTTON_WIDTH}px;
`;

const IconWithChevron = styled(Icon).attrs({
  icon: "Chevron_right-16",
  size: "16"
})`
  cursor: pointer;
`;

const ResponsiveTable = ({
  tableStructure,
  noDataMessage,
  hideTopBorderMobile
}) => {
  const windowSize = useWindowSize();
  const tableContainerRef = createRef();
  const intl = useIntl();

  const [width, setWidth] = useState();
  const [sortedByPrio, setSortedByPrio] = useState();
  const [rowStructure, setRowStructure] = useState();

  // sort table columns by priority so we can use this when counting what fits into table
  // const { tableStructure } = this.props;
  useEffect(() => {
    const sortedByPrio = sort((a, b) => {
      if (a.priority === b.priority) {
        return a.weight > b.weight ? -1 : 1;
      }
      return a.priority > b.priority ? -1 : 1;
    }, tableStructure.columns);

    setSortedByPrio(sortedByPrio);
  }, [tableStructure]);

  //set new width
  useEffect(() => {
    const rect = tableContainerRef.current.getBoundingClientRect();

    if (width !== rect.width) {
      setWidth(rect.width);
    }
  }, [windowSize, width, tableContainerRef]);

  //calculate visible columns
  useEffect(() => {
    if (sortedByPrio === undefined) {
      return;
    }

    const getCellBy = (key, datum) => {
      const idItem = find(propEq("key", key), datum);

      return idItem;
    };

    const expanderDefined =
      tableStructure.expander && tableStructure.expander.idKey;

    let widthSum = expanderDefined ? CLIPPED_BUTTON_WIDTH : 0, //Always reserve place for expander column when enabled
      visibleHeaderCells = [],
      visibleKeys = {},
      totalWeight = 0;

    //keep adding columns until sum of their minWidths fits into table width
    for (let column of sortedByPrio) {
      widthSum += column.minWidth || 0;

      if (widthSum <= width) {
        visibleHeaderCells.push(column);
        visibleKeys[column.key] = column;
        totalWeight += column.weight || 0;
      }
    }

    //TODO: FIXME: no matter what, there needs to be at least one column visible :) fix it ^

    //filter out columns that are not visible - non-prio-sorted columns here
    //also count column width here
    visibleHeaderCells = tableStructure.columns
      .filter(column => visibleKeys[column.key] !== undefined)
      .map(column => ({
        ...column,
        width:
          column.width ||
          (column.weight && (column.weight / totalWeight) * 100 + "%")
      }));

    //we want to show expander (chevron icon) when there's more columns then currently visible
    const isClipped = visibleHeaderCells.length < tableStructure.columns.length;
    const isExpanderAlwaysVisible =
      tableStructure.expander && tableStructure.expander.isAlwaysVisible;
    if (expanderDefined && (isClipped || isExpanderAlwaysVisible)) {
      visibleHeaderCells.push({
        width: `${CLIPPED_BUTTON_WIDTH}px`,
        content: <TableClippedContainerPlaceholder />,
        key: "expander"
      });
    }

    //filter out data of columns that are not visible
    const bodyRows = tableStructure.data.map(datum => {
      const cells = datum.cells
        .filter(cell => visibleKeys[cell.key] !== undefined)
        .map(cell => {
          return {
            ...cell,
            href: datum.href
            // width: relatedColumn && relatedColumn.width //TODO: what about to set cell width to 0px if column is not visible? Will screen readers work as expected? Will rendering work properly?
          };
        });

      //TODO: consider to not show chevron on every single row, but to show table expander to expand all the table, so users who need whole data can have it

      if ((isClipped && expanderDefined) || isExpanderAlwaysVisible) {
        const idItem = getCellBy(tableStructure.expander.idKey, datum.cells);

        cells.push({
          width: `${CLIPPED_BUTTON_WIDTH}px`,
          content:
            idItem && idItem.content !== undefined ? (
              <TableClippedContainer
                data-testid="show-details"
                aria-label={intl.formatMessage({
                  defaultMessage: "Show details", // TODO: aria-label can be passed as a prop, 'show details' needn't to be always the case OR do show the modal dialog always containing row data(?)
                  id: "showDetails"
                })}
                onClick={() => {
                  tableStructure.expander.onClick &&
                    tableStructure.expander.onClick(idItem.content);
                }}
              >
                <IconWithChevron />
              </TableClippedContainer>
            ) : (
              <TableClippedContainerPlaceholder />
            ),
          key: "expander"
        });
      }
      return { cells, meta: datum.meta };
    });

    setRowStructure({
      visibleHeaderCells,
      bodyRows
    });
  }, [sortedByPrio, tableStructure, width, intl]);

  return (
    <TableContainer ref={tableContainerRef} cellSpacing={0} cellPadding={0}>
      {rowStructure !== undefined && (
        <>
          <thead>
            <TableRow
              isHeader
              cells={rowStructure.visibleHeaderCells}
              hideTopBorderMobile={hideTopBorderMobile}
            />
          </thead>
          <tbody>
            {rowStructure.bodyRows.length > 0 ? (
              rowStructure.bodyRows.map((row, index) => {
                return (
                  <TableRow
                    key={index}
                    {...(row.meta || {})}
                    cells={row.cells}
                  />
                );
              })
            ) : (
              <NoDataTableRow>
                <NoDataContent colSpan={rowStructure.visibleHeaderCells.length}>
                  {noDataMessage}
                </NoDataContent>
              </NoDataTableRow>
            )}
          </tbody>
        </>
      )}
    </TableContainer>
  );
};

ResponsiveTable.propTypes = {
  tableStructure: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.node,
        key: PropTypes.string.isRequired,
        minWidth: PropTypes.number,
        priority: PropTypes.number.isRequired,
        weight: PropTypes.number
      })
    ).isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        cells: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string.isRequired,
            width: PropTypes.string, //width containing units
            content: PropTypes.any.isRequired,
            contentAlign: PropTypes.string,
            hidden: PropTypes.bool
          })
        ),
        meta: PropTypes.shape({
          isHighlighted: PropTypes.bool,
          rowTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
          //dragData needs to be stringifyable, so test it here
          dragData: (props, propName, componentName) => {
            try {
              JSON.stringify(props[propName]);
            } catch (e) {
              return new Error(
                "Invalid prop `" +
                  propName +
                  "` supplied to `" +
                  componentName +
                  "`. Validation failed."
              );
            }
          }
        })
      })
    ).isRequired,
    expander: PropTypes.shape({
      isAlwaysVisible: PropTypes.bool, //TODO: find in target projects where and how this is used; if you see this during review ask me about this
      onClick: PropTypes.func,
      idKey: PropTypes.string
    })
  }),
  noDataMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hideTopBorderMobile: PropTypes.bool
};

export default ResponsiveTable;
