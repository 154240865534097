import PropTypes from "prop-types";
import { css } from "styled-components";

import {
  MENU_ITEM_MOBILE_HORIZONTAL_PADDING,
  MENU_ITEM_DESKTOP_HORIZONTAL_PADDING
} from "./NavbarConstants";

const MenuItemStyles = css`
  font-family: Roboto, "Helvetica Neue", Helvetica, "Segoe UI", Arial,
    sans-serif;
  color: inherit;
  width: ${props => (props.isMobile ? "100%" : "auto")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  outline-offset: -3px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background: transparent;
  border: none;
  font-size: inherit;
  height: 100%;
  cursor: pointer;
  margin: 0;

  text-decoration: none;
  max-width: 100%;

  padding: ${props =>
    props.isMobile
      ? `0px ${MENU_ITEM_MOBILE_HORIZONTAL_PADDING}px`
      : `0 ${MENU_ITEM_DESKTOP_HORIZONTAL_PADDING}px`};
  line-height: 51px;
`;

export const DropdownItemStyles = css`
  ${MenuItemStyles};

  padding: ${props =>
    props.isMobile
      ? `10px ${MENU_ITEM_MOBILE_HORIZONTAL_PADDING}px 10px 32px`
      : `5px ${MENU_ITEM_DESKTOP_HORIZONTAL_PADDING}px`};
  line-height: 28px;
`;

MenuItemStyles.propTypes = {
  isMobile: PropTypes.bool.isRequired
};

export default MenuItemStyles;
