import React, { Children } from "react";
import styled from "styled-components";
import COLORS from "../../../constants/colors";

const BulletSize = 28;

const BulletOrderedList = styled.ol`
  counter-reset: item;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 16px; //TODO: unify with content in bullet somehow
`;

const BulletItem = styled.li`
  counter-increment: item;
  line-height: ${BulletSize}px;
  margin-bottom: 24px;
  color: ${COLORS.TEXT_COLORS.DEFAULT};

  display: flex;
  align-self: center;
  justify-items: center;

  &:before {
    flex: 0 0 ${BulletSize}px;
    max-height: ${BulletSize}px;
    max-width: ${BulletSize}px;
    margin-right: 12px;
    font-size: 14px;
    line-height: 16px;
    content: counter(item);
    background: ${COLORS.ACTION_COLORS.BLUE_DARKER};
    border-radius: 100%;
    color: white;
    padding: 6px 0;
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-self: flex-start;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledContent = styled.div`
  align-self: center;
`;

const Bullets = ({ children }) => (
  <BulletOrderedList>
    {Children.map(
      children,
      child =>
        child && (
          <BulletItem>
            <StyledContent>{child}</StyledContent>
          </BulletItem>
        )
    )}
  </BulletOrderedList>
);

Bullets.propTypes = {};

export default Bullets;
