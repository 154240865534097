import { css } from "styled-components";
import SIZES from "../../constants/sizes"

export const getHeight = (size, heightMap) => heightMap[size];

const getSizingDefinitions = (size, heightMap) => {
  if (size === SIZES.BIG) {
    return css`
      font-size: 18px;
      height: ${getHeight(size, heightMap)}px;
    `;
  } else if (size === SIZES.LARGE) {
    return css`
      font-size: 16px;
      height: ${getHeight(size, heightMap)}px;
    `;
  } else {
    // medium (default)
    return css`
      font-size: 14px;
      height: ${getHeight(size, heightMap)}px;
    `;
  }
};

export default getSizingDefinitions
