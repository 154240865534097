import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import LinkButton from "../LinkButton/LinkButton";

import FONTS from "../../../constants/fonts"; // Fonts
import COLORS from "../../../constants/colors"; // Colors

const LabelWrapper = styled.div`
  font-size: 14px;
  margin: 0 0 8px 0;
  display: flex;
  align-items: center;
  line-height: 20px;
`;

const LabelText = styled.label`
  color: ${COLORS.TEXT_COLORS.LABEL};
  font-weight: 400;
  font-family: ${FONTS.FONT_FAMILY.DEFAULT};
  letter-spacing: 0.4px;
  margin-right: 8px;
`;

const InfoText = styled.div`
  color: ${COLORS.TEXT_COLORS.LABEL};
  flex: 1 0 auto;
  text-align: right;
`;

const RequiredInfo = styled.div`
  color: ${COLORS.STATUS_COLORS.ERROR};
  flex: 1 0 auto;
  text-align: right;
`;

export const Label = ({ forId, action, isRequired, infoText, children }) => (
  <LabelWrapper>
    <LabelText htmlFor={forId}>{children}</LabelText>
    {action && <LinkButton onClick={action.handler}>{action.title}</LinkButton>}
    {infoText && <InfoText>{infoText}</InfoText>}
    {isRequired && (
      <RequiredInfo>
        <FormattedMessage defaultMessage="Required" />
      </RequiredInfo>
    )}
  </LabelWrapper>
);

Label.propTypes = {
  forId: PropTypes.string,
  action: PropTypes.shape({
    title: PropTypes.string.isRequired,
    handler: PropTypes.func.isRequired
  }),
  isRequired: PropTypes.bool,
  infoText: PropTypes.string
};

export default Label;
