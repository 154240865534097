import React from "react";
import PropTypes from "prop-types";
import { svgList } from "./svgList";
import styled from "styled-components";

const Image = styled.img`
  height: ${props => props.size};
  width: ${props => props.size};
  padding: ${props => props.padding};
  cursor: ${props => (props.onClick ? "pointer" : "auto")};
`;

const Icon = ({ icon, name, ...rest }) => (
  <Image src={svgList[name || icon]} {...rest} />
);

Icon.displayName = "ImageWrapper";

Icon.defaultProps = {
  size: "24px",
  padding: "0"
};

Icon.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string,
  padding: PropTypes.string,
  onClick: PropTypes.func
};

export default Icon;
