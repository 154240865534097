import React, { Children } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import COLORS from "../../../constants/colors";

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.horizontalCenter ? "center" : "flex-end")};

  border-top: solid 1px ${COLORS.SEPARATOR_COLORS.MEDIUM};
  padding: 16px 24px;

  @media screen and (max-width: ${props => props.breakpoint}px) {
    flex-direction: column;
    padding: 32px 20px;
  }
`;

const StyledItem = styled.div`
  margin-left: 12px;

  &:first-child {
    margin-left: 0px;
  }

  @media screen and (max-width: ${props => props.breakpoint}px) {
    margin-left: 0px;
    margin-top: 24px;
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-top: 0px;
    }
  }
`;

const DialogFooter = ({ children, breakpoint, horizontalCenter }) => (
  <Footer horizontalCenter={horizontalCenter} breakpoint={breakpoint}>
    {Children.map(
      children,
      child => child && <StyledItem breakpoint={breakpoint}>{child}</StyledItem>
    )}
  </Footer>
);

DialogFooter.propTypes = {
  breakpoint: PropTypes.number,
  horizontalCenter: PropTypes.bool
};

export default DialogFooter;
