import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import SingleValueForm from "../../../../hocs/SingleValueForm/SingleValueForm";
import Label from "../../../../ui/FormField/Label";
import Button from "../../../../ui/Button/Button";
import Content from "../../../../ui/Content/Content";
import ContentBlock from "../../../../ui/ContentBlock/ContentBlock";
import PhoneNumberInput from "./PhoneNumberInput";

const EnterPhoneNumber = ({
  onSubmitSMS,
  phoneNumber,
  defaultCountry,
  placeholder
}) => (
  <SingleValueForm
    onSubmit={onSubmitSMS}
    value={phoneNumber}
    render={({ invalid, value, onChange }) => (
      <Content data-testid="enter-phone-number-screen">
        <ContentBlock>
          <Label forId="enter-phone-number">
            <FormattedMessage defaultMessage="Enter your phone number" />
          </Label>
          <ContentBlock>
            <PhoneNumberInput
              defaultCountry={defaultCountry || "us"}
              onChange={onChange}
              value={value}
              placeholder={placeholder}
            />
          </ContentBlock>
        </ContentBlock>
        <ContentBlock>
          <Button size="large" type="submit" look="primary">
            <FormattedMessage defaultMessage="Continue" />
          </Button>
        </ContentBlock>
      </Content>
    )}
  />
);

EnterPhoneNumber.displayName = "EnterPhoneNumber";

EnterPhoneNumber.propTypes = {
  onSubmitSMS: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string,
  defaultCountry: PropTypes.string,
  placeholder: PropTypes.string
};

export default EnterPhoneNumber;
