import styled, { css } from "styled-components";
import COLORS from "../../../constants/colors";

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  color: ${COLORS.TEXT_COLORS.DEFAULT};

  ${props =>
    props.centered &&
    css`
      text-align: center;
      align-items: center;
    `};

  &:first-child {
    margin-top: 0px;
  }

  &:last-child {
    margin-bottom: 0px;
  }
`;

ContentBlock.displayName = "ContentBlock";

export default ContentBlock;
