import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import Content from "../../../../ui/Content/Content";
import ContentBlock from "../../../../ui/ContentBlock/ContentBlock";
import DescriptionText from "../../../../ui/Text/DescriptionText";
import CancelAuthentication from "./CancelAuthentication";
import UnsupportedBrowser from "./UnsupportedBrowser";
import LoginLoading from "../../common/LoginLoading";

const HorizontalCenter = styled(DescriptionText)`
  text-align: center;
`;

export default class WebAuthn extends Component {
  constructor() {
    super();
    this.state = {
      error: false
    };
  }

  browserSupported() {
    return navigator.credentials && navigator.credentials.create;
  }

  callAuthenticateUser = () => {
    this.props.authenticateUser().catch(() => {
      this.setState({
        error: true
      });
    });
  };

  componentDidMount() {
    if (this.browserSupported()) {
      this.callAuthenticateUser();
    }
  }

  handleRetry = () => {
    this.setState(
      {
        error: false
      },
      this.callAuthenticateUser
    );
  };

  render() {
    if (!this.browserSupported()) {
      return <UnsupportedBrowser />;
    } else if (this.props.loading) {
      return <LoginLoading />;
    } else if (this.state.error) {
      return <CancelAuthentication onRetry={this.handleRetry} />;
    }
    return (
      <Content data-testid="webauthn-screen">
        <ContentBlock>
          <HorizontalCenter>
            <FormattedMessage defaultMessage="Verifying your WebAuthn authenticators now." />
          </HorizontalCenter>
        </ContentBlock>
      </Content>
    );
  }
}
