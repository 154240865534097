import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import Button from "../../ui/Button/Button";
import Content from "../../ui/Content/Content";
import ContentBlock from "../../ui/ContentBlock/ContentBlock";

import WarningIcon from "../../../svg/alert.svg";

const ImageWrapper = styled.img`
  width: 48px;
  height: 48px;
  align-self: center;
`;

const RegistrationRequired = ({ onContinue, message }) => (
  <form
    onSubmit={event => {
      event.preventDefault();
      onContinue();
    }}
  >
    <Content data-testid="registration-required-screen">
      <ContentBlock>
        <ImageWrapper src={WarningIcon} />
      </ContentBlock>
      <ContentBlock>
        {message || (
          <FormattedMessage defaultMessage="2-factor authentication is required to secure your account." />
        )}
      </ContentBlock>
      <ContentBlock>
        <Button size="large" type="submit" look="primary">
          <FormattedMessage defaultMessage="Continue" />
        </Button>
      </ContentBlock>
    </Content>
  </form>
);

RegistrationRequired.displayName = "RegistrationRequired";

RegistrationRequired.propTypes = {
  onContinue: PropTypes.func.isRequired,
  message: PropTypes.string
};

export default RegistrationRequired;
