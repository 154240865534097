import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import COLORS from "../../../constants/colors";

const NOTIFICATION_MIN_HEIGHT = 50;

const typeToColor = type => {
  switch (type) {
    case "success":
      return COLORS.STATUS_COLORS.SUCCESS;
    case "error":
      return COLORS.STATUS_COLORS.ERROR;
    default:
      return COLORS.STATUS_COLORS.INFO;
  }
};

// "invisible" => top=-height, visibility=hidden
// "showing" => top=0, visibility=visible
// "hiding" => top=-height;  visibility=visible
const computeTop = (status, height) => {
  const top = status === "showing" ? 0 : `-${height}`;
  return `${top}px`;
};

const Notification = styled.div`
  align-self: center;
  visibility: hidden;
  border-style: solid;
  border-width: 1px;
  border-radius: 0 0 3px 3px;
  color: #ffffff;
  text-align: center;

  padding: 16px;
  position: absolute;
  font-size: 16px;
  min-height: ${NOTIFICATION_MIN_HEIGHT}px;
  line-height: 1;

  box-sizing: border-box;
  z-index: 1;
  transition: top 0.5s;

  background-color: ${props => typeToColor(props.type)};
  border-color: ${props => typeToColor(props.type)};
  visibility: ${props => (props.status === "invisible" ? "hidden" : "visible")};
  top: ${props => computeTop(props.status, props.height)};

  ${props =>
    props.fluid &&
    css`
      border-radius: 0;
      width: 100%;
    `};
`;

class NotificationItem extends React.Component {
  constructor(props) {
    super(props);
    this.notificationRef = React.createRef();

    this.state = {
      height: NOTIFICATION_MIN_HEIGHT
    };
  }

  componentDidMount() {
    const { height } = this.notificationRef.current.getBoundingClientRect();
    this.setState({ height });
  }

  render() {
    return (
      <Notification
        {...this.props}
        ref={this.notificationRef}
        height={this.state.height}
      />
    );
  }
}

NotificationItem.defaultProps = {
  type: "info",
  status: "invisible",
  fluid: true
};

NotificationItem.propTypes = {
  status: PropTypes.oneOf(["invisible", "showing", "hiding"]),
  type: PropTypes.oneOf(["error", "success", "info"]),
  fluid: PropTypes.bool
};

export default NotificationItem;
