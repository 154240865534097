import React, { useEffect, useState } from "react";
import {
  arrayOf,
  bool,
  checkPropTypes,
  func,
  number,
  oneOfType,
  shape,
  string
} from "prop-types";

import { SearchDropdown } from "../../../index";
import SelectField from "../SelectField/SelectField";

/**
 * A component that includes a select input and search dropdown.
 * Combines two components: SelectField and SearchDropdown
 * @param {Number|String} id - a number or string prepended to SelectField and SearchDropdown components
 * @param {[Object]} list - an array of objects to render as list elements in the search dropdown
 *   @param {Number|String} list[].label - the label of the rendered list element
 *   @param {Number|String} list[].id - [optional] a unique value set to the list element key
 * @param {Null|Object} selected - a react state object describing the value of which list element has been selected
 * @param {Function} setSelected - a function generated by React.useState which sets the selected value
 * @param {String} placeholder - a placeholder string to render when no list element has been selected
 * @param {Boolean} dirTop - [optional] a boolean describing if the dropdown renders below or above the trigger element
 * @param {Boolean} searchOnType - [optional] a boolean describing if the onSearch method runs on type
 * @param {Boolean} onSearch - [optional] a function that overwrites the existing onSearch handler in the component
 * @example
 *   <SelectSearchDropdown
 *     id="favorite-food"
 *     list={[{label: "Janet", id: 1001001}]}
 *     selected={selected}
 *     setSelected={setSelected}
 *     placeholder="Select a food"
 *     dirTop
 *     searchOnType
 *     onSearch={filterList}
 *   />
 * @type {{(props: { id: (string|number), list: [{label: (number|string), id?: (number|string)}], selected: (null|{}), setSelected: function, placeholder: string, dirTop?: boolean, searchOnType?: boolean, onSearch?: function }): JSX.Element }}
 */
const SelectSearchDropdown = ({
  id,
  list,
  selected,
  setSelected,
  placeholder,
  dirTop,
  searchOnType,
  onSearch,
  className
}) => {
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState(list);

  const handleSearch = term => {
    term = term.trim().replace(/\s+/g, " ");
    setLoading(true);
    if (!term) {
      setLoading(false);
      setSearchResults(list);
      return;
    }
    const termWords = term.toLowerCase().split(" ");
    const newResults = list.filter(({ label }) =>
      termWords.every(word => label.toLowerCase().includes(word))
    );
    setLoading(false);
    setSearchResults(newResults);
  };

  const search = onSearch || handleSearch;

  const onSelect = option => {
    setSelected(JSON.parse(option));
    setSearchResults(list);
  };

  useEffect(() => {
    setLoading(false);
    setSearchResults(list);
  }, [list]);

  return (
    <SearchDropdown
      loading={loading}
      id={`${id}-search-dropdown`}
      onSearch={search}
      onSelect={onSelect}
      searchResults={searchResults}
      dirTop={dirTop}
      searchOnType={searchOnType}
      className={className}
    >
      <SelectField
        id={`${id}-select-field`}
        value={selected?.label}
        placeholder={placeholder}
      />
    </SearchDropdown>
  );
};

const nullOrShape = (props, propName, componentName) => {
  const prop = props[propName];
  if (prop === null) return;
  checkPropTypes(
    { [propName]: optionShape.isRequired },
    { [propName]: prop },
    "prop",
    componentName
  );
};

const optionShape = shape({
  label: oneOfType([number, string]).isRequired,
  id: oneOfType([number, string])
});

SelectSearchDropdown.propTypes = {
  id: oneOfType([number, string]).isRequired,
  list: arrayOf(optionShape).isRequired,
  selected: nullOrShape,
  setSelected: func.isRequired,
  placeholder: string.isRequired,
  dirTop: bool,
  searchOnType: bool,
  onSearch: func
};

export default SelectSearchDropdown;
