import PropTypes from "prop-types";
import styled from "styled-components";
import AvatarImage from "../Icon/svg/avatar.svg";

const Avatar = styled.div`
  background-image: url(${props => props.image || AvatarImage});
  width: ${props => props.size};
  height: ${props => props.size};
  background-size: cover;
  background-position: top center;
  border-radius: 50%;
`;

Avatar.displayName = "Avatar";

Avatar.propTypes = {
  image: PropTypes.string,
  size: PropTypes.string
};

Avatar.defaultProps = {
  image: "",
  size: "36px"
};

export default Avatar;
