import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import Button from "../../../../ui/Button/Button";
import Content from "../../../../ui/Content/Content";
import ContentBlock from "../../../../ui/ContentBlock/ContentBlock";
import DescriptionText from "../../../../ui/Text/DescriptionText";

const HorizontalCenter = styled(DescriptionText)`
  text-align: center;
`;

const CancelAuthentication = ({ onRetry }) => (
  <Content data-testid="cancelled-webauthn-screen">
    <ContentBlock>
      <HorizontalCenter>
        <FormattedMessage defaultMessage="The process was cancelled. Please try again or use another authentication factor." />
      </HorizontalCenter>
      <ContentBlock>
        <Button size="large" look="regular" onClick={onRetry}>
          <FormattedMessage defaultMessage="Try Again" />
        </Button>
      </ContentBlock>
    </ContentBlock>
  </Content>
);

CancelAuthentication.displayName = "CancelAuthentication";

CancelAuthentication.propTypes = {
  onRetry: PropTypes.func.isRequired
};

export default CancelAuthentication;
