import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import Bullets from "../../../../ui/Bullets/Bullets";
import Button from "../../../../ui/Button/Button";
import ContentBlock from "../../../../ui/ContentBlock/ContentBlock";
import COLORS from "../../../../../constants/colors";
import FONTS from "../../../../../constants/fonts";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px;
`;

const StyledCode = styled.div`
  background-color: ${COLORS.GRAY_COLORS.GRAY_96};
  border-width: 1px;
  border-color: ${props =>
    props.copied ? COLORS.STATUS_COLORS.SUCCESS : "transparent"};
  font-size: ${props => (props.condensedCode ? "13px" : "20px")};
  line-height: 1;
  letter-spacing: ${props => (props.condensedCode ? "0.4px" : "8px")};
  box-sizing: border-box;
  min-width: 336px;
  padding: ${props => (props.condensedCode ? "12px 0" : "16px 8px")};
  margin-top: 16px;
  margin-left: -40px;
  cursor: pointer;
  transition: border-color 0.2s;
  text-align: center;
`;

const SecretKeyBlock = styled.div`
  display: inline-block;
  box-sizing: border-box;
  width: 42px;
  font-family: ${FONTS.FONT_FAMILY.MONOSPACE};
  line-height: 14px;
  color: ${COLORS.TEXT_COLORS.EMPHASIZED};

  &:not(:last-of-type) {
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 1px;
      height: 38px;
      position: absolute;
      right: 0;
      top: -12px;
      background-color: ${COLORS.WHITE};
    }
  }
`;

class CopyCodeManually extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: undefined
    };
  }

  componentWillUnmount() {
    clearTimeout(this.copiedTimeoutId);
  }

  onCopy = () => {
    this.setState({
      copied: true
    });
    this.copiedTimeoutId = setTimeout(() => {
      this.setState({
        copied: false
      });
    }, 1000);
  };

  showCode = () => {
    if (this.props.appName === "OneLogin Protect") {
      return this.props.code;
    } else if (this.props.appName === "Authenticator") {
      let keyBlocks = [];
      for (let i = 0; i < 32; i += 4) {
        keyBlocks.push(
          <SecretKeyBlock key={i}>
            {this.props.code.slice(i, i + 4)}
          </SecretKeyBlock>
        );
      }
      return <div>{keyBlocks}</div>;
    }
  };
  render = () => (
    <Content data-testid="copy-code-manually-screen">
      <ContentBlock>
        <Bullets>
          <FormattedMessage defaultMessage="Click Pair using manual entry" />
          <div>
            <FormattedMessage defaultMessage="Enter this code into the app" />
            <StyledCode
              copied={this.state.copied}
              condensedCode={this.props.condensedCode}
            >
              {this.showCode()}
            </StyledCode>
          </div>
        </Bullets>
      </ContentBlock>
      {this.props.onScanBarCode && (
        <ContentBlock centered>
          <Button size="large" onClick={this.props.onScanBarCode}>
            <FormattedMessage defaultMessage="Scan barcode instead" />
          </Button>
        </ContentBlock>
      )}
      {this.props.onContinue && (
        <ContentBlock>
          <Button
            size="large"
            look="primary"
            onClick={this.props.onContinue}
            data-testid="activate-button"
          >
            <FormattedMessage defaultMessage="Next" />
          </Button>
        </ContentBlock>
      )}
    </Content>
  );
}

CopyCodeManually.displayName = "CopyCodeManually";

CopyCodeManually.propTypes = {
  appName: PropTypes.oneOf(["OneLogin Protect", "Authenticator"]).isRequired,
  onScanBarCode: PropTypes.func,
  code: PropTypes.string.isRequired,
  condensedCode: PropTypes.bool
};

CopyCodeManually.defaultProps = {
  condensedCode: false
};

export default CopyCodeManually;
