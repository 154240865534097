import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import ContentBlock from "../../../../ui/ContentBlock/ContentBlock";
import RegisterWithCredentialIdAndTwoOtps from "../common/RegisterWithCredentialIdAndTwoOtps";

const RegisterVipAccess = ({ onSubmit }) => (
  <RegisterWithCredentialIdAndTwoOtps
    onSubmit={onSubmit}
    credentialIdText={<FormattedMessage defaultMessage="Credential ID" />}
    otp1Label={<FormattedMessage defaultMessage="First Security Code" />}
    otp2Label={<FormattedMessage defaultMessage="Second Security Code" />}
  >
    <ContentBlock>
      <FormattedMessage
        defaultMessage="Enter Credential ID and two consecutive Security Codes from Symantec VIP Access."
      />
    </ContentBlock>
  </RegisterWithCredentialIdAndTwoOtps>
);

RegisterVipAccess.displayName = "RegisterVipAccess";

RegisterVipAccess.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default RegisterVipAccess;
