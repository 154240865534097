import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Navbar from "../../ui/Navbar2/Navbar";
import Notifications from "../../ui/Notifications/Notifications";
import { NAVBAR_HEIGHT } from "../../ui/Navbar2/NavbarConstants";
import FONTS from "../../../constants/fonts";
import COLORS from "../../../constants/colors";

import Heading from "./Heading/Heading";
import Body from "./Body/Body";
import Title from "./Title/Title";
import Screen from "./Screen/Screen";
import ScreenHeader from "./Screen/ScreenHeader";
import Sidebar from "./Sidebar/Sidebar";
import SidebarLink from "./SidebarLink/SidebarLink";
import SidebarLinkGroup from "./SidebarLink/SidebarLinkGroup";
import Section from "./Section/Section";
import SectionHeader from "./Section/SectionHeader";

const MainContent = styled.main`
  top: ${NAVBAR_HEIGHT}px;
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  position: relative;
  max-height: calc(100vh - ${NAVBAR_HEIGHT}px);
  width: 100%;
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
  font-family: ${FONTS.FONT_FAMILY.DEFAULT};
  font-size: 14px;
  color: ${COLORS.GRAY_COLORS.GRAY_35};
`;

const CommonLayout = ({
  children,
  notifications,
  clearDisplayedNotification,
  navbarMaxWidth,
  alwaysListen = false,
  menus = [],
  ...navbarProps
}) => (
  <>
    <Navbar
      {...navbarProps}
      maxWidth={navbarMaxWidth}
      alwaysListen={alwaysListen}
      menus={menus}
    />

    <MainContent data-testid="common-layout-main-content" role="main">
      {children}
      {notifications && (
        <Notifications
          notifications={notifications}
          clearDisplayedNotification={clearDisplayedNotification}
        />
      )}
    </MainContent>
  </>
);

CommonLayout.propTypes = {
  /**
   * array of menus to display as part of navbar
   */
  menus: PropTypes.array.isRequired,
  /**
   * if navbar should be constrained to some witdth, set it through this prop. Otherwise 100% of screen will be used
   */
  navbarMaxWidth: PropTypes.number,
  /**
   * determines if menus listen for mouseover events. In "Apple Style Menus"
   * they don't listen until a menu is clicked and stop listening when
   * another menu or the page is clicked
   * {true} means mouse hover over menu to expand it. It hides automatically when mouseout
   * {false} means user needs to click the menu to expand it. It then stays opened until item is clicked or document body is clicked
   */
  alwaysListen: PropTypes.bool,
  /**
   * when you want to set mobile view even though menu links still fit the view
   */
  mobileBreakpoint: PropTypes.number,

  /**
   * slide down notifications
   */
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
      type: PropTypes.oneOf(["error", "success", "info"])
    })
  ),
  /**
   * when notifications are dismissable, provide a callback function
   */
  clearDisplayedNotification: PropTypes.func.isRequired,
  /**
   * in portal and profile, there's few of menu items, let's make user menu items to be expanded right into top level (in mobile view)
   */
  flatifyMobileMenus: PropTypes.bool
};

CommonLayout.Heading = Heading;
CommonLayout.Title = Title;
CommonLayout.Body = Body;
CommonLayout.Sidebar = Sidebar;
CommonLayout.SidebarLink = SidebarLink;
CommonLayout.SidebarLinkGroup = SidebarLinkGroup;
CommonLayout.Screen = Screen;
CommonLayout.ScreenHeader = ScreenHeader;
CommonLayout.Section = Section;
CommonLayout.SectionHeader = SectionHeader;

export default CommonLayout;
