import React from "react";
import PropTypes from "prop-types";
import { StyledCheckbox, StyledInput, StyledLabel } from "./Checkbox.styles";

const Checkbox = ({ label, id, value, onChange, checked, disabled }) => {
  const handleChange = () => {
    !disabled && onChange(id);
  };
  return (
    <StyledCheckbox>
      <StyledInput
        onChange={handleChange}
        id={id}
        value={value}
        type="checkbox"
        checked={checked}
        disabled={disabled}
      />
      <StyledLabel htmlFor={id} disabled={disabled}>
        <span>{label}</span>
      </StyledLabel>
    </StyledCheckbox>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
  label: PropTypes.node,
  checked: PropTypes.bool,
  disabled: PropTypes.bool
};

Checkbox.defaultProps = {
  onChange: () => {}
};

export default Checkbox;
