import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useCallback,
  useRef
} from "react";
import styled, { css, ThemeContext, ThemeProvider } from "styled-components";

import BREAKPOINTS from "../../../constants/breakpoints";
import PropTypes from "prop-types";
import HamburgerMenu from "./HamburgerMenu";
import Menus from "./Menus";
import Logo from "./Logo";
import { createNewEvent } from "../../utils/events";

import useWindowSize from "../../utils/useWindowSizeHook";

import getTheme from "../../utils/theme";
import {
  NAVBAR_HEIGHT,
  LOGO_LINK,
  NAVBAR_DESKTOP_PADDING
} from "./NavbarConstants";

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  font-size: ${props => (props.isMobile ? "16px" : "14px")};
  user-select: none;
  box-sizing: border-box;
  background-color: ${props => props.theme.primaryColor};
  padding: ${props => (props.isMobile ? 0 : `0 ${NAVBAR_DESKTOP_PADDING}px`)};
`;

const NavContent = styled.div`
  height: ${NAVBAR_HEIGHT}px;
  display: flex;
  margin: auto;
  width: ${props => (props.maxWidth ? props.maxWidth + "px" : "100%")};
  transition: width 0.1s;

  ${props =>
    props.maxWidth !== undefined &&
    css`
      @media screen and (max-width: ${props =>
          parseInt(props.maxWidth, 10) +
          (props.isMobile ? 0 : NAVBAR_DESKTOP_PADDING * 2)}px) {
        width: 100%;
      }
    `}
`;

// helper functions to flatten multi-section menus into single-section menus
const isSectioned = tab => tab.menuType === "sectioned_dropdown";
const flattenTab = tab =>
  tab.sections.map(section => ({
    links: section.sectionLinks,
    title: section.sectionHeader
  }));

const menuReducer = (menus, tab) => {
  const sanitizedTab = isSectioned(tab)
    ? menus.concat(flattenTab(tab))
    : menus.concat(tab);

  return sanitizedTab;
};

const flatifyLinks = links => links.map(link => link);

const mobileMenuFlatifyReducer = (menus, tab) => {
  const sanitizedTab =
    tab.links && tab.links.length > 0
      ? menus.concat(flatifyLinks(tab.links))
      : menus.concat(tab);

  return sanitizedTab;
};

const ITEM_ID_PREFIX = "navbar_item_";
let idCounter = 0;
const getMenuItemId = () => `${ITEM_ID_PREFIX}${++idCounter}`;

const getAriaOwns = menuData => menuData.map(menuItem => menuItem.id).join(" ");

export default function Navbar({
  menus,
  alwaysListen = false,
  maxWidth,
  mobileBreakpoint,
  flatifyMobileMenus = false
}) {
  const MOBILE_BREAKPOINT = parseInt(
    mobileBreakpoint || BREAKPOINTS.PHONE_LARGE,
    10
  );
  const windowSize = useWindowSize();

  const [availableMenuWidth, setAvailableMenuWidth] = useState(0);
  const [menuItemsTotalWidth, setMenuItemsTotalWidth] = useState(0);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [menuData, setMenuData] = useState([]);
  const [listen, setListen] = useState(alwaysListen);
  const [isMobile, setIsMobile] = useState(false); //always false for the first time to count width of menu items in desktop view //TODO: decideNeedMobileView()
  const [navbarDesktopPaddings, setNavbarDesktopPaddings] = useState(0);
  const navRef = useRef(null);
  const logoRef = useRef(null);
  const menusRef = useRef(null);

  const theme = getTheme(useContext(ThemeContext));

  // determines if menus listen for mouseover events. In "Apple Style Menus"
  // they don't listen until a menu is clicked and stop listening when
  // another menu or the page is clicked
  const setListenWrapper = useCallback(
    value => {
      setListen(alwaysListen || value);
    },
    [alwaysListen]
  );

  // when the page is clicked, menus should stop listening for mouseover events
  useEffect(() => {
    const clickHandler = () => {
      window.dispatchEvent(createNewEvent("hideMenu"));
      setListenWrapper(false);

      //this hides mobile menu when clicked outside of it
      if (mobileMenuVisible === true) {
        setMobileMenuVisible(false);
      }
    };

    setTimeout(() => {
      window.addEventListener("click", clickHandler);
    });
    return () => window.removeEventListener("click", clickHandler);
  }, [setListenWrapper, mobileMenuVisible]);

  useEffect(() => {
    const getAvailableMenuWidth = () => {
      //in mobile view count the nav (screen) width - logo
      //(hamburger is not needed to be counted, as it will disappear when swapped into desktop view)
      if (isMobile) {
        const { width: navWidth } = navRef.current.getBoundingClientRect();

        const anchor = logoRef.current;
        const { width } = anchor.getBoundingClientRect();
        const { marginLeft, marginRight } = window.getComputedStyle(anchor);
        const anchorWidth =
          width + parseInt(marginLeft, 10) + parseInt(marginRight, 10);

        //whole width - logo width makes available space for rendering menu items;
        return navWidth - anchorWidth - navbarDesktopPaddings;
      } else {
        //in desktop view, get UL element
        const ul = menusRef.current;
        const { overflow } = window.getComputedStyle(ul);

        //hide overflow temporarily to count the available width properly
        ul.style.overflow = "hidden";
        const { width } = ul.getBoundingClientRect();
        ul.style.overflow = overflow; //set back original overflow

        return width;
      }
    };

    setAvailableMenuWidth(getAvailableMenuWidth());
  }, [windowSize, navbarDesktopPaddings, isMobile]);

  //count <nav>'s padding, only in desktop view (which is drawn by default), only once
  useEffect(() => {
    const { paddingLeft, paddingRight } = window.getComputedStyle(
      navRef.current
    );

    setNavbarDesktopPaddings(
      parseInt(paddingLeft, 10) + parseInt(paddingRight, 10)
    );
  }, []);

  // when the component mounts, flatten the menu data.
  // The pre-revlon navbar had sections, the new navbar does not
  useEffect(() => {
    let menusToReduce = menus;

    if (flatifyMobileMenus && isMobile) {
      menusToReduce = menus
        .reduce(mobileMenuFlatifyReducer, [])
        .map(({ onClick, ...menuItem }) => {
          const modifiedOnClick = event => {
            onClick && onClick(event);
            setMobileMenuVisible(false); //always hide mobile menu when item is clicked
          };

          return {
            ...menuItem,
            onClick: modifiedOnClick
          };
        });
    }

    const reducedMenu = menusToReduce.reduce(menuReducer, []).map(menuItem => {
      const newMenuItem = {
        ...menuItem,

        id: menuItem.id || getMenuItemId(),
        title: menuItem.title || menuItem.name,
        profilePicture: menuItem.picture
      };

      if (menuItem.links && menuItem.links.length) {
        newMenuItem.links = menuItem.links.map(({ onClick, ...link }) => {
          const modifiedOnClick = event => {
            onClick && onClick(event);
            setMobileMenuVisible(false); //always hide mobile menu when item is clicked
          };

          return {
            ...link,
            onClick: modifiedOnClick
          };
        });
      }

      return newMenuItem;
    });

    setMenuData(reducedMenu);
  }, [menus, isMobile, flatifyMobileMenus]);

  //count width of rendered menu and decide whether to show desktop or mobile menu
  useLayoutEffect(() => {
    //IE10 (and possibly other browsers) don't support MutationObserver at all.
    //In these browsers only MOBILE_BREAKPOINT is taken into account when resizing screen, not the width of menu items
    if (!navRef.current || !window.MutationObserver) {
      return;
    }

    //very naive counting of item widths. Changed items are awaited only once, then observer disconnects itself
    //should be probably solved better way
    const callback = mutations => {
      let totalWidth = 0;
      mutations.forEach(mutation => {
        mutation.addedNodes.forEach(node => {
          const { width } = node.getBoundingClientRect();
          totalWidth += width;
        });
      });

      setMenuItemsTotalWidth(totalWidth);

      //desktop width of all menu items is counted, so make the menus active (<ul> parent styles changes a bit)
      observer.disconnect();
    };

    const observer = new MutationObserver(callback);

    if (!isMobile) {
      //set an observer to watch for childlist changes, so whenever new menu is added to the view we can count its width.
      //this may happen in as a bulk change (so all menu items are handled in one call)
      //react rendering works as follows in this case:
      // - Nav element + empty HamburgerMenu, Logo and Menus are present when this observer is set.
      // All mentioned elements has their widths already set because of CSS styling is already applied.
      // So Menus element has all the available width within navbar, we can use this value to count if menu items/dropdowns gonna fit it
      observer.observe(navRef.current, {
        childList: true,
        subtree: true
      });
    }
    return () => observer.disconnect();
  }, [isMobile]);

  useEffect(() => {
    //in IE10 menuItemsTotalWidth is always 0; so only MOBILE_BREAKPOINT is taken into acount when deciding isMobile prop.
    const isMobile =
      windowSize.width <= MOBILE_BREAKPOINT ||
      menuItemsTotalWidth > availableMenuWidth;

    setIsMobile(isMobile);
  }, [availableMenuWidth, menuItemsTotalWidth, windowSize, MOBILE_BREAKPOINT]);

  return (
    <ThemeProvider theme={theme}>
      <Nav theme={theme} isMobile={isMobile} ref={navRef}>
        <NavContent
          maxWidth={maxWidth}
          role="menu"
          aria-owns={getAriaOwns(menuData)}
        >
          {isMobile && (
            <HamburgerMenu
              onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
              menuVisible={mobileMenuVisible}
            />
          )}
          <Logo ref={logoRef} href={LOGO_LINK} />
          <Menus
            menus={menuData}
            listen={listen}
            setListen={setListenWrapper}
            alwaysListen={alwaysListen}
            isMobile={isMobile}
            mobileMenuExpanded={mobileMenuVisible}
            ref={menusRef}
          />
        </NavContent>
      </Nav>
    </ThemeProvider>
  );
}

Navbar.propTypes = {
  menus: PropTypes.array.isRequired,
  maxWidth: PropTypes.number,
  mobileBreakpoint: PropTypes.number,
  alwaysListen: PropTypes.bool,
  /**
   * in portal and profile, there's few of menu items, let's make user menu items to be expanded right into top level (in mobile view)
   */
  flatifyMobileMenus: PropTypes.bool
};
