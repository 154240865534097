import React, { useState, useEffect, useContext, forwardRef, useCallback } from "react";
import styled, { ThemeContext } from "styled-components";
import PropTypes from "prop-types";
import Menu from "./Menu";
import { NAVBAR_HEIGHT, NAVBAR_MOBILE_WIDTH } from "./NavbarConstants";

const DesktopMenus = styled.ul`
  margin: 0;
  padding-inline-start: 0;
  list-style-type: none;
  white-space: nowrap;
  flex-grow: 1;
  display: flex;
`;

const MobileMenus = styled(DesktopMenus)`
  display: block;
  position: fixed;
  top: ${NAVBAR_HEIGHT}px;
  left: 0;
  bottom: 0;
  width: ${NAVBAR_MOBILE_WIDTH}px;
  padding: 0 0 0.5rem 0;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.dropdownBgColor};
  margin-left: ${({ expanded }) =>
    expanded ? "0" : -(NAVBAR_MOBILE_WIDTH + 1) + "px"};
  transition: margin-left 0.4s ease-in-out;
`;

const getMenus = (menuData, props) =>
  menuData.map((menu, idx) => (
    <Menu
      listen={props.listen}
      setListen={props.setListen}
      alwaysListen={props.alwaysListen}
      isMobile={props.isMobile}
      menu={menu}
      key={idx}
    />
  ));

const Menus = forwardRef((props, ref) => {
  const { menus, isMobile, mobileMenuExpanded } = props;

  const [menuData, setMenuData] = useState([]);
  const [renderMenus, setRenderMenus] = useState(false);
  const theme = useContext(ThemeContext);

  const handleTransitionEnd = useCallback(() => 
    !mobileMenuExpanded && setRenderMenus(false),
    [mobileMenuExpanded]
  );

  // this is goofy, but the way to align items in flexbox is to add
  // margin-left: auto to the FIRST element of the right-aligned
  // elements. This de-dupes the align prop so the elements squish
  // together on the right instead of taking equal space
  // Additionally, IF at least one item is aligned right, then the
  // right-most (last) element needs to right-align the dropdown menu
  useEffect(() => {
    let aligned = false;
    let data = menus.map(menu => {
      if (menu.align) {
        if (aligned) delete menu.align;
        aligned = true;
      }
      return menu;
    });
    if (aligned) data[data.length - 1].alignDropdown = "right";
    setMenuData(data);
  }, [menus]);

  useEffect(() => {
    if (mobileMenuExpanded) setRenderMenus(true);
  }, [mobileMenuExpanded]);

  useEffect(() => {
    const current = ref?.current;
    if (current) {
      current.addEventListener("transitionend", handleTransitionEnd);
    };
    return () => {
      current.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, [ref, handleTransitionEnd]);

  if (isMobile) {
    return (
      <MobileMenus expanded={mobileMenuExpanded} theme={theme} ref={ref}>
        {renderMenus && getMenus(menuData, props)}
      </MobileMenus>
    );
  }
  return (
    <DesktopMenus theme={theme} ref={ref}>
      {getMenus(menuData, props)}
    </DesktopMenus>
  );
});

Menus.defaultProps = {
  menus: []
};

Menus.propTypes = {
  menus: PropTypes.array,
  listen: PropTypes.bool,
  setListen: PropTypes.func,
  alwaysListen: PropTypes.bool,
  isMobile: PropTypes.bool,
  mobileMenuExpanded: PropTypes.bool
};

export default Menus;
