import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import Duo from "../../../../../lib/duoSdk";

const StyledIFrame = styled.iframe`
  height: 400px;
  width: 100%;
  border: 0;
  display: block;
  padding: 8px;
  box-sizing: border-box;
`;

class DuoFrame extends React.Component {
  componentDidMount = () => {
    const { host, sigRequest } = this.props;

    Duo().init({
      host,
      sig_request: sigRequest,
      submit_callback: this.handleDuoResponse
    });
  };

  handleDuoResponse = duoForm => {
    const { callback } = this.props;
    // read signedResponse from Duo form
    const sigResponse = duoForm.firstChild.value;

    return callback(sigResponse);
  };

  render = () => <StyledIFrame id="duo_iframe" title="Duo 2FA" />;
}

DuoFrame.displayName = "DuoFrame";

DuoFrame.propTypes = {
  host: PropTypes.string.isRequired,
  sigRequest: PropTypes.string.isRequired,
  callback: PropTypes.func
};

DuoFrame.defaultProps = {
  callback: () => {}
};

export default DuoFrame;
