import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import PropTypes from "prop-types";

import Dimmer from "./Dimmer";
import BREAKPOINTS from "../../../constants/breakpoints";

const largePhoneBreakpoint = parseInt(BREAKPOINTS.PHONE_LARGE, 10);

// ui small modal transition visible active modal-dialog
const ModalBox = styled.div`
  display: block;

  z-index: 1001;

  text-align: left;
  background: #ffffff;
  border: none;
  box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.2),
    1px 3px 15px 2px rgba(0, 0, 0, 0.2);
  transform-origin: 50% 25%;
  border-radius: 0.28571429rem;
  user-select: text;

  padding: 0;
  outline: none; //so whole dialog doesn't appear outlined when focused programatically

  max-height: 70%;
  overflow-x: hidden;
  overflow-y: auto;

  ${props =>
    !props.fixedWidth &&
    `
    @media only screen and (min-width: ${largePhoneBreakpoint}) {
      width: 95%;
    }
    @media only screen and (min-width: 768px) {
      width: 70.4%;
    }
    @media only screen and (min-width: 992px) {
      width: 680px;
    }
    @media only screen and (min-width: 1200px) {
      width: 720px;
    }
    @media only screen and (min-width: 1920px) {
      width: 760px;
    }
  `}

  // when height is lower than 400px for desktop view
  // we set the max-height back to 100% for better view
  @media screen and (max-height: ${BREAKPOINTS.MODAL_HEIGHT_LIMIT}) {
    max-height: calc(100% - 30px);
  }

  ${props => props.fixedWidth && `width: ${props.fixedWidth}px;`}

  @media screen and (max-width: ${props => props.fullScreenWidth}px) {
    min-width: none;
    margin: 0 !important;
    border-radius: 0;
    max-height: 100%;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
  }
`;

//VPAT stuff taken/inspired by https://github.com/ghosh/Micromodal/blob/master/lib/src/index.js
const FOCUSABLE_ELEMENTS = [
  "a[href]",
  "area[href]",
  'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
  "select:not([disabled]):not([aria-hidden])",
  "textarea:not([disabled]):not([aria-hidden])",
  "button:not([disabled]):not([aria-hidden])",
  "iframe",
  "object",
  "embed",
  "[contenteditable]",
  '[tabindex]:not([tabindex^="-"])'
];

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.activeElementBeforeOpening = undefined;
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    this.activeElementBeforeOpening = document.activeElement;
    this.modalRef.current.setAttribute("aria-hidden", "false");
    this.addListeners();
    this.focusModal();
  }

  componentWillUnmount() {
    this.activeElementBeforeOpening &&
      this.activeElementBeforeOpening.focus &&
      this.activeElementBeforeOpening.focus();
    this.modalRef.current.setAttribute("aria-hidden", "true"); //probably not needed, as dialog will be probably removed from DOM anyway, but use-cases can be different
    this.removeListeners();
  }

  focusModal = () => {
    this.modalRef.current.focus();
  };

  addListeners = () => {
    this.modalRef.current.addEventListener("keydown", this.onKeyDown);
  };

  removeListeners = () => {
    this.modalRef.current.removeEventListener("keydown", this.onKeyDown);
  };

  onKeyDown = event => {
    if (event.key === "Tab") {
      this.maintainFocus(event);
    }
    if (event.key === "Escape" && this.props.onClose) {
      this.closeDialog(event);
    }
  };

  closeDialog = () => {
    this.props.onClose && this.props.onClose();
  };

  getFocusableNodes = () => {
    const nodes = this.modalRef.current.querySelectorAll(FOCUSABLE_ELEMENTS);
    return Array(...nodes);
  };

  maintainFocus = event => {
    //theoretically getting focusable elements can be done only once, but it may not work for dynamic dialog contents
    //keeping here to avoid weird behavior and hard to catch bugs in future
    const focusableNodes = this.getFocusableNodes();

    if (focusableNodes.length === 0) {
      this.focusModal();
      event.preventDefault();
      return;
    }

    if (!this.modalRef.current.contains(document.activeElement)) {
      focusableNodes[0].focus();
    } else {
      const focusedItemIndex = focusableNodes.indexOf(document.activeElement);

      if (event.shiftKey && focusedItemIndex === 0) {
        focusableNodes[focusableNodes.length - 1].focus();
        event.preventDefault();
      }

      if (!event.shiftKey && focusedItemIndex === focusableNodes.length - 1) {
        focusableNodes[0].focus();
        event.preventDefault();
      }
    }
  };

  onDimmerClick = event => {
    if (!this.modalRef.current.contains(event.target)) {
      this.focusModal();

      if (this.props.closeOnClick && this.props.onClose) {
        this.closeDialog();
      }
    }
  };

  render() {
    const {
      children,
      fixedWidth,
      "data-testid": dataTestId,
      onClose
    } = this.props;

    const fullScreenWidth = Math.max(largePhoneBreakpoint, fixedWidth || 0);
    return ReactDOM.createPortal(
      <Dimmer
        onClick={this.onDimmerClick}
        data-testid={dataTestId && `${dataTestId}-dimmer`}
      >
        <ModalBox
          fixedWidth={fixedWidth}
          fullScreenWidth={fullScreenWidth}
          ref={this.modalRef}
          tabIndex={-1}
          data-testid={dataTestId}
        >
          {React.Children.map(children, child =>
            React.cloneElement(child, {
              breakpoint: fullScreenWidth,
              onClose
            })
          )}
        </ModalBox>
      </Dimmer>,
      document.body
    );
  }
}

Modal.displayName = "Modal";

Modal.defaultProps = {
  closeOnClick: false
};

Modal.propTypes = {
  fixedWidth: PropTypes.number,
  closeOnClick: PropTypes.bool,
  onClose: PropTypes.func
};

export default Modal;
