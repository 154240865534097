import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import PropTypes from "prop-types";

const MenuButton = styled.button`
  position: relative;
  margin: 0 8px 0 0;
  width: 52px;
  letter-spacing: -1px;
  line-height: 44px;
  font-size: 18px;
  transform: translate(-1px, 0) rotate(-90deg);
  font-weight: 600;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.textColor};
  background-color: ${({ theme, toggled }) =>
    toggled ? theme.dropdownBgColor : theme.primaryColor};
  display: "inline-block";
  outline-offset: -4px;
`;

export default function HamburgerMenu({ onClick, menuVisible }) {
  const theme = useContext(ThemeContext);

  return (
    <MenuButton
      aria-label="Open the menu"
      theme={theme}
      onClick={onClick}
      toggled={menuVisible}
    >
      |||
    </MenuButton>
  );
}

HamburgerMenu.propTypes = {
  onClick: PropTypes.func,
  menuVisible: PropTypes.bool
};
