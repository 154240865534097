import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import Icon from "../Icon/Icon";
import { MENU_ITEM_MOBILE_HORIZONTAL_PADDING } from "./NavbarConstants";
import MenuLink from "./MenuLink";
import PropTypes from "prop-types";

const CHEVRON_WIDTH = 12;

const getTextMaxWidth = (isMobile, hasDropdown) => {
  let chevronSpace = 0;
  if (hasDropdown && isMobile) {
    chevronSpace += CHEVRON_WIDTH;
    chevronSpace += MENU_ITEM_MOBILE_HORIZONTAL_PADDING;
  }
  return chevronSpace > 0 ? `calc(100% - ${chevronSpace}px)` : "100%";
};

const Avatar = styled.img`
  display: inline-block;
  color: #999;
  height: 32px;
  width: 32px;
  margin-right: 13px;
  overflow: hidden;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
`;

const InitialsCircle = styled.div`
  display: inline-block;
  height: 32px;
  width: 32px;
  margin-right: 13px;
  overflow: hidden;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  align-items: center;
  font-size: 12px;
  line-height: 32px;
  color: #000;
  background-color: #f3f3f4;
  letter-spacing: 0.2px;
  font-weight: 400;
`;

const ButtonText = styled.div`
  max-width: ${({ isMobile, hasDropdown }) =>
    `${getTextMaxWidth(isMobile, hasDropdown)}`};
  overflow: hidden;
  text-overflow: ellipsis;

  &::after {
    width: 0;
    left: 12px;
    content: "";
    display: block;
    height: 3px;
    background-color: #00a9e0;
    position: absolute;
    bottom: -1px;
    transition: width 0.4s ease-in-out;
  }

  @media only screen and (min-width: 1060px) {
    &[data-active="true"]::after {
      width: calc(100% - 24px);
    }
  }
`;

const getFirstName = name => {
  if (!name) return "";
  return name.split(" ")[0].trim();
};

const getUserMenuTitle = data => {
  if (data.profilePicture) {
    return (
      <>
        <Avatar alt="" src={data.profilePicture} />
        {getFirstName(data.name)}
      </>
    );
  }

  if (data.initials) {
    return (
      <>
        <InitialsCircle data-testid="initials-circle">
          {data.initials}
        </InitialsCircle>
        {getFirstName(data.name)}
      </>
    );
  }

  // Turns out, this is unreachable code, but leaving here
  // incase isUserMenu determination gets changed
  return (
    <>
      <Avatar alt="" src="/images/admin/icons/Avatar-32.svg" />
      {getFirstName(data.name)}
    </>
  );
};

function MenuCaret({ expanded, primaryColorIsLight }) {
  let iconNameDown = "Chevron_down-12-white";
  let iconNameRight = "Chevron_right-12-white";

  if (primaryColorIsLight) {
    iconNameDown = "Chevron_down-12-black";
    iconNameRight = "Chevron_right-12-black";
  }
  return expanded ? (
    <Icon name={iconNameDown} size={`${CHEVRON_WIDTH}px`} />
  ) : (
    <Icon icon={iconNameRight} size={`${CHEVRON_WIDTH}px`} />
  );
}

export default function MenuTitle({
  data,
  expanded,
  isMobile,
  hasDropdown,
  children,
  isActive
}) {
  const theme = useContext(ThemeContext);

  //TODO: looks to be weird condition(?)
  const isUserMenu = data && data.initials;

  const title = data.title;
  const buttonLabel = isUserMenu ? getUserMenuTitle(data) : title;

  let titleProps = {
    "aria-haspopup": hasDropdown,
    target: data.target,
    onClick: hasDropdown ? undefined : data.onClick,
    id: data.id
  };

  if (hasDropdown) {
    titleProps = { ...titleProps, "aria-expanded": expanded };
  }
  if (data.href) {
    titleProps = {
      ...titleProps,
      href: data.href
    };
  }

  return (
    <MenuLink {...titleProps}>
      <ButtonText isMobile={isMobile} hasDropdown={hasDropdown} data-active={isActive}>
        {buttonLabel}
      </ButtonText>
      {isMobile && hasDropdown && (
        <MenuCaret
          expanded={expanded}
          primaryColorIsLight={theme.primaryColorIsLight}
        />
      )}
      {children}
    </MenuLink>
  );
}

MenuTitle.propTypes = {
  data: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string,
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        onClick: PropTypes.func
      })
    ),
    name: PropTypes.string,
    profilePicture: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    href: PropTypes.string
  }),
  expanded: PropTypes.bool,
  isMobile: PropTypes.bool,
  hasDropdown: PropTypes.bool,
  isActive: PropTypes.bool
};
