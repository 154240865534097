import React from "react";
import styled from "styled-components";
import COLORS from "../../../constants/colors";

const TextWrapper = styled.div`
  color: ${COLORS.TEXT_COLORS.DEFAULT};
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;

  pre {
    white-space: normal;
  }
`;

const DescriptionText = ({ className, children }) => (
  <TextWrapper className={className}>{children}</TextWrapper>
);

export default DescriptionText;
