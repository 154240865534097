import React, { Component } from "react";
import PropTypes from "prop-types";

import NotificationItem from "./NotificationItem";

const CSS_HIDE_ANIMATION_TIME = 600; // this must be equal or higher then transition time of hidding animation
const DEFAULT_DISMISS_DELAY = 2000;

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notification: null,
      status: "invisible"
    };
  }

  componentDidMount() {
    this.showNext(this.props);
  }

  componentWillUnmount() {
    clearTimeout(this.showingTimeout);
    clearTimeout(this.hiddingTimeout);
    clearTimeout(this.removeTimeout);
  }

  componentDidUpdate() {
    if (this.props.notifications && this.props.notifications.length) {
      this.showNext(this.props);
    }
  }

  showNext(props) {
    const notification =
      props.notifications && props.notifications.length
        ? props.notifications[0]
        : null;

    if (!this.state.notification && notification) {
      this.setState({
        notification,
        status: "invisible"
      });

      this.showingTimeout = setTimeout(() => {
        this.setState({
          status: "showing"
        });
      }, 0);

      this.planHide(notification);
      props.clearDisplayedNotification(notification.id);
    }
  }

  planHide(notification) {
    this.hiddingTimeout = setTimeout(() => {
      this.setState({
        status: "hiding"
      });

      this.removeTimeout = setTimeout(() => {
        this.setState({
          notification: null
        });

        this.showNext(this.props);
      }, CSS_HIDE_ANIMATION_TIME);
    }, notification.dismissDelay || DEFAULT_DISMISS_DELAY);
  }

  render() {
    const { notification, status } = this.state;

    return (
      notification && (
        <NotificationItem
          type={notification.type}
          status={status}
          fluid
          data-testid="notification-item"
        >
          {notification.message}
        </NotificationItem>
      )
    );
  }
}

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
      type: PropTypes.oneOf(["error", "success", "info"])
    })
  ),
  clearDisplayedNotification: PropTypes.func.isRequired
};

export default Notifications;
