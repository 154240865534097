import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Input from "../../../../ui/FormField/Input";
import Button from "../../../../ui/Button/Button";
import Content from "../../../../ui/Content/Content";
import ContentBlock from "../../../../ui/ContentBlock/ContentBlock";
import DescriptionText from "../../../../ui/Text/DescriptionText";
import SingleValueForm from "../../../../hocs/SingleValueForm/SingleValueForm";

// TODO: this is copy-pasted VerifyYubiKey, let see if these will stay the same in final design
const EnterYubiKey = ({ onSubmit, onChangeClient }) => (
  <SingleValueForm
    onSubmit={onSubmit}
    render={({ invalid, value, onChange }) => (
      <Content data-testid="enter-yubikey-screen">
        <DescriptionText>
          <FormattedMessage defaultMessage="Insert your YubiKey in the USB port and press the button" />
        </DescriptionText>
        <ContentBlock>
          <Input
            id="security-code"
            value={value}
            onChange={event => onChange(event.target.value.toLowerCase())}
            autoFocus
            invalid={invalid}
            size="large"
            type="password"
          />
        </ContentBlock>
        <ContentBlock>
          <Button size="large" type="submit" look="primary">
            <FormattedMessage defaultMessage="Continue" />
          </Button>
        </ContentBlock>
      </Content>
    )}
  />
);

EnterYubiKey.displayName = "EnterYubiKey";

EnterYubiKey.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default EnterYubiKey;
