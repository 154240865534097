import React from "react";
import styled from "styled-components";
import Icon from "../Icon/Icon";

const Box = styled.div`
  height: 56px;
  background-color: #d6291a;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
`;

const Text = styled.div`
  margin-left: 12px;
`;

const DialogNotification = ({ children }) => (
  <Box>
    <Icon name="ErrorWhite_24" size="20px" />
    <Text>{children}</Text>
  </Box>
);

export default DialogNotification;
