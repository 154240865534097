// sizes for inputs, buttons, etc.

export const MEDIUM = "medium";
export const LARGE = "large";
export const BIG = "big";

const SIZES = {
  MEDIUM,
  LARGE,
  BIG
};

export default SIZES;
