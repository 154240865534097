import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import COLORS from "../../../constants/colors";

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.TEXT_COLORS.TITLE};
  background-color: #ffffff;
  height: 64px;
  padding-left: 24px;
  border-bottom: solid 1px ${COLORS.SEPARATOR_COLORS.MEDIUM};
`;

const ICON_IMAGE_SIZE = 32;

const Icon = styled.img`
  height: ${ICON_IMAGE_SIZE}px;
  width: ${ICON_IMAGE_SIZE}px;
  margin-right: 16px;
`;

const Heading = ({ children, icon }) => (
  <HeadingWrapper>
    {icon && <Icon src={icon} />}
    {children}
  </HeadingWrapper>
);

Heading.propTypes = {
  icon: PropTypes.string
};

export default Heading;
