import React, { Component } from "react";
import PropTypes from "prop-types";
import { keys, isNil, pickBy, isEmpty } from "ramda";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import LoginProvideAnswer from "./LoginProvideAnswer";
import SelectQuestion from "./SelectQuestion";

import COLORS from "../../../../../constants/colors";

const SubTitle = styled.div`
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  padding: 16px 24px;
  color: ${COLORS.TEXT_COLORS.TITLE};
  border-bottom: 1px solid ${COLORS.SEPARATOR_COLORS.MEDIUM};
`;

class AnswerSecurityQuestions extends Component {
  constructor(props) {
    super(props);

    this.letUserPickAnswers =
      !isNil(props.requiredAnwersCount) &&
      props.requiredAnwersCount < keys(props.questions).length;

    if (this.letUserPickAnswers) {
      this.state = {
        showSelector: true,
        remaining: keys(props.questions),
        questions: props.questions
      };
    } else {
      const [current, ...remaining] = keys(props.questions);
      this.state = {
        current,
        remaining,
        questions: props.questions
      };
    }

    this.answers = {};
  }

  onSelectQuestion = id => {
    this.setState({
      current: id,
      remaining: this.state.remaining.filter(current => current !== id),
      showSelector: false
    });
  };

  getSubmitAnswer = questionId => answer => {
    this.answers[questionId] = answer;

    if (
      keys(this.answers).length === this.props.requiredAnwersCount ||
      isEmpty(this.state.remaining)
    ) {
      this.props.onComplete(this.answers);
    } else {
      if (this.letUserPickAnswers) {
        this.setState({
          showSelector: true
        });
      } else {
        const [current, ...remaining] = this.state.remaining;
        this.setState({
          current,
          remaining
        });
      }
    }
  };

  render = () => {
    const { current, questions, remaining, showSelector } = this.state;
    const totalQuestions = keys(questions);

    if (showSelector) {
      return (
        <div>
          <SubTitle>
            <FormattedMessage
              defaultMessage="Select Security Question {current} / {total}"
              values={{
                current: totalQuestions.length - remaining.length + 1,
                total: this.props.requiredAnwersCount || totalQuestions.length
              }}
            />
          </SubTitle>
          <SelectQuestion
            questions={pickBy((val, id) => remaining.includes(id), questions)}
            onSelect={this.onSelectQuestion}
          />
        </div>
      );
    } else {
      return (
        // key is used here in quite hacky way (it ensures that the same LoginProvideAnswer is not reused)
        <LoginProvideAnswer
          key={current}
          question={questions[current]}
          onSubmit={this.getSubmitAnswer(current)}
        />
      );
    }
  };
}

AnswerSecurityQuestions.propTypes = {
  questions: PropTypes.object.isRequired,
  requiredAnwersCount: PropTypes.number,
  onComplete: PropTypes.func.isRequired
};

export default AnswerSecurityQuestions;
