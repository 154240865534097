import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import Input from "./Input";
import LinkButton from "../LinkButton/LinkButton";
import SIZES from "../../../constants/sizes";
import { getHeight as getInputHeight } from "../../utils/getSizingDefinitions";

const revealLinkSize = SIZES.MEDIUM;
const heightMap = {
  [SIZES.MEDIUM]: 38,
  [SIZES.LARGE]: 48,
  [SIZES.BIG]: 48
};

const PasswordInputContainer = styled.div`
  position: relative;
  height: ${props =>
    getInputHeight(props.size, heightMap)}px; //to avoid bumping in IE
`;

const PasswordInputStyled = styled(Input)`
  line-height: ${props => getInputHeight(props.size, heightMap)}px;
`;

const PasswordPlaceholder = styled(Input)`
  -webkit-text-security: ${props => (props.revealed ? "initial" : "disc")};
  line-height: ${props => getInputHeight(props.size, heightMap)}px;
`;

const RevealContainer = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  line-height: normal;
`;

export class PasswordInput extends Component {
  constructor(props) {
    super(props);

    this.passwordInputRef = React.createRef();

    this.state = { inputVisible: props.autoFocus, revealed: false };
  }

  toggleInputVisibility = () => {
    if (!this.props.disabled) {
      this.setState({ inputVisible: !this.state.inputVisible });
    }
  };

  toggleReveal = event => {
    if (!this.props.disabled) {
      this.setState({ revealed: !this.state.revealed }, () => {
        this.passwordInputRef.current.focus();
      });
    }
  };

  onBlur = event => {
    this.toggleInputVisibility();
    typeof this.props.onBlur === "function" &&
      this.props.onBlur(event.target.value);
  };

  render() {
    const { value, disabled } = this.props;
    // you can override revealed from outside (use-case: reveal password on generate password)
    const revealed = this.props.revealed || this.state.revealed;
    const showRevealToggle = !disabled && value && !this.props.revealed;

    return (
      <PasswordInputContainer size={this.props.size}>
        {this.state.inputVisible ? (
          <PasswordInputStyled
            {...this.props}
            type={revealed ? "text" : "password"}
            onBlur={this.onBlur}
            autoFocus
          />
        ) : (
          <PasswordPlaceholder
            ref={this.passwordInputRef}
            as="div"
            id={this.props.id}
            tabIndex={this.props.disabled ? undefined : 0}
            onClick={this.toggleInputVisibility}
            onFocus={this.toggleInputVisibility}
            revealed={revealed}
            disabled={disabled}
            size={this.props.size}
            invalid={this.props.invalid}
          >
            {revealed ? value : value.replace(/./g, "•")}
          </PasswordPlaceholder>
        )}
        {showRevealToggle && (
          <RevealContainer size={this.props.size}>
            <LinkButton onClick={this.toggleReveal} size={revealLinkSize}>
              {revealed ? (
                <FormattedMessage defaultMessage="Hide" />
              ) : (
                <FormattedMessage defaultMessage="Show" />
              )}
            </LinkButton>
          </RevealContainer>
        )}
      </PasswordInputContainer>
    );
  }
}

PasswordInput.defaultProps = {
  autoFocus: false,
  disabled: false,
  invalid: false,
  autoComplete: "off",
  autoCorrect: "off",
  spellCheck: "false",
  maxLength: 255,
  revealed: false,
  size: SIZES.MEDIUM
};

PasswordInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoCorrect: PropTypes.string,
  spellCheck: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  autoCapitalize: PropTypes.string,
  revealed: PropTypes.bool,
  size: PropTypes.oneOf([SIZES.MEDIUM, SIZES.LARGE, SIZES.BIG])
};

export default PasswordInput;
