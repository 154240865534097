import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyledTag } from "./Tag.styles";
import COLORS from "../../../constants/colors";

class Tag extends Component {
  static propTypes = {
    id: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    icon: PropTypes.string,
    color: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),
    className: PropTypes.string
  };

  static defaultProps = {
    onClick: () => {},
    color: COLORS.ACTION_COLORS.BLUE_DARKER
  };

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    const { text, id, onClick } = this.props;
    onClick(text, id || null);
  }

  renderIcon() {
    return <span onClick={this.handleClose}>x</span>;
  }

  render() {
    const { style, color, children, text, id } = this.props;
    const colorStyle = { backgroundColor: color };
    const newStyle = Object.assign({}, style, colorStyle);

    return (
      <StyledTag id={id} style={newStyle}>
        {text || children}
        {this.renderIcon()}
      </StyledTag>
    );
  }
}

export default Tag;
