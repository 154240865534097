import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";

import Button from "../../../../ui/Button/Button";
import Content from "../../../../ui/Content/Content";
import ContentBlock from "../../../../ui/ContentBlock/ContentBlock";
import TextField from "../../../../ui/FormField/TextField";
import Label from "../../../../ui/FormField/Label";
import sendingMailGif from "./images/sending_mail.gif";
import { fadeIn } from "../../../../../constants/keyframes";

const messages = defineMessages({
  sending: {
    defaultMessage: "Sending"
  }
});

const FadeInButton = styled(Button)`
  animation: ${fadeIn} 0.3s ease-in-out;
`;

const CenteredSmallTextField = styled(TextField)`
  font-size: 12px;
  text-align: center;
  margin-top: 12px;
  line-height: 16px;
`;

const EmptyCenteredSmallTextField = styled(CenteredSmallTextField)`
  margin-bottom: 16px;
`;

const SendingMailGif = styled.img`
  width: 140px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const GifButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const DEFAULT_SECONDS = 120;

const getInitialState = () => ({
  secLeft: DEFAULT_SECONDS
});

class WaitForEmail extends Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();
  }

  componentDidMount = () => {
    this.timer = setInterval(this.countdown, 1000);
  };

  componentWillUnmount = () => {
    clearInterval(this.timer);
  };

  countdown = () => {
    let seconds = this.state.secLeft;
    if (seconds === 0) {
      clearInterval(this.timer);
    } else {
      this.setState({
        secLeft: seconds - 1
      });
    }
  };

  resetTimer = () => {
    this.setState(getInitialState());
  };

  renderButton = () => {
    if (this.state.secLeft === 0) {
      return (
        <FadeInButton
          data-testid="try-again-button"
          size="large"
          look="regular"
          onClick={() => {
            this.props.onTryAgain();
            this.resetTimer();
          }}
        >
          <FormattedMessage defaultMessage="Try Again" />
        </FadeInButton>
      );
    } else {
      return (
        <GifButton>
          <SendingMailGif 
            alt={this.props.intl.formatMessage(messages.sending)}
            src={sendingMailGif} 
          />
          <Button
            data-testid="sending-button"
            size="large"
            look="regular"
            disabled
          />
        </GifButton>
      );
    }
  };

  renderRemainingSecs = () => {
    if (this.state.secLeft > 0) {
      return (
        <CenteredSmallTextField>
          <FormattedMessage
            defaultMessage={`You can try again in {secLeft, number} {secLeft, plural,
                        one {second}
                        other {seconds}
                      }`}
            values={{ secLeft: this.state.secLeft }}
          />
        </CenteredSmallTextField>
      );
    } else {
      return <EmptyCenteredSmallTextField />;
    }
  };

  render = () => {
    return (
      <Content data-testid="show-email-screen">
        {this.props.email ? (
          <ContentBlock>
            <Label forId="email">
              <FormattedMessage defaultMessage="An access link was sent to" />
            </Label>
            <TextField large>{this.props.email}</TextField>
          </ContentBlock>
        ) : (
          <ContentBlock>
            <FormattedMessage defaultMessage="An access link was sent to your email" />
          </ContentBlock>
        )}
        <ContentBlock>
          <FormattedMessage defaultMessage="Please click the link on your email to continue" />
        </ContentBlock>
        <ContentBlock>
          {this.renderButton()}
          {this.renderRemainingSecs()}
        </ContentBlock>
      </Content>
    );
  };
}

WaitForEmail.displayName = "WaitForEmail";

WaitForEmail.propTypes = {
  onTryAgain: PropTypes.func.isRequired,
  email: PropTypes.string
};

export default injectIntl(WaitForEmail);
