// A
import Avatar_24 from "./svg/Avatar-24.svg";
import Alert from "./svg/alert.svg";
import App_24 from "./svg/app-24.svg";

// C
import Checkmark_16 from "./svg/Checkmark-16.svg";
import Checkmark_16_thick from "./svg/Checkmark-16-thick.svg";
import Checkmark_24 from "./svg/Checkmark-24.svg";
import CheckmarkCheckbox from "./svg/Checkmark-checkbox.svg";
import ChevronDown_12 from "./svg/Chevron_down-12.svg";
import ChevronDown_12_white from "./svg/Chevron_down-12-white.svg";
import ChevronDown_12_black from "./svg/Chevron_down-12-black.svg";
import ChevronDown_16 from "./svg/Chevron_down-16.svg";
import ChevronLeft_12 from "./svg/Chevron_left-12.svg";
import ChevronLeft_12_white from "./svg/Chevron_left-12-white.svg";
import ChevronLeft_12_black from "./svg/Chevron_left-12-black.svg";
import ChevronLeft_16 from "./svg/Chevron_left-16.svg";
import ChevronLeft_16_white from "./svg/Chevron_left-16-white.svg";
import ChevronRight_12 from "./svg/Chevron_right-12.svg";
import ChevronRight_12_white from "./svg/Chevron_right-12-white.svg";
import ChevronRight_12_black from "./svg/Chevron_right-12-black.svg";
import ChevronRight_16 from "./svg/Chevron_right-16.svg";
import ChevronUp_12 from "./svg/Chevron_up-12.svg";
import ChevronUp_16 from "./svg/Chevron_up-16.svg";
import CircleDisabled_16 from "./svg/Circle_disabled-16.svg";
import Clock_24 from "./svg/Clock-24.svg";
import Close_12 from "./svg/Close-12.svg";
import Close_16 from "./svg/Close-16.svg";
import Close_16_thick from "./svg/Close-16-thick.svg";
import Close_24 from "./svg/Close-24.svg";

// D
import Download_16 from "./svg/Download-16.svg";

// E
import Ellipsis_16 from "./svg/Ellipsis-16.svg";
import Ellipsis_24 from "./svg/Ellipsis-24.svg";
import Error_24 from "./svg/Error-24.svg";
import ErrorWhite_24 from "./svg/error-white-24.svg";
import Eye_24 from "./svg/icon-eye-24.svg";

// F
import Forbid_16 from "./svg/forbid-16.svg";

// G
import Gear_24 from "./svg/Gear-24.svg";
import Grip_16 from "./svg/Grip-16.svg";

// I
import InProgress_24 from "./svg/In_progress-24.svg";
import InfoOutline_16 from "./svg/Info_outline-16.svg";
import InfoOutline_24 from "./svg/Info_outline-24.svg";
import Info_16 from "./svg/Info-16.svg";
import Info_24 from "./svg/Info-24.svg";

// K
import Key_24 from "./svg/key-24.svg";

// L
import Library_24 from "./svg/Library-24.svg";
import Lock_16 from "./svg/Lock-16.svg";
import Lock_24 from "./svg/Lock-24.svg";

// M
import MagnifyingGlass_24 from "./svg/Magnifying_glass-24.svg";
import Mail_24 from "./svg/Mail-24.svg";
import Menu_24 from "./svg/Menu-24.svg";
import Minus_16 from "./svg/Minus-16.svg";
import Minus_24 from "./svg/Minus-24.svg";

// P
import Papers_24 from "./svg/Papers-24.svg";
import Pencil_24 from "./svg/Pencil-24.svg";
import Person_24 from "./svg/Person-24.svg";
import Person_Plus_24 from "./svg/icon-person-plus-24.svg";
import Plus_16 from "./svg/Plus-16.svg";
import Plus_24 from "./svg/Plus-24.svg";

// Q
import QuestionMarkCircleOutline_16 from "./svg/Question_mark_circle_outline-16.svg";
import QuestionMarkCircle_16 from "./svg/Question_mark_circle-16.svg";

// R
import Refresh_24 from "./svg/Refresh-24.svg";
import Revert_16 from "./svg/revert-16.svg";
import Revert_24 from "./svg/Revert-24.svg";
import Reset_Password_24 from "./svg/reset-password-24.svg";

// S
import Star_Outline_16 from "./svg/icon-star-outline-16.svg";
import Star_Yellow_16 from "./svg/icon-star-16-yellow.svg";
import StatusCircle_12 from "./svg/Status_circle-12.svg";
import Success_16 from "./svg/Success-16.svg";
import Success_24 from "./svg/Success-24.svg";

// T
import Timer_24 from "./svg/Timer-24.svg";
import Trashcan_24 from "./svg/Trashcan-24.svg";
import TriangleDown_12 from "./svg/Triangle-down-12.svg";

// V
import VerticalEllipsis_16 from "./svg/Vertical_ellipsis-16.svg";
import VerticalEllipsis_24 from "./svg/Vertical_ellipsis-24.svg";

// W
import Warning_24 from "./svg/Warning-24.svg";

export const svgList = {
  // A
  Avatar_24: Avatar_24,
  Alert,
  "App-24": App_24,
  // C
  "Checkmark-16": Checkmark_16,
  "Checkmark-16-thick": Checkmark_16_thick,
  "Checkmark-24": Checkmark_24,
  "Checkmark-checkbox": CheckmarkCheckbox,
  "Chevron_down-12": ChevronDown_12,
  "Chevron_down-12-white": ChevronDown_12_white,
  "Chevron_down-12-black": ChevronDown_12_black,
  "Chevron_down-16": ChevronDown_16,
  "Chevron_left-12": ChevronLeft_12,
  "Chevron_left-12-white": ChevronLeft_12_white,
  "Chevron_left-12-black": ChevronLeft_12_black,
  "Chevron_left-16": ChevronLeft_16,
  "Chevron_left-16-white": ChevronLeft_16_white,
  "Chevron_right-12": ChevronRight_12,
  "Chevron_right-12-white": ChevronRight_12_white,
  "Chevron_right-12-black": ChevronRight_12_black,
  "Chevron_right-16": ChevronRight_16,
  "Chevron_up-12": ChevronUp_12,
  "Chevron_up-16": ChevronUp_16,
  "Circle_disabled-16": CircleDisabled_16,
  "Clock-24": Clock_24,
  "Close-12": Close_12,
  "Close-16": Close_16,
  "Close-16-thick": Close_16_thick,
  "Close-24": Close_24,
  // D
  "Download-16": Download_16,
  // E
  "Ellipsis-16": Ellipsis_16,
  "Ellipsis-24": Ellipsis_24,
  "Error-24": Error_24,
  ErrorWhite_24,
  "Eye-24": Eye_24,
  // F
  "Forbid-16": Forbid_16,
  // G
  "Gear-24": Gear_24,
  "Grip-16": Grip_16,
  // I
  "In_progress-24": InProgress_24,
  "Info_outline-16": InfoOutline_16,
  "Info_outline-24": InfoOutline_24,
  "Info-16": Info_16,
  "Info-24": Info_24,
  // K
  "Key-24": Key_24,
  // L
  "Library-24": Library_24,
  "Lock-16": Lock_16,
  "Lock-24": Lock_24,
  // M
  "Magnifying_glass-24": MagnifyingGlass_24,
  "Mail-24": Mail_24,
  "Menu-24": Menu_24,
  "Minus-16": Minus_16,
  "Minus-24": Minus_24,
  // P
  "Papers-24": Papers_24,
  "Pencil-24": Pencil_24,
  "Person-24": Person_24,
  "Person-Plus-24": Person_Plus_24,
  "Plus-16": Plus_16,
  "Plus-24": Plus_24,
  // Q
  "Question_mark_circle_outline-16": QuestionMarkCircleOutline_16,
  "Question_mark_circle-16": QuestionMarkCircle_16,
  // R
  "Refresh-24": Refresh_24,
  "Revert-16": Revert_16,
  "Revert-24": Revert_24,
  "Reset-Password-24": Reset_Password_24,
  // S
  "Star-Outline-16": Star_Outline_16,
  "Star-Yellow-16": Star_Yellow_16,
  "Status_circle-12": StatusCircle_12,
  "Success-16": Success_16,
  "Success-24": Success_24,
  // T
  "Timer-24": Timer_24,
  "Trashcan-24": Trashcan_24,
  "Triangle-down-12": TriangleDown_12,
  // V
  "Vertical_ellipsis-16": VerticalEllipsis_16,
  "Vertical_ellipsis-24": VerticalEllipsis_24,
  // W
  "Warning-24": Warning_24
};
