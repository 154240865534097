import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import SingleValueForm from "../../../../hocs/SingleValueForm/SingleValueForm";
import Input from "../../../../ui/FormField/Input";
import Label from "../../../../ui/FormField/Label";
import Button from "../../../../ui/Button/Button";
import Content from "../../../../ui/Content/Content";
import ContentBlock from "../../../../ui/ContentBlock/ContentBlock";
import TextField from "../../../../ui/FormField/TextField";

export const BottomButton = styled(Button)`
  margin-top: 12px;
`;

export const TopButton = styled(Button)`
  margin-bottom: 12px;
`;

const EnterRegistrationCode = ({ onSubmit, contactInfo, onTryAgain }) => (
  <SingleValueForm
    onSubmit={onSubmit}
    render={({ invalid, value, onChange }) => (
      <Content data-testid="enter-sms-code-screen">
        <ContentBlock>
          {
            contactInfo ? (
              <div>
                <Label forId="contact-info">
                  <FormattedMessage defaultMessage="A confirmation code was sent to" />
                </Label>
                <TextField large> {contactInfo}</TextField>
              </div>
            ) : (
              <Label forId="contact-info">
                <FormattedMessage defaultMessage="A confirmation code was sent to your configured address." />
              </Label>
            )
          }
        </ContentBlock>
        <ContentBlock>
          <Label forId="security-code">
            <FormattedMessage defaultMessage="Enter your confirmation code" />
          </Label>
          <Input
            id="security-code"
            value={value}
            onChange={event => onChange(event.target.value)}
            autoFocus
            invalid={invalid}
            size="large"
          />
        </ContentBlock>
        <ContentBlock>
          <TopButton size="large" type="submit" look="primary">
            <FormattedMessage defaultMessage="Continue" />
          </TopButton>
          <BottomButton
            data-testid="try-again-button"
            size="large"
            look="regular"
            onClick={onTryAgain}
          >
            <FormattedMessage defaultMessage="Try Again" />
          </BottomButton>
        </ContentBlock>
      </Content>
    )}
  />
);

EnterRegistrationCode.displayName = "EnterRegistrationCode";

EnterRegistrationCode.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onTryAgain: PropTypes.func.isRequired,
  contactInfo: PropTypes.string
};

export default EnterRegistrationCode;
