import React, { useRef } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { takeWhile } from "ramda";
import COLORS from "../../../constants/colors";
import Link from "../Link/Link";
import { svgList } from "../Icon/svgList";

import BREAKPOINTS from "../../../constants/breakpoints";

const ALIGNMENT = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right"
};

const CELL_PADDING = 24;
const CELL_INNER_PADDING = 8;
const HEADER_LEFT = 10;

const TDContent = styled.div`
  //first and last cell contents are overridden below in TableCellInternal for different padding
  padding-left: ${CELL_INNER_PADDING}px;
  padding-right: ${CELL_INNER_PADDING}px;

  ${props =>
    props.contentAlign === ALIGNMENT.RIGHT &&
    css`
      text-align: right;
    `}
  ${props =>
    props.contentAlign === ALIGNMENT.CENTER &&
    css`
      text-align: center;
    `}
`;

const TableCellInternal = styled.td`
  width: ${props => props.width};
  position: relative;
  color: ${COLORS.GRAY_COLORS.GRAY_35};
  height: ${props => (props.isHeader ? "58px" : "50px")};
  
    
  ${props =>
    props.isHighlighted &&
    css`
      border-top: 1px solid ${COLORS.STATUS_COLORS.SUCCESS};
    `}
  border-bottom: 1px solid
    ${props =>
      props.isHighlighted
        ? COLORS.STATUS_COLORS.SUCCESS
        : COLORS.GRAY_COLORS.GRAY_94};
  ${props =>
    props.isHeader &&
    css`
      border-top: 1px solid ${COLORS.GRAY_COLORS.GRAY_94};
      border-bottom: 1px solid ${COLORS.GRAY_COLORS.GRAY_90};
    `};
  font-weight: ${props => (props.isHeader ? 500 : 400)};
  line-height: 1.23;

  &:first-child {
    border-left: 1px solid
      ${props =>
        props.isHighlighted
          ? COLORS.STATUS_COLORS.SUCCESS
          : COLORS.GRAY_COLORS.GRAY_94};
    
    ${TDContent} {
      padding-left: ${CELL_PADDING}px;
    }
  }

  &:last-child {
    border-right: 1px solid
      ${props =>
        props.isHighlighted
          ? COLORS.STATUS_COLORS.SUCCESS
          : COLORS.GRAY_COLORS.GRAY_94};

    ${TDContent} {
      padding-right: ${CELL_PADDING}px;
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.PORTAL_MIDDLE}) {
    &:first-child {
      border-top-width: ${props => (props.hideTopBorderMobile ? 0 : "1px")};
    }
    border-left: 0;
    border-right: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-grow: 1;
    height: 100%;
    align-items: center;
  }
`;

const RowTitle = styled.div`
  display: inline-block;
  background-color: white;
  border-radius: 2px;
  color: ${props =>
    props.isHighlighted
      ? COLORS.STATUS_COLORS.SUCCESS
      : COLORS.GRAY_COLORS.GRAY_35};
  padding: 0px ${CELL_PADDING - HEADER_LEFT}px;
  position: absolute;
  left: ${HEADER_LEFT}px;
  top: -10px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  max-width: calc(100% - 20px); //10px left + 10px right //TODO
`;

const DragIcon = styled.div`
  position: absolute;
  opacity: 0.3;
  background: transparent url(${svgList["Grip-16"]}) no-repeat center;
  left: 4px;
  height: 16px;
  background-size: 16px;
  width: 16px;
`;

const TableRowInternal = styled.tr`
  margin-top: ${props => (props.isHighlighted ? "-1px" : "0px")};
  background-color: #ffffff;
  font-size: 13px;
  position: relative;

  ${props =>
    props.draggable &&
    css`
      cursor: move;
    `}

  &:hover {
    ${props => 
      !props.isHeader && css`
        background-color: ${COLORS.ONELOGIN_GRAY_COLORS.ONELOGIN_GRAY_LIGHT};
      `
    }

    ${DragIcon} {
      opacity: 1;
    }
  }
`;
/* PORTAL-2630: The principle here is to allow cells to take up the space of all following
 * invisible cells, up until the next non-invisible cell. The result is sum of columns to merge (colspan attribute).
 */
const calculateColSpan = (cells, index) => {
  const postCells = cells.slice(index + 1);
  const absorbedCells = takeWhile(cell => cell.hidden, postCells);

  return 1 + absorbedCells.length;
};

export const TableRow = ({
  dragData,
  href,
  cells,
  rowTitle,
  isHeader,
  isHighlighted,
  hideTopBorderMobile,
  onDrop = () => void 0
}) => {
  let headerRendered,
    wasFirstVisibleColumnRendered = false;

  const row = useRef();
  const isDraggable = () => !!dragData;
  const ondragstart = event => {
    try {
      const crt = row.current.cloneNode(true); //TODO: having a TR element appended directly to body el is weird. Should be a valid and styled element with limits in movement to the left and right as proposed in zeplin.

      crt.style.border = "1px solid edeff0";
      crt.style.backgroundColor = "white";
      crt.style.position = "absolute";
      crt.style.left = "-999999px";
      crt.id = "proxyElement";
      row.current.parentNode.appendChild(crt);

      event.dataTransfer.setDragImage(crt, 0, 0);

      event.dataTransfer.setData("application/json", JSON.stringify(dragData));
      event.dataTransfer.effectAllowed = "move";
    } catch (err) {
      //no op
    }
  };

  const ondragover = ev => ev.preventDefault();

  const ondrop = ev => {
    ev.preventDefault();
    try {
      let data = ev.dataTransfer.getData("application/json");
      onDrop(null, JSON.parse(data), dragData);
    } catch (err) {
      onDrop(err, null, dragData);
    }
  };

  const ondragend = ev => {
    ev.preventDefault();
    try {
      document.body.removeChild(document.getElementById("proxyElement"));
    } catch {
      // no op
    }
  };

  return (
    <>
      <TableRowInternal
        draggable={isDraggable()}
        onDragStart={isDraggable() ? ondragstart : () => void 0}
        onDragOver={isDraggable() ? ondragover : () => void 0}
        onDrop={isDraggable() ? ondrop : () => void 0}
        onDragEnd={isDraggable() ? ondragend : () => void 0}
        ref={row}
        isHeader={isHeader}
      >
        {cells.map((column, index) => {
          let showDragIcon = false;
          const renderRowTitle = rowTitle && !headerRendered;

          const content = (
            <TDContent contentAlign={column.contentAlign}>
              {column.content}
            </TDContent>
          );

          if (!column.hidden) {
            if (!wasFirstVisibleColumnRendered && isDraggable()) {
              showDragIcon = true;
              wasFirstVisibleColumnRendered = true;
            }
            if (renderRowTitle) {
              headerRendered = true;
            }

            return (
              <TableCellInternal
                width={column.width}
                colSpan={calculateColSpan(cells, index)}
                isHeader={isHeader}
                isHighlighted={isHighlighted}
                hideTopBorderMobile={hideTopBorderMobile}
                key={index}
              >
                {showDragIcon && <DragIcon />}
                {renderRowTitle && (
                  <RowTitle isHighlighted={isHighlighted}>{rowTitle}</RowTitle>
                )}
                {!!href ? <Link href={href}>{content}</Link> : content}
              </TableCellInternal>
            );
          } else {
            return null;
          }
        })}
      </TableRowInternal>
    </>
  );
};

TableRow.propTypes = {
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      width: PropTypes.string, //width containing units
      content: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
        .isRequired,
      contentAlign: PropTypes.oneOf(
        Object.keys(ALIGNMENT).map(align => ALIGNMENT[align])
      ), //used object.keys method to avoid polyfill object.values for IE11
      hidden: PropTypes.bool
    })
  ),

  href: PropTypes.string,
  isHeader: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  rowTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hideTopBorderMobile: PropTypes.bool, //TODO:?
  //dragData needs to be stringifyable, so test it here
  dragData: (props, propName, componentName) => {
    try {
      JSON.stringify(props[propName]);
    } catch (e) {
      return new Error(
        "Invalid prop `" +
          propName +
          "` supplied to `" +
          componentName +
          "`. Validation failed."
      );
    }
  }
};

export default TableRow;
