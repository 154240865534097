import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import Button from "../../../ui/Button/Button";
import Content from "../../../ui/Content/Content";
import ContentBlock from "../../../ui/ContentBlock/ContentBlock";
import Icon from "../../../ui/Icon/Icon";
import WarningIcon from "../../../../svg/alert.svg";

const CenteredIcon = styled(Icon)`
  width: 48px;
  height: 48px;
  align-self: center;
`;

const ContactAdmin = ({ onContinue, warningMessage }) => (
  <Content data-testid="contact-admin-screen">
    <ContentBlock>
      <CenteredIcon src={WarningIcon} />
    </ContentBlock>
    <ContentBlock>
      {warningMessage || (
        <FormattedMessage defaultMessage="Please contact admin for more information." />
      )}
    </ContentBlock>
    <ContentBlock>
      <Button onClick={onContinue} size="large" look="primary">
        <FormattedMessage defaultMessage="Continue" />
      </Button>
    </ContentBlock>
  </Content>
);

ContactAdmin.displayName = "ContactAdmin";

ContactAdmin.propTypes = {
  onContinue: PropTypes.func.isRequired,
  warningMessage: PropTypes.object
};

export default ContactAdmin;
