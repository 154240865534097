import React, { useEffect, useRef, useState } from "react";

import COLORS from "../../../constants/colors";
import FONTS from "../../../constants/fonts";
import KEY_CODES from "../../../constants/keyCodes";
import PropTypes from "prop-types";
import styled from "styled-components";
import useKeyDown from "../../utils/useKeyDown";

const RadioGroupWrapper = styled.div`
  height: auto;
  width: 100%;
  box-sizing: border-box;
`;

const RadioButtonWrapper = styled.div`
  color: ${COLORS.GRAY_COLORS.GRAY_25};
  font-family: ${FONTS.FONT_FAMILY.DEFAULT};
  display: flex;
  align-items: center;
  min-height: 24px;
  position: relative;
  margin-bottom: 10px;

  &:focus-visible {
    outline: none;
  }
`;

const RadioButtonDot = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: ${props =>
    props.inputDisabled ? `${COLORS.GRAY_COLORS.GRAY_90}` : `${COLORS.WHITE}`};
  border: 1px solid ${COLORS.GRAY_COLORS.GRAY_55};
  opacity: ${props => (props.inputDisabled ? 0.3 : 1)};
`;

const RadioButtonInput = styled.input`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin: 0 8px 0 0;
  opacity: ${props => (props.disabled ? 0 : 1)};

  &:checked + ${RadioButtonDot} {
    background: ${COLORS.WHITE};
    border: 1px solid
      ${props =>
        props.disabled
          ? `${COLORS.GRAY_COLORS.GRAY_55}`
          : `${COLORS.ACTION_COLORS.ONELOGIN_BLUE_DARK}`};
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 4px;
      background: ${props =>
        props.disabled
          ? `${COLORS.GRAY_COLORS.GRAY_55}`
          : `${COLORS.ACTION_COLORS.ONELOGIN_BLUE_DARK}`};
    }
  }
`;

const RadioButtonLabel = styled.label`
  font-size: 14px;
  display: inline-block;
  position: relative;
  line-height: 18px;
  color: ${props =>
    props.checked
      ? `${COLORS.GRAY_COLORS.GRAY_25}`
      : `${COLORS.GRAY_COLORS.GRAY_35}`};
  opacity: ${props => (props.inputDisabled ? 0.3 : 1)};
}`;

const RadioGroup = ({ name, options, selected, onChange, disabled }) => {
  let selectedIndex = options.findIndex(item => item.value === selected);
  // manually setting initial index to 0 if selected is undefined
  if (selectedIndex === -1) {
    selectedIndex = 0;
  }
  const [index, setIndex] = useState(selectedIndex);

  const radioGroupRef = useRef();
  const radioButtonRef = useRef([]);
  const radioButtonTextRef = useRef([]);

  useEffect(() => {
    radioButtonRef.current = radioButtonRef.current.slice(0, options.length);
    radioButtonTextRef.current = radioButtonTextRef.current.slice(
      0,
      options.length
    );
  }, [options]);

  useEffect(() => {
    if (index !== selectedIndex) {
      setIndex(selectedIndex);
    }
  }, [index, selectedIndex]);

  const handleChange = value => {
    !disabled && onChange(value);
  };

  const handleClickChange = value => {
    if (disabled) {
      return;
    }

    const clickedIndex = options.findIndex(item => item.value === value);

    if (clickedIndex !== -1 && radioButtonTextRef.current[clickedIndex]) {
      radioButtonTextRef.current[clickedIndex].focus();
    }

    onChange(value);
  };

  const onArrowDown = event => {
    event.preventDefault();
    event.stopPropagation();

    const nextIndex = Math.min(index + 1, options.length - 1);
    if (nextIndex !== index) {
      radioButtonRef.current[nextIndex].focus();

      if (radioButtonTextRef.current[nextIndex]) {
        radioButtonTextRef.current[nextIndex].focus();
      }

      setIndex(nextIndex);
      handleChange(options[nextIndex].value);
    }
  };

  const onArrowUp = event => {
    event.preventDefault(); //do not scroll the page
    event.stopPropagation();

    const prevIndex = Math.max(index - 1, 0);
    if (prevIndex !== index) {
      radioButtonRef.current[prevIndex].focus();

      if (radioButtonTextRef.current[prevIndex]) {
        radioButtonTextRef.current[prevIndex].focus();
      }

      setIndex(prevIndex);
      handleChange(options[prevIndex].value);
    }
  };

  const onConfirm = event => {
    if (index !== -1) {
      if (
        !radioButtonTextRef.current[index] ||
        (radioButtonTextRef.current[index] &&
          radioButtonTextRef.current[index] !== document.activeElement)
      ) {
        event.preventDefault(); //do not scroll the page
        event.stopPropagation();
      }
    }

    handleClickChange(options[index].value);
  };

  useKeyDown(radioGroupRef, {
    [KEY_CODES.ARROW_DOWN]: onArrowDown,
    [KEY_CODES.ARROW_UP]: onArrowUp,
    [KEY_CODES.SPACE]: onConfirm
  });

  return (
    <>
      <RadioGroupWrapper role="radiogroup" ref={radioGroupRef} tabIndex="-1">
        {options.map((option, index) => {
          const getTabIndex = () => {
            // only allow tab to first item if no item selected before
            // or allow tab on the selected item
            if (
              !disabled &&
              ((!selected && index === 0) || option.value === selected)
            ) {
              return "0";
            } else {
              return "-1";
            }
          };

          return (
            <RadioButtonWrapper
              key={option.id}
              tabIndex={getTabIndex()}
              ref={el => (radioButtonRef.current[index] = el)}
            >
              <RadioButtonInput
                id={option.id}
                type="radio"
                name={name}
                value={option.value}
                checked={option.value === selected}
                aria-checked={option.value === selected}
                onChange={() => handleChange(option.value)}
                disabled={disabled}
                tabIndex="-1"
              />
              <RadioButtonDot
                inputDisabled={disabled}
                onClick={() => handleClickChange(option.value)}
              />
              {typeof option.text === "string" && (
                <RadioButtonLabel
                  inputDisabled={disabled}
                  htmlFor={option.id}
                  checked={option.value === selected}
                >
                  {option.text}
                </RadioButtonLabel>
              )}
              {typeof option.text === "object" && (
                <>
                  {React.cloneElement(option.text, {
                    ref: el => (radioButtonTextRef.current[index] = el)
                  })}
                </>
              )}
            </RadioButtonWrapper>
          );
        })}
      </RadioGroupWrapper>
    </>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ]).isRequired,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
    })
  ).isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default RadioGroup;
