import styled from "styled-components";
import PropTypes from "prop-types";
import COLORS from "../../../../constants/colors";
import breakpoints from "../../../../constants/breakpoints";

const Body = styled.div`
  padding: ${({ noHeading }) => (noHeading ? "32px" : "78px 32px 32px")};
  background: ${COLORS.GRAY_98};
  display: grid;
  grid-template-columns: 233px 1fr;
  grid-column-gap: 2em;

  @media only screen and (max-width: ${breakpoints.PORTAL_MIDDLE}) {
    grid-template-columns: 1fr;

    padding: ${({ noHeading }) => (noHeading ? "32px 1em" : "78px 1em 32px")};
  }
`;

Body.propTypes = {
  noHeading: PropTypes.bool
};

export default Body;
