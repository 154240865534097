import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import COLORS from "../../../../constants/colors";

export const SidebarGroupWrapper = styled.div`
  border-bottom: 1px solid ${COLORS.GRAY_COLORS.GRAY_92};
  margin-bottom: 10px;

  a:last-child {
    margin-bottom: 8px;
  }
`;

const SidebarGroupWrapperTitle = styled.h3`
  display: block;
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 14px;
  padding-left: 20px;
  font-size: 13px;
  line-height: 16px;
  line-height: 1rem;
  color: ${COLORS.ACTION_COLORS.BLUE_DARKER};
  text-decoration: none;
  font-weight: 500;
`;

const SidebarLinkGroup = ({ children, title }) => (
  <SidebarGroupWrapper>
    <SidebarGroupWrapperTitle>{title}</SidebarGroupWrapperTitle>
    {children}
  </SidebarGroupWrapper>
);

SidebarLinkGroup.propTypes = {
  title: PropTypes.string.isRequired
};

export default SidebarLinkGroup;
