import React, { useState, useRef, useEffect, useCallback } from "react";
import { string, func, number, bool, shape, oneOfType, arrayOf, node, checkPropTypes } from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import Load from "../Load/Load";
import SearchBox from "../SearchBox/SearchBox";

import COLORS from "../../../constants/colors";
import { ENTER, ARROW_UP, ARROW_DOWN, TAB, ESC, SPACE } from "../../../constants/keyCodes";
import useKeyDown from "../../utils/useKeyDown";
import useWindowSize from "../../utils/useWindowSizeHook";
import useScroll from "../../utils/useScrollHook";
import useOnClickOutside from "../../utils/useOnClickOutside";

const Container = styled.div`
  ${props => props.inline && "display: inline-block;"}
`;

const StyledSearchBox = styled(SearchBox)`
  width: 100%;
`;

const Dropdown = styled.div.attrs(({ dirTop, windowSize, toggleRect }) => {
  // style properties that change often belong in the .attrs() method
  const position = dirTop
    // css calc func is erased by jsdom in spec, so js is required
    ? { bottom: `${windowSize.height - toggleRect.top}px` }
    : { top: `max(0px, ${toggleRect.bottom}px)` };
  const style = {
    maxHeight: `${dirTop ? toggleRect.top : windowSize.height - toggleRect.bottom}px`,
    left: `min(${windowSize.width - 310}px, ${toggleRect.left}px)`,
    ...position
  };
  return { style };
})`
  border: 1px solid ${COLORS.ONELOGIN_GRAY_COLORS.ONELOGIN_SILVER};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px;
  width: 100%;
  min-width: max(310px, ${props => props.toggleRect.width}px);
  display: flex;
  width: auto;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  position: fixed; // necessary to appear on top of modal
  z-index: 1;
`;

const StyledLoad = styled(Load)`
  padding: 1em 0;
`;

const Results = styled.div`
  overflow-y: auto;
  & > div {
    outline-offset: -2px;
  }
`;

const Item = styled.div`
  padding: 10px;
  color: ${COLORS.TEXT_COLORS.TITLE};
  cursor: pointer;
  transition: background-color 0.1s;
  background: ${COLORS.WHITE};
  font-size: 14px;
  text-align: left;
  &:hover {
    background-color: ${COLORS.ACTION_COLORS.BLUE_ITEM_HIGHLIGHT};
  }
  &[aria-selected="true"] {
    background-color: ${COLORS.ACTION_COLORS.BLUE_ITEM_HIGHLIGHT};
  }
`;

const SubLabel = styled.div`
  font-size: 10px;
`;

const NoResults = styled.div`
  padding: 10px;
  color: ${COLORS.TEXT_COLORS.PLACEHOLDER};
  font-size: 14px;
  text-align: left;
`;

/**
 * A component containing a search bar and list of selectable elements
 * Only allows a single child which requires forwardRef if the child is a functional React component
 * @param {Boolean} loading [default = false] - describes if the content is loading
 * @param {Number|String} id - a value given to dropdown and prepended to the search box, options list, and option elements' ids
 * @param {Function} onSearch - a function that runs when enter is pressed on the search input OR when typing if searchOnType = true
 * @param {Function} onSelect - a function that runs when a list element is selected
 * @param {[Object]|Null} searchResults - null OR an array of objects that render in a list
 *   @param {Number|String} searchResults[].label - the label of the rendered list element
 *   @param {Number|String} searchResults[].id - [optional] a unique value set to the list element key
 * @param {Boolean} dirTop - [optional] a boolean describing if the dropdown renders below or above the toggle element
 * @param {Boolean} searchOnType - [optional] a boolean describing if the onSearch function runs on type
 * @param {Boolean} inline - [optional] describes if the toggle is displayed inline or not
 * @example
 *   <SearchDropdown
 *     loading={loading}
 *     id="dropdown"
 *     onSearch={searchValue => filterResults(searchValue)}
 *     onSelect={() => alert("selected list element")}
 *     searchResults={[{ label: "Daniel Craig", id: 007 }]}
 *     dirTop
 *     searchOnType
 *     inline
 *   >
 *     <button>Toggle Dropdown</button>
 *   </SearchDropdown>
 * @type {{( props: { loading?: boolean, id: (number|string), onSearch: function, onSelect: function, searchResults?: [{ label: (number|string), id?: (number|string) }], dirTop?: boolean, searchOnType?: boolean, inline?: boolean }): JSX.Element}}
 */
const SearchDropdown = ({
  loading = false,
  id,
  onSearch,
  onSelect,
  searchResults,
  dirTop,
  searchOnType,
  inline,
  children,
  className
}) => {
  // States
  const [open, setOpen] = useState(false);
  const [highlightIndex, setHighlightIndex] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  // Refs
  const containerEl = useRef();
  const toggleWrapperEl = useRef();
  const toggleEl = useRef();
  const dropdownEl = useRef();
  const searchBoxEl = useRef();
  const options = [];

  const windowSize = useWindowSize();
  useScroll(toggleEl, open);

  const focusToggle = () => toggleEl.current.focus();

  const closeDropdown = event => {
    event.stopPropagation();
    setOpen(false);
    if (event.keyCode === ESC) focusToggle();
  };

  const onTogglerClick = ({ keyCode, target }) => {
    const isEnter = keyCode === ENTER;
    const isButton = target.tagName === "BUTTON";
    // prevents button keyboard click from running function twice
    if (!(isEnter && isButton)) setOpen(prevOpen => !prevOpen);
  };

  const onItemClick = event => {
    const selectedItem = event.currentTarget.dataset.item || JSON.stringify(searchResults?.[highlightIndex]);
    if (selectedItem) {
      onSelect(selectedItem);
      setOpen(false);
      setHighlightIndex(null);
      setSearchValue("");
      // prevents re-opening of dropdown on enter-select item
      if (event.keyCode === ENTER) setTimeout(() => focusToggle());
    };
  };

  const onArrowPress = event => {
    event.preventDefault(); // stop scrolling
    const isDownArrow = event.keyCode === ARROW_DOWN;

    let currentIndex = highlightIndex !== null ? highlightIndex : -1;
    isDownArrow ? currentIndex++ : currentIndex--;

    // loop options
    if (currentIndex < 0) currentIndex = options.length - 1;
    else if (currentIndex >= options.length) currentIndex = 0;
    const newOption = options[currentIndex];

    if (newOption) {
      // scrollIntoView not accessible in spec, therefore optional
      newOption.scrollIntoView && newOption.scrollIntoView({ behavior: "smooth", block: "nearest" });
      setHighlightIndex(currentIndex);
    }
  };

  const handleChange = newSearchValue => {
    setHighlightIndex(null);
    setSearchValue(newSearchValue);
    searchOnType && onSearch(newSearchValue);
  };

  useEffect(() => {
    const currentToggle = toggleEl.current;
    const callback = ([entry]) => !entry.isIntersecting && setOpen(false);
    const observer = new IntersectionObserver(callback, { threshold: 0 });
    if (currentToggle) observer.observe(currentToggle);
    return () => observer.unobserve(currentToggle);
  }, [toggleEl]);

  useKeyDown(toggleWrapperEl, {
    [ENTER]: onTogglerClick,
    [SPACE]: onTogglerClick
  });

  useKeyDown(dropdownEl, {
    [ARROW_UP]: onArrowPress,
    [ARROW_DOWN]: onArrowPress,
    [ENTER]: onItemClick,
    [TAB]: closeDropdown,
    [ESC]: closeDropdown
  });

  useEffect(() => {
    if (open) {
      setHighlightIndex(null);
      searchBoxEl.current.focus();
    }
  }, [open]);

  useOnClickOutside(
    [toggleEl, dropdownEl], 
    useCallback(() => setOpen(false), [setOpen])
  );

  return (
    <Container className={className} ref={containerEl} inline={inline}>
      <span
        onClick={onTogglerClick}
        ref={toggleWrapperEl}
        role="button"
        tabIndex="-1"
      >
        {React.cloneElement(
          React.Children.only(children),
          { ref: toggleEl }
        )}
      </span>
      {open &&
        <Dropdown 
          id={id} 
          ref={dropdownEl} 
          dirTop={dirTop} 
          toggleRect={toggleEl.current?.getBoundingClientRect()}
          windowSize={windowSize}
        >
          <StyledSearchBox
            id={`${id}-search-box`}
            ariaControls={`${id}-options-list`}
            aria-activedescendant={`${id}-option-${highlightIndex}`}
            onSearch={onSearch}
            handleChange={handleChange}
            value={searchValue}
            showButton={false}
            ref={searchBoxEl}
          />
          <StyledLoad collapsed={true} loading={loading}>
            <Results id={`${id}-options-list`} role="listbox" aria-labelledby={`${id}-search-box`}>
              {searchResults?.length ?
                searchResults.map((item, idx) => (
                  <Item
                    role="option"
                    key={(item.id || item.id === 0) ? item.id : idx}
                    id={`${id}-option-${idx}`}
                    onClick={onItemClick}
                    aria-selected={idx === highlightIndex}
                    data-item={JSON.stringify(item)}
                    ref={el => options[idx] = el}
                  >
                    <div>{item.label}</div>
                    {item.sublabel && <SubLabel>{item.sublabel}</SubLabel>}
                  </Item>
                ))
                :
                <NoResults><FormattedMessage defaultMessage="No Results"/></NoResults>
              }
            </Results>
          </StyledLoad>
        </Dropdown>
      }
    </Container>
  );
};

const nullOrShape = (props, propName, componentName) => {
  const prop = props[propName];
  if (prop === null) return;
  checkPropTypes({ [propName]: arrayOf(optionShape).isRequired }, { [propName]: prop }, "prop", componentName);
};

const optionShape = shape({
  label: oneOfType([string, number]).isRequired,
  id: oneOfType([string, number])
});

SearchDropdown.propTypes = {
  loading: bool,
  id: oneOfType([number, string]).isRequired,
  onSearch: func.isRequired,
  onSelect: func.isRequired,
  searchResults: nullOrShape,
  dirTop: bool,
  searchOnType: bool,
  children: node.isRequired
};

export default SearchDropdown;
