import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import COLORS from "../../../constants/colors";

const SPINNER_HEIGHT = 72;
const MSG_FONT_SIZE = 14;
const MSG_SPINNER_DISTANCE = 12;

const LoadingContainer = styled.div`
  position: absolute;
  left: calc(50% - ${SPINNER_HEIGHT / 2}px);
  top: calc(50% - ${SPINNER_HEIGHT / 2}px);
  text-align: center;
`;

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  transform-origin: center center;
  height: 100%;
  width: 100%;
  max-width: ${SPINNER_HEIGHT}px;
  max-height: ${SPINNER_HEIGHT}px;
  margin: auto;

  & .path {
    stroke-dasharray: 75, 120;
    stroke-dashoffset: 0;
    stroke: ${COLORS.ACTION_COLORS.ONELOGIN_BLUE};
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 120;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 65, 120;
      stroke-dashoffset: -30px;
    }
    100% {
      stroke-dasharray: 65, 120;
      stroke-dashoffset: -100px;
    }
  }
`;

const LoadingMessage = styled.span`
  font-size: ${MSG_FONT_SIZE}px;
  display: block;
  margin-top: ${MSG_SPINNER_DISTANCE}px;
`;

export const Loading = ({ msg, className }) => (
  <LoadingContainer className={className}>
    {/** className required for styled-components re-styling of Loading component e.g. styled(Loading) */}
    <StyledSpinner viewBox="20 20 40 40">
      <circle
        className="path"
        cx="40"
        cy="40"
        r="16"
        fill="none"
        strokeWidth="1"
      />
    </StyledSpinner>
    <LoadingMessage>{msg}</LoadingMessage>
  </LoadingContainer>
);

Loading.propTypes = {
  msg: PropTypes.string
};

export default Loading;
