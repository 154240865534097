import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Tooltip from "./Tooltip";

const ToggleTooltipWrapper= styled.div`
  padding: 7px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

const TooltipData = styled.p`
  width: max-content;
  max-width: 300px;
  padding: 0 20px;
  font-size: 14px;
`;

class ToggleTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
  }

  render() {
    const { dir, lean, info, children } = this.props;
    const { hover } = this.state;

    return (
      <ToggleTooltipWrapper
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        data-testid="toggle-tooltip"
      >
        <Tooltip
          open={hover}
          dir={dir}
          lean={lean}
          ariaLabel={info}
          tooltip={() => <TooltipData>{info}</TooltipData>}
        >
          {children}
        </Tooltip>
      </ToggleTooltipWrapper>
    );
  }
}

ToggleTooltip.propTypes = {
  dir: PropTypes.string,
  lean: PropTypes.string,
  info: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default ToggleTooltip;
