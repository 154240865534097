import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import logo from "./images/onelogin-logo.svg";

const ErrorWrapper = styled.div`
  height: 100%;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #1a1919;
`;

const Image = styled.img`
  display: block;
  padding: 35px;
  max-width: 250px;
  width: 50%;
`;

const ErrorHeader = styled.div`
  font-size: 30px;
  line-height: normal;
`;

const ErrorMessage = styled.div`
  font-size: 18px;
  margin: 40px;
`;

const ErrorHelp = styled.div`
  font-size: 18px;
  line-height: 1.67;
  margin: 0 40px 40px;
`;

const FatalError = ({ message }) => {
  return (
    <ErrorWrapper>
      <Image src={logo} />
      <ErrorHeader>
        <FormattedMessage defaultMessage="An unexpected error seems to have occurred." />
      </ErrorHeader>
      <ErrorMessage>{message}</ErrorMessage>
      <ErrorHelp>
        <FormattedMessage
          defaultMessage="We're investigating the problem and expect to be back soon. Please wait a few minutes{break}and refresh your browser, or visit our <link>Status Page</link>."
          values={{
            link: (...chunks) => (
              <a href="https://www.onelogin.com/status">{chunks}</a>
            ),
            break: <br />
          }}
        />
      </ErrorHelp>
    </ErrorWrapper>
  );
};

FatalError.propTypes = {
  message: PropTypes.string.isRequired
};

export default FatalError;
