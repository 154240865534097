import { useEffect, useState } from "react";

/**
 * A custom hook that re-renders the component every time the given ref's position is changed by scrolling.
 * @param {Object} ref - an object reference created by React's useRef hook.
 * @param {Element} [ref.current] - an html element that is used to control whether the component should rerender or not
 * @param {Boolean} condition - a condition that controls whether the "onScroll" event listener is added
 * @example
 *   const toggleRef = useRef();
 *   const [isOpen, setOpen] = useState(false);
 *   useScroll(toggleRef, isOpen);
 */
const useScroll = (ref, condition = true) => {
  const [, setScroll] = useState(0);

  useEffect(() => {
    let prevRect = ref.current?.getBoundingClientRect();
    const handleScroll = () => {
      if (ref.current) {
        const currentRect = ref.current.getBoundingClientRect();
        if (prevRect) for (let prop in prevRect) {
          if (prevRect[prop] !== currentRect[prop]) {
            setScroll(prev => prev + 1);
            prevRect = currentRect;
            break;
          }
        }
      }
    };

    if (condition) window.addEventListener("scroll", handleScroll, true);

    return () => window.removeEventListener("scroll", handleScroll, true);
  }, [ref, condition]);
};

export default useScroll;
