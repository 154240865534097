import PropTypes from "prop-types";
import styled from "styled-components";
import COLORS from "../../../constants/colors"; // Colors

const StyledTextField = styled.div`
  color: ${COLORS.TEXT_COLORS.DEFAULT};
  font-size: ${props => (props.large ? "16px" : "14px")};
  line-height: 28px;
`;

StyledTextField.defaultProps = {
  large: false
};

StyledTextField.propTypes = {
  large: PropTypes.bool
};

StyledTextField.displayName = "TextField";

export default StyledTextField;
