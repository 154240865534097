import React, { useState, useEffect } from "react";
import styled from "styled-components";
import COLORS from "../../../../constants/colors";

export function useSetDocumentTitle(title = "Administration") {
  const [formatted, setFormatted] = useState(`${title} | OneLogin`);
  useEffect(() => {
    document.title = formatted;
  }, [formatted]);
  useEffect(() => {
    setFormatted(`${title} | OneLogin`);
  }, [title]);
  return formatted;
}

const TitleContainer = styled.h1`
  display: inline-block;
  color: ${COLORS.GRAY_COLORS.GRAY_25};
  font-size: 21px;
  font-weight: normal;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  align-items: center;
`;

const Title = ({ children, ...props }) => {
  useSetDocumentTitle(children);
  return <TitleContainer {...props}>{children}</TitleContainer>;
};

Title.propTypes = {};

export default Title;
