import React from "react";
import styled from "styled-components";

import Notifications from "../Notifications/Notifications";

const Wrap = styled.div`
  /** to make <Notifications /> hide properly */
  overflow: hidden;
`;

const Content = styled.div`
  position: relative;
  text-align: center;
  margin: auto;
  padding: 24px;
  line-height: 1.4;

  @media screen and (max-width: ${props => props.breakpoint}px) {
    flex-direction: column;
    padding: 32px 20px;
    margin: 0;
  }
`;

const NotificationsWrap = styled.div`
  position: relative;
`;

const DialogContent = ({
  notifications,
  clearDisplayedNotification,
  children
}) => {
  return (
    <Wrap>
      {notifications && (
        <NotificationsWrap>
          <Notifications
            notifications={notifications}
            clearDisplayedNotification={clearDisplayedNotification}
          />
        </NotificationsWrap>
      )}
      <Content>{children}</Content>
    </Wrap>
  );
};

export default DialogContent;
