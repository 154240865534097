import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Label from "../../../../ui/FormField/Label";
import Input from "../../../../ui/FormField/Input";
import Button from "../../../../ui/Button/Button";
import Content from "../../../../ui/Content/Content";
import ContentBlock from "../../../../ui/ContentBlock/ContentBlock";
import MultiValueForm from "../../../../hocs/MultiValueForm/MultiValueForm";

const RegisterWithCredentialIdAndTwoOtps = ({
  onSubmit,
  children,
  credentialIdText,
  otp1Label,
  otp2Label
}) => (
  <MultiValueForm
    fieldNames={["credentialId", "otp1", "otp2"]}
    onSubmit={onSubmit}
    render={({ credentialId, otp1, otp2 }) => (
      <Content data-testid="register-with-two-otp">
        {children}
        <ContentBlock>
          <Label forId="credentialId">{credentialIdText}</Label>
          <Input
            id="credentialId"
            value={credentialId.value}
            onChange={event => credentialId.onChange(event.target.value)}
            invalid={credentialId.invalid}
            size="large"
          />
        </ContentBlock>
        <ContentBlock>
          <Label forId="otp1">{otp1Label}</Label>
          <Input
            id="otp1"
            value={otp1.value}
            onChange={event => otp1.onChange(event.target.value)}
            invalid={otp1.invalid}
            size="large"
          />
        </ContentBlock>
        <ContentBlock>
          <Label forId="otp2">
            <Label forId="otp2">{otp2Label}</Label>
          </Label>
          <Input
            id="otp2"
            value={otp2.value}
            onChange={event => otp2.onChange(event.target.value)}
            invalid={otp2.invalid} 
            size="large"
          />
        </ContentBlock>
        <ContentBlock>
          <Button
            size="large"
            type="submit"
            look="primary"
            data-testid="continue-button"
          >
            <FormattedMessage defaultMessage="Continue" />
          </Button>
        </ContentBlock>
      </Content>
    )}
  />
);

RegisterWithCredentialIdAndTwoOtps.displayName =
  "RegisterWithCredentialIdAndTwoOtps";

RegisterWithCredentialIdAndTwoOtps.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.element,
  otp1Label: PropTypes.element,
  otp2Label: PropTypes.element
};

export default RegisterWithCredentialIdAndTwoOtps;
