import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";

import Button from "../../../../ui/Button/Button";
import Content from "../../../../ui/Content/Content";
import ContentBlock from "../../../../ui/ContentBlock/ContentBlock";
import TextField from "../../../../ui/FormField/TextField";
import COLORS from "../../../../../constants/colors";
import callingGif from "./images/calling@2x.gif";
import { fadeIn } from "../../../../../constants/keyframes";

const messages = defineMessages({
  calling: {
    defaultMessage: "Calling"
  }
});

const FadeInButton = styled(Button)`
  animation: ${fadeIn} 0.3s ease-in-out;
`;

const StyledBlock = styled.div`
  background-color: ${COLORS.GRAY_COLORS.GRAY_96};
  border-width: 1px;
  border-color: ${props =>
    props.copied ? COLORS.STATUS_COLORS.SUCCESS : "transparent"};
  font-size: 28px;
  line-height: 1;
  letter-spacing: 8px;
  transition: border-color 0.2s;
  height: 60px;
  margin-top: 8px;
`;

const StyledCode = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`;

const PhoneNumber = styled(TextField)`
  margin-top: 8px;
`;

const Centered = styled.div`
  text-align: center;
`;

const CenteredFlex = styled(Centered)`
  display: flex;
  flex-direction: column;
`;

const CenteredSmallTextField = styled(TextField)`
  font-size: 12px;
  text-align: center;
  margin-top: 12px;
  line-height: 16px;
`;

const EmptyCenteredSmallTextField = styled(CenteredSmallTextField)`
  margin-bottom: 16px;
`;

const CallingGif = styled.img`
  width: 140px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const GifButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const DEFAULT_SECONDS = 30;

const getInitialState = () => ({
  secLeft: DEFAULT_SECONDS
});

class ShowPinCode extends Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();
  }

  componentDidMount = () => {
    this.timer = setInterval(this.countdown, 1000);
  };

  componentWillUnmount = () => {
    clearInterval(this.timer);
  };

  countdown = () => {
    let seconds = this.state.secLeft;
    if (seconds === 0) {
      clearInterval(this.timer);
    } else {
      this.setState({
        secLeft: seconds - 1
      });
    }
  };

  resetTimer = () => {
    this.setState({
      secLeft: DEFAULT_SECONDS
    });
  };

  renderButton = () => {
    if (this.state.secLeft === 0) {
      return (
        <FadeInButton
          data-testid="try-again-button"
          size="large"
          look="regular"
          onClick={() => {
            this.props.onTryAgain();
            this.resetTimer();
          }}
        >
          <FormattedMessage defaultMessage="Try Again" />
        </FadeInButton>
      );
    } else {
      return (
        <GifButton>
          <CallingGif
            alt={this.props.intl.formatMessage(messages.calling)}
            src={callingGif}
          />
          <Button
            data-testid="calling-button"
            size="large"
            look="regular"
            disabled
          />
        </GifButton>
      );
    }
  };

  renderRemainingSecs = () => {
    if (this.state.secLeft > 0) {
      return (
        <CenteredSmallTextField>
          <FormattedMessage
            defaultMessage={`You can try again in {secLeft, number} {secLeft, plural,
                        one {second}
                        other {seconds}
                      }`}
            values={{ secLeft: this.state.secLeft }}
          />
        </CenteredSmallTextField>
      );
    } else {
      return <EmptyCenteredSmallTextField />;
    }
  };

  render = () => {
    return (
      <Content data-testid="show-pincode-screen">
        <ContentBlock>
          <FormattedMessage defaultMessage="Calling the below phone number:" />
          <PhoneNumber large> {this.props.phoneNumber}</PhoneNumber>
        </ContentBlock>
        <ContentBlock>
          <FormattedMessage defaultMessage="Enter this code when receiving the call:" />
          <CenteredFlex>
            <StyledBlock>
              <StyledCode>{this.props.verificationToken}</StyledCode>
            </StyledBlock>
          </CenteredFlex>
        </ContentBlock>
        <ContentBlock>
          {this.renderButton()}
          {this.renderRemainingSecs()}
        </ContentBlock>
      </Content>
    );
  };
}

ShowPinCode.displayName = "ShowPinCode";

ShowPinCode.propTypes = {
  onTryAgain: PropTypes.func.isRequired,
  verificationToken: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired
};

export default injectIntl(ShowPinCode);
