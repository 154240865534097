import React from "react";
import styled from "styled-components";

import Icon from "../Icon/Icon";
import COLORS from "../../../constants/colors";

const StyledDiv = styled.div`
  padding: 1em;
  display: flex;
  font-size: smaller;
  background-color: ${COLORS.GRAY_COLORS.GRAY_94};
  align-items: center;
  color: ${COLORS.TEXT_COLORS.DEFAULT};
  border-left: 2px solid ${COLORS.STATUS_COLORS.WARNING};
`;

const StyledIcon = styled(Icon)`
  height: inherit;
  width: inherit;
  padding-right: .5em;
`;

const InfoText = ({ children, ...props }) => (
  <StyledDiv {...props}>
    <StyledIcon alt="" name="Info_outline-16"/>
    {children}
  </StyledDiv>
);

export default InfoText;
