import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
  useState
} from "react";
import styled, { css } from "styled-components";

import COLORS from "../../../constants/colors";
import Input from "../FormField/Input";
import KEY_CODES from "../../../constants/keyCodes";
import PropTypes from "prop-types";
import SIZES from "../../../constants/sizes";
import { equals } from "ramda";
import getBorderColorDefinition from "../../utils/getBorderColorDefinition";
import { svgList } from "../Icon/svgList";
import useKeyDown from "../../utils/useKeyDown";
import useOnClickOutside from "../../utils/useOnClickOutside";
import useWindowSize from "../../utils/useWindowSizeHook";

const DROPDOWN_BORDER_WIDTH = 1;
const DROPDOWN_BORDER_RADIUS = 2;

const CONTENT_FONT_SIZE = 14;
const CHEVRON_WIDTH = 12;
const CHEVRON_SPACING = 12;
const ITEM_ID_PREFIX = "item_id_";
const OWNED_LISTBOX_PREFIX = "owned_listbox_";

const DropdownField = styled(Input)`
  background-image: url(${svgList["Chevron_down-12"]});
  background-position: right ${CHEVRON_SPACING}px center;
  background-repeat: no-repeat;
  cursor: pointer;
  border-bottom-left-radius: ${props =>
    props.expanded ? "0px" : `${DROPDOWN_BORDER_RADIUS}px`};
  border-bottom-right-radius: ${props =>
    props.expanded ? "0px" : `${DROPDOWN_BORDER_RADIUS}px`};
  padding-right: ${CHEVRON_SPACING * 2 + CHEVRON_WIDTH}px;
`;

const DropdownWrap = styled.div`
  position: relative;
  width: 100%;
`;

const getListMaxWidth = listMaxWidth => {
  if (typeof listMaxWidth === "number") {
    return `${listMaxWidth}px`;
  } else if (typeof listMaxWidth === "string") {
    return listMaxWidth;
  }

  return "initial";
};

const ItemsWrap = styled.ul`
  position: absolute;
  width: 100%;
  max-width: ${props => getListMaxWidth(props.listMaxWidth)};
  border: ${DROPDOWN_BORDER_WIDTH}px solid ${COLORS.GRAY_COLORS.GRAY_88};
  ${props => getBorderColorDefinition(props)};
  border-top-width: ${props => (props.showListTopBorder ? `1px` : `0px`)};
  transition: border-color 0.2s;
  overflow: auto;
  border-bottom-left-radius: ${DROPDOWN_BORDER_RADIUS}px;
  border-bottom-right-radius: ${DROPDOWN_BORDER_RADIUS}px;
  border-top-left-radius: ${props =>
    props.showListTopBorder ? `${DROPDOWN_BORDER_RADIUS}px` : 0};
  border-top-right-radius: ${props =>
    props.showListTopBorder ? `${DROPDOWN_BORDER_RADIUS}px` : 0};
  background-color: #fff;
  z-index: 1; //to appear above other elements
  box-sizing: border-box;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  //zero margin and padding for IE browsers
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

const DropdownItem = styled.li`
  list-style-type: none;
  font-size: 13px;
  display: flex;
  align-items: center;
  min-height: 38px;
  padding: 0 12px;
  cursor: pointer;
  color: ${COLORS.GRAY_COLORS.GRAY_35};

  ${props =>
    props.selected
      ? css`
          background-color: ${COLORS.ACTION_COLORS.BLUE_ITEM_HIGHLIGHT};
        `
      : ``}

  &:hover {
    background-color: ${COLORS.ACTION_COLORS.BLUE_ITEM_HIGHLIGHT};
    color: ${COLORS.GRAY_COLORS.GRAY_25};
  }
`;

const EmptyDropdown = styled.div`
  color: ${COLORS.GRAY_COLORS.GRAY_78};
  padding: 6px 12px;
  font-size: ${CONTENT_FONT_SIZE}px;
`;

const getIdByValue = value => `${ITEM_ID_PREFIX}${value}`;

const reducer = (state, action) => {
  switch (action.type) {
    case "setSelectedItem":
      //item selected by user from the list of options (either by mouseclick or enter/space key press)
      return {
        ...state,
        selectedItem: { ...action.selectedItem },
        highlightedItem: { ...action.selectedItem }
      };

    case "listUpdate":
      //clonned `list` of options, updated for `id` value
      return {
        ...state,
        listWithIds: [...action.listWithIds]
      };

    case "highlightNext":
      return {
        ...state,
        highlightedItem:
          state.listWithIds[
            Math.min(
              state.highlightedItem.index + 1,
              state.listWithIds.length - 1 //not higher than list.length
            )
          ]
      };

    case "highlightPrev":
      return {
        ...state,
        highlightedItem:
          state.listWithIds[Math.max(state.highlightedItem.index - 1, 0)]
      };

    case "setHighlightedItem":
      //highlighted item in the list of options (could be different to what selected item is, as there could be one option highlighted while other can be clicked)
      //highlight just makes the item visible and does not fire onChange event when changing
      //selectItem fires onChange event
      return { ...state, highlightedItem: action.highlightedItem };

    default:
      return { ...state };
  }
};

const injectIds = list =>
  list.map((item, index) => ({
    ...item,
    id: item.id || getIdByValue(item.value),
    index
  }));

const Dropdown = forwardRef(
  (
    {
      list,
      onChange,
      size,
      disabled,
      invalid,
      value,
      placeholder,
      noItemsPlaceholder,
      ariaLabel,
      triggerEl,
      listMaxWidth,
      showListTopBorder,
      id = Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
    },
    ref
  ) => {
    const windowSize = useWindowSize();
    const [state, dispatch] = useReducer(reducer, {
      listWithIds: [],
      selectedItem: { text: "" }, //empty text to avoid React's "A component is changing an uncontrolled input of type text to be controlled" warning
      highlightedItem: {}
    });

    //update list of items when new list props are passed from outside
    useEffect(() => {
      //  - update given `list` for generated ids so they can be used for ARIA handling
      const listWithIds = injectIds(list);
      if (!equals(listWithIds, state.listWithIds)) {
        dispatch({ type: "listUpdate", listWithIds });

        const selectedItem = listWithIds.find(item => item.value === value);
        if (selectedItem && state.selectedItem?.value !== selectedItem?.value) {
          dispatch({ type: "setSelectedItem", selectedItem });
        }

        const itemToHighlight = selectedItem || listWithIds[0] || {};
        if (state.highlightedItem?.value !== itemToHighlight.value) {
          dispatch({
            type: "setHighlightedItem",
            highlightedItem: itemToHighlight
          });
        }
      }
    }, [
      list,
      state.highlightedItem,
      state.listWithIds,
      state.selectedItem,
      value
    ]);

    //handle open/closed state
    const [isOpen, setOpen] = useState(false);

    //isHover state to make two sibling elements hovered at the same time
    const [isHover, setHover] = useState(undefined);

    const dropdownWrapRef = useRef();
    const dropdownFieldRef = useRef();

    //hide dropdown on outer click
    useOnClickOutside(
      dropdownWrapRef,
      useCallback(() => setOpen(false), [setOpen])
    );

    //when item is selected in from dropdown list, fire the onChange handler with selected value
    //check for previous value so dropdown doesn't fire in case user selects the same value twice
    const doSelectionAndFocus = useCallback(
      selectedItem => {
        setOpen(false);
        if (state.selectedItem?.value !== selectedItem?.value) {
          dispatch({ type: "setSelectedItem", selectedItem });
          onChange(selectedItem.value);
        }

        //selection changes on **mousedown** event, so mouseclick event that happens after, makes <body> to focus.
        //putting the focus() call in settimeout makes it work at the right time (after mouseclick event happens)
        setTimeout(() => {
          dropdownFieldRef.current && dropdownFieldRef.current.focus();
        });
      },
      [dispatch, onChange, state.selectedItem]
    );

    const onConfirm = event => {
      event.preventDefault(); //don't scroll page (space) or submit form (enter)
      event.stopPropagation();

      if (isOpen) {
        doSelectionAndFocus(state.highlightedItem);

        setOpen(false);
      } else {
        setOpen(true);
      }
    };

    const onArrowDown = event => {
      event.preventDefault(); //do not scroll the page
      event.stopPropagation();

      if (isOpen) {
        dispatch({ type: "highlightNext" });
      } else {
        setOpen(true);
      }
    };

    const onArrowUp = event => {
      event.preventDefault(); //do not scroll the page
      event.stopPropagation();

      if (isOpen) {
        dispatch({ type: "highlightPrev" });
      } else {
        setOpen(true);
      }
    };

    const onEsc = event => {
      setOpen(false);
      //even though highlighted item is set on dropdown list open, we still need to revert aria-activedescendant attribute when list closes
      dispatch({
        type: "setHighlightedItem",
        highlightedItem: state.selectedItem
      });
    };

    //TODO: home/end/printable-characters handling is missing,
    //see e.g. https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html
    useKeyDown(dropdownFieldRef, {
      [KEY_CODES.ARROW_DOWN]: onArrowDown,
      [KEY_CODES.ARROW_UP]: onArrowUp,
      // if search is implemented, will cause problems with space input
      // https://github.com/onelogin/web-notes/pull/19
      [KEY_CODES.SPACE]: onConfirm,
      [KEY_CODES.ENTER]: onConfirm,
      [KEY_CODES.ESC]: onEsc
    });

    //this is called whenever options list (dis)appears
    //when present on screen, count the available height for the list so it can scroll if there's a lot of items in the list
    const itemsWrapRef = useCallback(
      dropdownListEl => {
        const dropdownWrapEl = dropdownWrapRef.current;

        if (dropdownListEl !== null && dropdownWrapEl !== null) {
          const boxRect = dropdownWrapEl.getBoundingClientRect();

          dropdownListEl.style.maxHeight = `${
            windowSize.height - boxRect.bottom - DROPDOWN_BORDER_WIDTH
          }px`;
        }
      },
      [windowSize]
    );

    const toggleOpen = () => {
      //when there's no given `value` passed to this component, then selectedItem is undefined, so select first item in the list of options
      //TODO: check this again, is listwithids[0] needed?
      dispatch({
        type: "setHighlightedItem",
        highlightedItem: state.selectedItem || state.listWithIds[0]
      });
      setOpen(!isOpen);
    };

    const onItemClick = doSelectionAndFocus;

    const onBlur = () => {
      setOpen(false);
    };

    useImperativeHandle(ref, () => ({
      focus: () => {
        dropdownFieldRef.current.focus();
      }
    }));

    useEffect(() => {
      if (state.listWithIds) {
        const selectedItem = state.listWithIds.find(
          item => item.value === value
        );
        if (selectedItem) dispatch({ type: "setSelectedItem", selectedItem });
      }
    }, [value, state.selectedItem.value, state.listWithIds]);

    return (
      <DropdownWrap
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        ref={dropdownWrapRef}
      >
        {triggerEl ? (
          React.cloneElement(triggerEl, {
            id,
            "aria-expanded": isOpen,
            "aria-haspopup": "listbox",
            "aria-label": ariaLabel,
            onMouseDown: toggleOpen,
            size,
            disabled,
            ref: dropdownFieldRef,
            onBlur,
            "aria-activedescendant": state.highlightedItem
              ? state.highlightedItem.id
              : "",
            "aria-owns": `${OWNED_LISTBOX_PREFIX}${id}`,
            value: state.selectedItem ? state.selectedItem.text : "",
            placeholder: placeholder
          })
        ) : (
          <DropdownField
            id={id}
            aria-expanded={isOpen}
            aria-haspopup="listbox"
            aria-label={ariaLabel}
            expanded={isOpen}
            onMouseDown={toggleOpen}
            size={size}
            disabled={disabled}
            invalid={invalid}
            aria-invalid={invalid}
            hovered={isHover}
            ref={dropdownFieldRef}
            onBlur={onBlur}
            aria-activedescendant={
              state.highlightedItem ? state.highlightedItem.id : ""
            }
            aria-owns={`${OWNED_LISTBOX_PREFIX}${id}`}
            value={state.selectedItem ? state.selectedItem.text : ""}
            placeholder={placeholder}
            readOnly
          />
        )}

        {isOpen && state.listWithIds && (
          <ItemsWrap
            disabled={disabled}
            hovered={isHover}
            ref={itemsWrapRef}
            role="listbox"
            aria-labelledby={id}
            id={`${OWNED_LISTBOX_PREFIX}${id}`}
            listMaxWidth={listMaxWidth}
            showListTopBorder={showListTopBorder}
          >
            {state.listWithIds.map(item => {
              const isSelected = state.highlightedItem
                ? item.id === state.highlightedItem.id
                : false;
              return (
                <DropdownItem
                  role="option"
                  onMouseDown={() => {
                    onItemClick(item);
                  }}
                  key={item.id}
                  id={item.id}
                  selected={isSelected}
                  aria-selected={isSelected}
                >
                  {item.text}
                </DropdownItem>
              );
            })}
            {state.listWithIds.length === 0 && (
              <EmptyDropdown>
                <i>{noItemsPlaceholder}</i>
              </EmptyDropdown>
            )}
          </ItemsWrap>
        )}
      </DropdownWrap>
    );
  }
);

Dropdown.defaultProps = {
  disabled: false,
  invalid: false,
  size: SIZES.MEDIUM,
  placeholder: "Select",
  noItemsPlaceholder: "No options",
  list: []
};

Dropdown.propTypes = {
  id: PropTypes.string,
  triggerEl: PropTypes.node, //string, element whatever can be passed here to act as a dropdown trigger; if not provided, than RC's Input will be used
  list: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string.isRequired,
      id: PropTypes.string //not needed, will be generated when not provided
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(Object.keys(SIZES).map(e => SIZES[e])), //used object.keys method to avoid polyfill object.values for IE11
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  noItemsPlaceholder: PropTypes.string,
  ariaLabel: PropTypes.string.isRequired, //required
  listMaxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), //limit the popup list to a defined width or make it auto-width; If not provided, initial value is used.
  showListTopBorder: PropTypes.bool //explicitly set the visibility of list top border. When not set there's no top border on the list
};

export default Dropdown;
