import React, { useState, useRef } from "react";
import { bool, string, func } from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import { ModalDialog } from "../../../index";
import Button from "../Button/Button";
import useKeyDown from "../../utils/useKeyDown";
import { ENTER, SPACE } from "../../../constants/keyCodes";

const StyledButton = styled(Button)`
  margin-right: 10px
`;

const Wrapper = styled.span`
  display: inline-block;
`;

/**
 * A dialog modal to confirm an action that wraps a trigger element
 * @param {Function} onConfirm - a function to run when the child element is triggered
 * @param {String} message - a message to display in the dialog modal
 * @param {Boolean} bubble - [optional] a boolean that describes if bubbling is allowed when the child element is triggered
 * @return {JSX.Element} a div containing a wrapper with the passed children and a modal that is toggled by interaction with the passed children
 * @example
 *   <Confirm
 *     message="Are you sure you want to delete this item?"
 *     onConfirm={event => alert("Ok, it's gone")}
 *     bubble
 *   >
 *     <button>Delete</button>
 *   </Confirm>
 * @type {{( props: { onConfirm: function, message: string, bubble?: boolean }): JSX.Element}}
 */
const Confirm = ({
  children,
  onConfirm,
  message,
  bubble
}) => {
  const [show, setShow] = useState(false);
  const wrapperEl = useRef();

  const onClick = event => {
    if (!bubble) {
      event.preventDefault();
      event.stopPropagation();
    }
    setShow(true);
  };

  const onModalButtonClick = (event, confirmed) => {
    event.stopPropagation();
    setShow(false);
    if (confirmed) onConfirm(event, true);
  };

  const Modal = () => (
    <ModalDialog data-testid="confirm-modal" onClose={() => setShow(false)} closeOnClick>
      <ModalDialog.Header><FormattedMessage defaultMessage="Confirm" /></ModalDialog.Header>
      <ModalDialog.Content>
        <p>{message}</p>
      </ModalDialog.Content>
      <ModalDialog.Actions>
        <span>
          <StyledButton
            onClick={event => onModalButtonClick(event, false)}
          >
            <FormattedMessage defaultMessage="Cancel" />
          </StyledButton>
          <Button
            look="primary"
            onClick={event => onModalButtonClick(event, true)}
          >
            <FormattedMessage defaultMessage="OK" />
          </Button>
        </span>
      </ModalDialog.Actions>
    </ModalDialog>
  );

  useKeyDown(wrapperEl, {
    [SPACE]: onClick,
    [ENTER]: onClick
  });

  return (
    <>
      <Wrapper
        onClick={onClick}
        ref={wrapperEl}
        role="button"
        tabIndex="-1"
      >
        {children}
      </Wrapper>
      {show && <Modal />}
    </>
  );
};

Confirm.propTypes = {
  message: string.isRequired,
  onConfirm: func.isRequired,
  bubble: bool
};

export default Confirm;
