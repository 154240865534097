import React from "react";
import styled from "styled-components";

import Loading from "../../../ui/Loading/Loading";

const Container = styled.div`
  position: relative;
  height: 275px;
`;

const LoginLoading = () => (
  <Container>
    <Loading />
  </Container>
);

LoginLoading.propTypes = {};

export default LoginLoading;
