import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import PropTypes from "prop-types";

import SidebarDropdownToggle from "./SidebarDropdownToggle";
import Dimmer from "../../../ui/ModalDialog/Dimmer";

import COLORS from "../../../../constants/colors";

const PADDING_TOP = 20;

const Overlay = styled.div`
  width: 100%;
  bottom: 0;
  position: absolute;
  top: ${props => props.top}px;
  text-align: left;
  overflow-y: auto;
  margin: 0;
  display: inline;
  height: auto;
`;

const DropdownWrap = styled.div`
  background: ${COLORS.WHITE};
`;

const DropdownItems = styled.div`
  background-color: ${COLORS.WHITE};
`;

const ActiveTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${COLORS.GRAY_COLORS.GRAY_25};
  border-bottom: solid 1px ${COLORS.GRAY_COLORS.GRAY_92};
  padding-left: 55px;
  padding-right: 1em;
  height: 70px;
`;

const Dropdown = ({ activeItemTitle = "", children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [top, setTop] = useState();
  const dropdownToggleEl = useRef(null);
  const dropdownWrapEl = useRef(null);
  const [shouldFocus, setShouldFocus] = useState(false);

  if (dropdownToggleEl.current) {
    dropdownToggleEl.current.focus();
  }

  const closeModal = e => {
    if (!dropdownWrapEl.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const onTogglerClick = () => {
    //when dropdown is going to open, count the top position
    //it can change in time, e.g. navBar menu will be open or password expiration bar is displayed etc
    if (!isOpen && dropdownToggleEl && dropdownToggleEl.current) {
      const size = ReactDOM.findDOMNode(
        dropdownToggleEl.current
      ).getBoundingClientRect();
      setTop(size.top - PADDING_TOP);
    }
    setIsOpen(prev => !prev);
  };

  const dropdownToggle = (
    <SidebarDropdownToggle
      onClick={onTogglerClick}
      isOpen={isOpen}
      ref={dropdownToggleEl}
      setShouldFocus={setShouldFocus}
      shouldFocus={shouldFocus}
    />
  );

  if (isOpen) {
    return (
      <>
        <Dimmer onClick={closeModal}>
          <Overlay top={top} data-testid="overlay">
            <DropdownWrap ref={dropdownWrapEl}>
              {dropdownToggle}
              {isOpen && <ActiveTitle>{activeItemTitle}</ActiveTitle>}
              <DropdownItems>{children}</DropdownItems>
            </DropdownWrap>
          </Overlay>
        </Dimmer>
      </>
    );
  } else {
    return dropdownToggle;
  }
};

Dropdown.propTypes = {
  activeItemTitle: PropTypes.node.isRequired
};

export default Dropdown;
