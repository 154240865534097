import EmailIcon from "./images/email.png";
import ProtectIcon from "./images/onelogin-protect-small.png";
import VipIcon from "./images/vip-access.png";
import YubiKeyIcon from "./images/yubikey.png";
import DuoIcon from "./images/duo-security.png";
import GoogleAuthIcon from "./images/google-authenticator.png";
import OneloginSmsIcon from "./images/onelogin-sms.png";
import SecureIdIcon from "./images/secure-id.png";
import SecurityQuestionsIcon from "./images/security-questions.png";
import TempOtpIcon from "./images/OneLogin-logo.svg";
import OneloginVoiceIcon from "./images/onelogin-voice.png";
import WebAuthnOtpIcon from "./images/webauthn.png";
import EmailOtpIcon from "./images/onelogin-email-otp.png";
import TidpIcon from "./images/tidp_icon.png";
import OathIcon from "./images/oath.png"; 

const MFA_TYPE_EMAIL = 0;
const MFA_TYPE_YUBIKEY = 1;
const MFA_TYPE_VIPACCESS = 2;
const MFA_TYPE_SECURID = 7;
const MFA_TYPE_ONELOGIN = 8;
const MFA_TYPE_AUTHENTICATOR = 10;
const MFA_TYPE_SMS = 11;
const MFA_TYPE_DUOSECURITY = 12;
const MFA_TYPE_SECURITYQUESTIONS = 13;
const MFA_TYPE_TEMP_OTP = 16;
const MFA_TYPE_VOICE = 17;
const MFA_TYPE_WEBAUTHN = 18;
const MFA_TYPE_EMAIL_OTP = 19;
const MFA_TYPE_TRUSTED_IDP = 20;
const MFA_TYPE_OATH = 22; 

const IconProvider = type => {
  switch (type) {
    case MFA_TYPE_EMAIL: {
      return EmailIcon;
    }
    case MFA_TYPE_YUBIKEY: {
      return YubiKeyIcon;
    }
    case MFA_TYPE_VIPACCESS: {
      return VipIcon;
    }
    case MFA_TYPE_SECURID: {
      return SecureIdIcon;
    }
    case MFA_TYPE_ONELOGIN: {
      return ProtectIcon;
    }
    case MFA_TYPE_AUTHENTICATOR: {
      return GoogleAuthIcon;
    }
    case MFA_TYPE_SMS: {
      return OneloginSmsIcon;
    }
    case MFA_TYPE_DUOSECURITY: {
      return DuoIcon;
    }
    case MFA_TYPE_SECURITYQUESTIONS: {
      return SecurityQuestionsIcon;
    }
    case MFA_TYPE_TEMP_OTP: {
      return TempOtpIcon;
    }
    case MFA_TYPE_VOICE: {
      return OneloginVoiceIcon;
    }
    case MFA_TYPE_WEBAUTHN: {
      return WebAuthnOtpIcon;
    }
    case MFA_TYPE_EMAIL_OTP: {
      return EmailOtpIcon;
    }
    case MFA_TYPE_TRUSTED_IDP: {
      return TidpIcon;
    }
    case MFA_TYPE_OATH: {
      return OathIcon; 
    }
    default:
      return ProtectIcon;
  }
};

export default IconProvider;
