import COLORS from "../../constants/colors";
import { lighten, darken, parseToRgb, rgba, hsl, parseToHsl } from "polished";

// use instead of getLuminance in polished
const getLuminance = color => {
  //rgba also translates named colors (like 'yellow') to rgba, so it can be used for lightening or darkening
  //but not non-senses (see specs); when user set such color, make it work even if ugly (try-catch)
  let sanitizedColor;
  try {
    sanitizedColor = rgba(color, 1);
  } catch (e) {
    sanitizedColor = COLORS.GRAY_COLORS.GRAY_18;
  }

  const rgb = parseToRgb(sanitizedColor);

  return (rgb.red * 212.6 + rgb.green * 715.2 + rgb.blue * 72.2) / 1000 / 255;
};

export const isLight = color => 0.5 <= getLuminance(color);

//count font color based on its luminance
export const readableColor = color => (isLight(color) ? "#000" : "#fff");

//make given color lighter or darker based on its luminance
export const darkenOrLighten = (color = COLORS.GRAY_COLORS.GRAY_18) => {
  //rgba also translates named colors (like 'yellow') to rgba, so it can be used for lightening or darkening
  //but not non-senses (see specs); when user set such color, make it work even if ugly (try-catch)
  let sanitizedColor;
  try {
    sanitizedColor = rgba(color, 1);
  } catch (e) {
    sanitizedColor = COLORS.GRAY_COLORS.GRAY_18;
  }

  return isLight(sanitizedColor)
    ? darken(0.1, sanitizedColor)
    : lighten(0.1, sanitizedColor);
};

export const getActiveColor = color => {
  const hslParsed = parseToHsl(color);

  return hsl(
    hslParsed.hue,
    hslParsed.saturation,
    Math.max(0, hslParsed.lightness - 0.08)
  );
};

export const getHoverColor = color => {
  const hslParsed = parseToHsl(color);

  return hsl(
    hslParsed.hue,
    Math.max(0, hslParsed.saturation - 0.08),
    Math.min(1, hslParsed.lightness + 0.08)
  );
};
