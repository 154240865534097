import { readableColor, darkenOrLighten, isLight } from "./colorUtils";

const getTheme = ({ accentColor, primaryColor }) => {
  // olThemeProps: PropTypes.shape({
  //   primaryColor: PropTypes.string.isRequired,
  //   accentColor: PropTypes.string.isRequired,
  //   logoUrl: PropTypes.string
  // });

  const dropdownBgColor = darkenOrLighten(primaryColor);

  return {
    /** color provided by OL branding: button color */
    accentColor,

    /** color provided by OL branding: navbar background color */
    primaryColor,

    /** text color of menu items in normal state */
    textColor: readableColor(primaryColor),

    /** opened dropdown background color */
    dropdownBgColor,

    /** text color of menu items in opened state */
    dropdownTextColor: readableColor(dropdownBgColor),

    /** mobile view opened dropdown has separators around each item of this color */
    dropdownMobileItemSeparator: darkenOrLighten(dropdownBgColor),

    /** used for rendering proper icons within navbar (chevron-right, chevron-down) */
    primaryColorIsLight: isLight(primaryColor)
  };
};

export default getTheme;
