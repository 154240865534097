import React, { forwardRef, useEffect } from "react";
import { useIntl } from "react-intl";

import styled from "styled-components";
import PropTypes from "prop-types";

import Icon from "../../../ui/Icon/Icon";
import useKeyDown from "../../../utils/useKeyDown";

import KEY_CODES from "../../../../constants/keyCodes";

const ToggleContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 8px;
  left: 16px;
  z-index: 5;
  font-size: 22px;
  box-sizing: border-box;
  margin-top: 12px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

const StyledIcon = styled(Icon)`
  margin: auto;
`;

const DropdownToggle = forwardRef(
  (
    { isOpen, onClick, setShouldFocus = () => {}, shouldFocus = false },
    ref
  ) => {
    const intl = useIntl();

    useEffect(() => {
      if (ref.current && shouldFocus) {
        ref.current.focus();
      }
    }, [ref, shouldFocus]);

    const onConfirm = event => {
      event.preventDefault(); //don't scroll page (space) or submit form (enter)
      event.stopPropagation();
      setShouldFocus(true);
      onClick();
    };

    useKeyDown(ref, {
      // if search is implemented, will cause problems with space input
      // https://github.com/onelogin/web-notes/pull/19
      [KEY_CODES.SPACE]: onConfirm,
      [KEY_CODES.ENTER]: onConfirm
    });

    return (
      <ToggleContent
        tabIndex={0}
        ref={ref}
        onClick={() => {
          onClick();
          setShouldFocus(false);
        }}
        isOpen={isOpen}
        role="button"
      >
        <StyledIcon
          size={"16px"}
          icon={isOpen ? "Chevron_up-16" : "Chevron_down-16"}
          alt={intl.formatMessage({
            defaultMessage: "Open menu"
          })}
        />
      </ToggleContent>
    );
  }
);

DropdownToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setShouldFocus: PropTypes.func,
  shouldFocus: PropTypes.bool
};

export default DropdownToggle;
