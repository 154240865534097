import csCZ from "./cs-CZ.json";
import da from "./da.json";
import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import fi from "./Finland.json";
import fr from "./fr.json";
import huHU from "./hu-HU.json";
import it from "./it.json";
import ja from "./ja.json";
import { keys } from "ramda";
import ko from "./ko.json";
import msMY from "./ms-MY.json";
import nl from "./nl.json";
import no from "./no-Norway.json";
import plPL from "./pl-PL.json";
import pt from "./pt.json";
import ruRU from "./ru-RU.json";
import skSK from "./sk-SK.json";
import sv from "./Sweeden.json";
import thTH from "./th-TH.json";
import trTR from "./tr-TR.json";
import viVN from "./vi-VN.json";
import zh from "./zh.json";
import zhTW from "./zh-TW.json";
import lv from "./lv.json";

// map of all translation we support by language tag
const LANGUAGES_MAP = {
  "cs-CZ": csCZ,
  da: da,
  de: de,
  en: en,
  es: es,
  fi: fi,
  fr: fr,
  "hu-HU": huHU,
  it: it,
  ja: ja,
  ko: ko,
  "ms-MY": msMY,
  nl: nl,
  nb: no,
  "pl-PL": plPL,
  pt: pt,
  "ru-RU": ruRU,
  sk: skSK,
  sv: sv,
  th: thTH,
  "tr-TR": trTR,
  "vi-VN": viVN,
  zh: zh,
  "zh-TW": zhTW,
  lv: lv
};

export const removeRegionTag = lang => lang && lang.split("-")[0];

const PRIMARY_LANGUAGES_TAGS = keys(LANGUAGES_MAP).map(removeRegionTag);

// Terms: language tag, region tag, primary language tag taken from https://tools.ietf.org/html/rfc5646
/**
 * Resolve best language to use based on user and account settings
 *
 * @param {string} userLanguage language set by user/admin or detected language in browser
 * @param {string} defaultLanguage default language (for account)
 * @param {string[]} enabledLanguages languages enabled by admin (for account). Expecting list of primary language tags (tags without region tag, example: no "zh-TW", just "zh")
 */
export const resolveBestLanguage = ({
  userLanguage = "",
  defaultLanguage = "en",
  enabledLanguages = PRIMARY_LANGUAGES_TAGS
}) => {
  const primaryTag = removeRegionTag(userLanguage);
  if (enabledLanguages.includes(primaryTag)) {
    if (LANGUAGES_MAP[userLanguage]) {
      return {
        languageTag: userLanguage,
        primaryLanguageTag: primaryTag
      };
    } else if (LANGUAGES_MAP[primaryTag]) {
      return {
        languageTag: primaryTag,
        primaryLanguageTag: primaryTag
      };
    }
    // else could happen only if LANGUAGES_MAP is not in sync with list of languages in monorail
  }

  return {
    languageTag: defaultLanguage,
    primaryLanguageTag: removeRegionTag(defaultLanguage)
  };
};

export default language => LANGUAGES_MAP[language];
