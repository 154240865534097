import React from "react";
import PropTypes from "prop-types";
import { keys } from "ramda";

import ScrollableContent from "../../../../ui/ScrollableContent/ScrollableContent";
import ListItem from "../../../../ui/ListItem/ListItem";

const SelectQuestion = ({ onSelect, questions }) => (
  <ScrollableContent data-testid="select-question-screen">
    {keys(questions).map(key => (
      <ListItem key={key} text={questions[key]} onClick={() => onSelect(key)} />
    ))}
  </ScrollableContent>
);

SelectQuestion.displayName = "SelectQuestion";

SelectQuestion.propTypes = {
  questions: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default SelectQuestion;
