import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import COLORS from "../../../../constants/colors";
import breakpoints from "../../../../constants/breakpoints";
import SidebarDropdown from "./SidebarDropdown";

const DropdownControl = styled.div`
  font-size: 16px;
  @media screen and (min-width: ${breakpoints.PORTAL_MIDDLE}) {
    display: none;
  }
`;

const SideMenuWrapper = styled.div`
  border-right: 1px solid ${COLORS.GRAY_COLORS.GRAY_92};
  @media screen and (max-width: ${breakpoints.PORTAL_MIDDLE}) {
    display: none;
    overflow-y: scroll;
  }
`;

const SideMenu = styled.div`
  padding-top: 6px;
  font-size: 14px;
`;

const Menu = ({ children, activeItemTitle }) => {
  return (
    <>
      <DropdownControl>
        <SidebarDropdown activeItemTitle={activeItemTitle}>
          {children}
        </SidebarDropdown>
      </DropdownControl>
      <SideMenuWrapper>
        <SideMenu>{children}</SideMenu>
      </SideMenuWrapper>
    </>
  );
};

Menu.propTypes = {
  activeItemTitle: PropTypes.node.isRequired
};

export default Menu;
