import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { useProjectRoot } from "../../../contexts/projectRootContext";

export default function RCLink({ href, children, ...props }) {
  const projectRoot = useProjectRoot();

  const sanitizedProjectRoot = `/${(projectRoot || "").replace(/^\/+/, "")}`;
  const isInternal = href.startsWith(sanitizedProjectRoot);

  return isInternal ? (
    <Link to={href} {...props}>
      {children}
    </Link>
  ) : (
    <a href={href} {...props}>
      {children}
    </a>
  );
}

RCLink.propTypes = {
  /** href can be either absolute URL or pathname for internal links */
  href: PropTypes.string.isRequired
};
