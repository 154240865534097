import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const LEFT = "left";
const BOTTOM = "bottom";
const BOTTOMLEFT = "bottom-left";

const positions = {
  [LEFT]: {
    top: 0,
    right: "100%"
  },
  [BOTTOM]: {
    top: "100%",
    left: 0
  },
  [BOTTOMLEFT]: {
    top: "100%"
  }
};

const getTop = menuPosition => positions[menuPosition].top;
const getRight = menuPosition => positions[menuPosition].right;
const getLeft = menuPosition => positions[menuPosition].left;

const Wrap = styled.div`
  position: relative;
  height: 100%;
`;

const MenuWrap = styled.div`
  border: 1px solid #d1d2d4;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 6px 0;
  display: inline-block;
  min-width: 190px;
  background-color: #ffffff;
  z-index: 10;
  position: absolute;
  top: ${props => getTop(props.position)};
  right: ${props => getRight(props.position)};
  left: ${props => getLeft(props.position)};
`;

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  onTogglerClick = event => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
    this.setListeners(!isOpen);
  };

  setListeners = isOpen => {
    if (isOpen) {
      this.registerListeners();
    } else {
      this.unregisterListeners();
    }
  };

  registerListeners = () => {
    window.addEventListener("mousedown", this.onMouseDown, false);
    window.addEventListener("click", this.onMouseClick, false);
  };

  unregisterListeners = () => {
    window.removeEventListener("mousedown", this.onMouseDown);
    window.removeEventListener("click", this.onMouseClick);
  };

  onMouseDown = event => {
    if (!this.wrap.contains(event.target) && this.state.isOpen) {
      this.setState({ isOpen: false });
      this.setListeners(false);
    }
  };

  onMouseClick = event => {
    if (
      this.menuWrap &&
      this.menuWrap.contains(event.target) &&
      this.state.isOpen
    ) {
      this.setState({ isOpen: false });
      this.setListeners(false);
    }
  };

  setMenuWrapPosition = () => {
    if (this.toggleEl) {
      switch (this.props.position) {
        case BOTTOMLEFT:
          const toggleRect = this.toggleEl.getBoundingClientRect();
          const menuWrapRect = this.menuWrap.getBoundingClientRect();
          this.menuWrap.style.left =
            toggleRect.width - menuWrapRect.width + "px";
          break;
        default:
        // no-op
      }
    }
  };

  render() {
    const { toggle, position, children } = this.props;

    const toggleEl = React.cloneElement(toggle, {
      ref: tglEl => {
        if (tglEl) {
          this.toggleEl = tglEl;
        }
      },
      onClick: this.onTogglerClick
    });

    return (
      <Wrap
        ref={wrap => {
          if (wrap) {
            this.wrap = wrap;
            this.togglerWidth = wrap.offsetWidth;
          }
        }}
      >
        {toggleEl}
        {this.state.isOpen ? (
          <MenuWrap
            ref={menuWrap => {
              if (menuWrap) {
                this.menuWrap = menuWrap;
              }

              this.setMenuWrapPosition();
            }}
            togglerWidth={this.togglerWidth}
            position={position}
          >
            {children}
          </MenuWrap>
        ) : null}
      </Wrap>
    );
  }

  componentWillUnmount() {
    this.unregisterListeners();
  }
}

Menu.defaultProps = {
  position: BOTTOM
};

Menu.propTypes = {
  toggle: PropTypes.element,
  position: PropTypes.oneOf([LEFT, BOTTOM, BOTTOMLEFT])
};

export default Menu;
