import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";

import Button from "../Button/Button";
import Input from "../FormField/Input";
import SIZES from "../../../constants/sizes";
import KEY_CODES from "../../../constants/keyCodes";
import { getHeight as getInputHeight } from "../../utils/getSizingDefinitions";

import clearSearchIcon from "./clear-search-thin.svg";
import searchIcon from "./magnifying-glass.svg";

const searchIconSize = 24;
const iconsMargin = 8;
const clearIconSize = 12;
const clearIconInteractiveSize = 38;
const heightMap = {
  [SIZES.MEDIUM]: 38,
  [SIZES.LARGE]: 48,
  [SIZES.BIG]: 48
};

const messages = defineMessages({
  searchIcon: {
    defaultMessage: "search icon"
  },
  clearIcon: {
    defaultMessage: "clear icon"
  }
});

const InputOverrided = styled(Input)`
  padding-left: ${iconsMargin * 2 + searchIconSize}px;
  padding-right: ${iconsMargin + clearIconInteractiveSize}px;
  border-top-right-radius: ${props => (props.showButton ? 0 : "inherit")};
  border-bottom-right-radius: ${props => (props.showButton ? 0 : "inherit")};
`;

const SearchContainer = styled.div`
  display: flex;
`;

const InputField = styled.div`
  position: relative;
  flex-grow: 1;
`;

const ClearIcon = styled.img`
  position: absolute;
  box-sizing: content-box;
  right: 0;
  top: ${props => (props.inputHeight - clearIconInteractiveSize) / 2}px;
  width: ${clearIconSize}px;
  height: ${clearIconSize}px;
  padding: ${(clearIconInteractiveSize - clearIconSize) / 2}px;
  cursor: pointer;
`;

const SearchIcon = styled.img`
  position: absolute;
  left: ${iconsMargin}px;
  top: ${props => (props.inputHeight - searchIconSize) / 2}px;
  width: ${searchIconSize}px;
  height: ${searchIconSize}px;
`;

const ButtonOverrided = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin: 0;
`;

const SearchField = ({
  inputRef,
  value,
  onRemove,
  onSearch = () => null,
  showButton = false,
  onKeyDown,
  buttonLabel = "Search",
  size = SIZES.MEDIUM,
  ...rest
}) => {
  const intl = useIntl();

  const defaultOnKeyDown = event => {
    if (event.keyCode === KEY_CODES.ENTER) {
      onSearch(value);
    }
    if (event.keyCode === KEY_CODES.ESC) {
      onRemove(event);
    }
  };

  return (
    <SearchContainer>
      <InputField>
        {value.length > 0 && (
          <ClearIcon
            src={clearSearchIcon}
            onClick={onRemove}
            inputHeight={getInputHeight(size, heightMap)}
            showButton={showButton}
            alt={intl.formatMessage(messages.clearIcon)}
          />
        )}
        <SearchIcon
          src={searchIcon}
          inputHeight={getInputHeight(size, heightMap)}
          alt={intl.formatMessage(messages.searchIcon)}
        />
        <InputOverrided
          {...rest}
          value={value}
          size={size}
          ref={inputRef}
          onKeyDown={onKeyDown || defaultOnKeyDown}
        />
      </InputField>
      {showButton && (
        <ButtonOverrided look="regular" onClick={onSearch} size={size}>
          {buttonLabel}
        </ButtonOverrided>
      )}
    </SearchContainer>
  );
};

SearchField.propTypes = {
  value: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  showButton: PropTypes.bool,
  buttonLabel: PropTypes.string,
  size: PropTypes.oneOf([SIZES.MEDIUM, SIZES.LARGE, SIZES.BIG]),
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element)
    })
  ])
};

export default SearchField;
