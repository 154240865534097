import React, { Component } from "react";
import PropTypes from "prop-types";

class SingleValueForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      invalid: false
    };
  }

  onChange = value => {
    this.setState({
      value,
      invalid: false
    });
  };

  onSubmit = event => {
    event.preventDefault();
    let { value } = this.state;
    const { validateFn } = this.props;

    if (this.props.trim === true) {
      value = value.trim();
    }

    const valueIsValid = (value) => {
      if (validateFn && typeof validateFn === "function") {
        return validateFn(value);
      } else if (value.length > 0) {
        return true;
      } else
        return false;
    }

    if (valueIsValid(value)) {
      this.props.onSubmit(value);
    } else {
      this.setState({ invalid: true });
    }
  };

  render = () => (
    <form onSubmit={this.onSubmit}>
      {this.props.render({ ...this.state, onChange: this.onChange })}
    </form>
  );
}

SingleValueForm.defaultProps = {
  value: ""
};

SingleValueForm.displayName = "SingleValueForm";

SingleValueForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  validateFn: PropTypes.func,
  trim: PropTypes.bool,
  value: PropTypes.string
};

export default SingleValueForm;
