import React from "react";
import PropTypes from "prop-types";

import FactorListItem from "./FactorListItem";
import getIcon from "./iconProvider/IconProvider";

const FactorList = ({ factors, onSelect }) => (
  <div data-testid="factor-list">
    {factors.map((factor,index) => (
      <FactorListItem
        key={factor.id}
        icon={factor.customIconUrl || getIcon(factor.typeId)}
        text={factor.name}
        onClick={() => onSelect(factor.id)}
        isLoginFactor={factor.isLoginFactor}
        isPasswordResetFactor={factor.isPasswordResetFactor}
        isAppFactor={factor.isAppFactor}
        isLastItem={index === factors.length-1}
      />
    ))}
  </div>
);

FactorList.displayName = "FactorList";

FactorList.propTypes = {
  factors: PropTypes.arrayOf(
    PropTypes.shape({
      /** Text representation of link */
      name: PropTypes.string.isRequired,
      /** MFA id of factor */
      id: PropTypes.number.isRequired,
      /** MFA type of factor */
      typeId: PropTypes.number.isRequired,
      /** Custom icon set for factor instance */
      customIconUrl: PropTypes.string,
      /** if factor is used for Login */
      isLoginFactor: PropTypes.bool,
      /** if factor is used for Password Reset */
      isPasswordResetFactor: PropTypes.bool,
      /** if factor is used for App */
      isAppFactor: PropTypes.bool
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired
};

export default FactorList;
