import React from "react";
import PropTypes from "prop-types";

import Link from "../Link/Link";

export default function MenuLink({ href, children, ...props }) {
  const attributes = {
    role: "menuitem",
    tabIndex: 0
  };

  if (href === undefined) {
    return (
      <button {...props} {...attributes}>
        {children}
      </button>
    );
  }

  return (
    <Link href={href} {...props} {...attributes}>
      {children}
    </Link>
  );
}

MenuLink.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func
};
