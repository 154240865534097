/**
 *  Font family
 */

const FONT_FAMILY = {
  DEFAULT: 'Roboto, "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif',
  MONOSPACE: '"Roboto Mono", monospace'
};

/* ----------------------------------------------------- */

/**
 *  Group all settings and export as single constant
 */

const FONTS = {
  FONT_FAMILY: FONT_FAMILY
};

export default FONTS;
