import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";

import AppleBadgeIcon from "../../../../../svg/apple-download-badge.svg";
import Bullets from "../../../../ui/Bullets/Bullets";
import Button from "../../../../ui/Button/Button";
import Content from "../../../../ui/Content/Content";
import ContentBlock from "../../../../ui/ContentBlock/ContentBlock";

const messages = defineMessages({
  linkToGooglePlay: {
    defaultMessage: "Link to Google Play"
  },
  linkToAppStore: {
    defaultMessage: "Link to App Store"
  },
  getOnGooglePlay: {
    defaultMessage: "Get it on Google Play"
  },
  downloadOnAppStore: {
    defaultMessage: "Download on the app store"
  }
});

const ACTIVATE_ACTION_TEXT = "Activate";

const GoogleBadge = styled.img`
  width: 160px;
  margin: -10px;
  height: 67px;
`;

const AppleBadge = styled.img`
  width: 140px;
`;

const BadgeWrapper = styled.div`
  margin-top: 20px;
`;

// https://play.google.com/intl/en_us/badges/
// google badge is not translated by intention
const GOOGLE_BADGE = intl => (
  <a
    href="https://play.google.com/store/apps/details?id=com.onelogin.protect"
    target="_blank"
    alt={intl.formatMessage(messages.linkToGooglePlay)}
    rel="noopener noreferrer"
  >
    <GoogleBadge
      alt={intl.formatMessage(messages.getOnGooglePlay)}
      src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
    />
  </a>
);

// https://developer.apple.com/app-store/marketing/guidelines/#downloadOnAppstore
// apple bade is not translated by intention (see 1.2 Localized badges ^)
const APPLE_BADGE = intl => (
  <a
    href="https://itunes.apple.com/us/app/onelogin-protect/id509252983"
    target="_blank"
    alt={intl.formatMessage(messages.linkToAppStore)}
    rel="noopener noreferrer"
  >
    <AppleBadge
      alt={intl.formatMessage(messages.downloadOnAppStore)}
      src={AppleBadgeIcon}
    />
  </a>
);

const OTHER_BADGE = intl => (
  <div>
    <a
      href="https://itunes.apple.com/us/app/onelogin-protect/id509252983"
      target="_blank"
      alt={intl.formatMessage(messages.linkToAppStore)}
      rel="noopener noreferrer"
    >
      <AppleBadge
        alt={intl.formatMessage(messages.downloadOnAppStore)}
        src={AppleBadgeIcon}
      />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=com.onelogin.protect"
      target="_blank"
      alt={intl.formatMessage(messages.linkToGooglePlay)}
      rel="noopener noreferrer"
    >
      <GoogleBadge
        alt={intl.formatMessage(messages.getOnGooglePlay)}
        src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
      />
    </a>
  </div>
);

const InstallProtect = ({ bowser, onContinue, onUseMagicLink, intl }) => {
  const badge = bowser => {
    if (bowser.android) {
      return GOOGLE_BADGE(intl);
    } else if (bowser.ios) {
      return APPLE_BADGE(intl);
    } else {
      return OTHER_BADGE(intl);
    }
  };

  return (
    <Content data-testid="install-protect-screen">
      <ContentBlock>
        <Bullets>
          <div>
            <FormattedMessage defaultMessage="Download and install OneLogin Protect on your device" />
            <BadgeWrapper>{badge(bowser)}</BadgeWrapper>
          </div>
          <div>
            <FormattedMessage
              defaultMessage="When the app is installed, come back to this screen and click {action}"
              values={{
                action: (
                  <b>
                    <FormattedMessage defaultMessage={ACTIVATE_ACTION_TEXT} />
                  </b>
                )
              }}
            />
          </div>
        </Bullets>
        <ContentBlock>
          <Button
            size="large"
            look="primary"
            onClick={onContinue}
            data-testid="activate-button"
          >
            <FormattedMessage defaultMessage={ACTIVATE_ACTION_TEXT} />
          </Button>

          {onUseMagicLink && (
            <Button size="large" onClick={onUseMagicLink}>
              <FormattedMessage defaultMessage="Magic Link" />
            </Button>
          )}
        </ContentBlock>
      </ContentBlock>
    </Content>
  );
};

InstallProtect.propTypes = {
  bowser: PropTypes.object,
  onContinue: PropTypes.func,
  onUseMagicLink: PropTypes.func
};

export default injectIntl(InstallProtect);
