import styled from "styled-components";

const ScrollableContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 400px;
`;

ScrollableContent.displayName = "ScrollableContent";

export default ScrollableContent;
