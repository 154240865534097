import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import Content from "../../../../ui/Content/Content";
import ContentBlock from "../../../../ui/ContentBlock/ContentBlock";
import Icon from "../../../../ui/Icon/Icon";

const ICON_IMAGE_SIZE = "32px";

const TextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const IconPadding = styled.div`
  margin-right: 15px;
`;
export default function UnsupportedBrowser() {
  return (
    <Content data-testid="unsupported-webauthn-screen">
      <ContentBlock>
        <TextWrapper>
          <IconPadding>
            <Icon icon="Warning-24" size={ICON_IMAGE_SIZE} />
          </IconPadding>
          <FormattedMessage defaultMessage="Your browser doesn't support WebAuthn" />
        </TextWrapper>
      </ContentBlock>
    </Content>
  );
}
