import styled from "styled-components";
import COLORS from "../../../../constants/colors";

const SectionHeader = styled.div`
  font-size: 18px;
  color: ${COLORS.GRAY_COLORS.GRAY_25};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: 0.2px;
  margin-bottom: 12px;
`;

export default SectionHeader;
