import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import PropTypes from "prop-types";
import MenuLink from "./MenuLink";
import { DropdownItemStyles } from "./MenuItemStyles";

import { NAVBAR_HEIGHT } from "./NavbarConstants";

const DropdownMenu = styled.ul`
  position: absolute;
  top: ${NAVBAR_HEIGHT}px;
  left: ${({ isRight }) => (isRight ? "auto" : 0)};
  right: ${({ isRight }) => (isRight ? 0 : "auto")};
  min-width: 175px;
  padding: 6px 0;
  margin: 0;
  border: 0;
  box-shadow: 0 4px 12px 0 rgba(45, 46, 46, 0.08),
    0 1px 4px 0 rgba(45, 46, 46, 0.2);
  border-radius: 0 0 2px 2px;
  list-style-type: none;
  z-index: 1;
  color: ${props => props.theme.dropdownTextColor};
  background-color: ${props => props.theme.dropdownBgColor};
  display: ${props => (props.expanded ? "block" : "none")};
`;

const DropdownMenuMobile = styled(DropdownMenu)`
  position: relative;
  top: auto;
  right: auto;
  left: auto;
  background-color: ${props => props.theme.dropdownBgColor};
  width: 100%;
`;

const Li = styled.li`
  line-height: 1.75rem;
  white-space: nowrap;
  background-color: inherit;
  list-style-type: none;
  position: relative;
  text-align: left;

  &:focus-within,
  &:hover {
    background-color: ${props => props.theme.primaryColor};
    color: ${props => props.theme.textColor};
  }

  > a {
    ${DropdownItemStyles}
  }

  > button {
    ${DropdownItemStyles}
  }
`;

const getLinks = ({ data, isMobile, theme }) =>
  data.map(({ title, ...link }, index) => {
    return (
      <Li theme={theme} isMobile={isMobile} key={index}>
        <MenuLink {...link}>{title}</MenuLink>
      </Li>
    );
  });

export default function Dropdown({ isMobile, expanded, data, isRight = false }) {
  const theme = useContext(ThemeContext);

  if (isMobile) {
    return (
      <DropdownMenuMobile theme={theme} expanded={expanded} role="menu">
        {getLinks({ data, isMobile, theme })}
      </DropdownMenuMobile>
    );
  }

  return (
    <DropdownMenu
      theme={theme}
      expanded={expanded}
      role="menu"
      isRight={isRight}
    >
      {getLinks({ data, isMobile, theme })}
    </DropdownMenu>
  );
}

Dropdown.propTypes = {
  isMobile: PropTypes.bool,
  expanded: PropTypes.bool,
  isRight: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      onClick: PropTypes.func
    })
  )
};
