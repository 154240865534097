import React, { useState, useEffect } from "react";
import styled from "styled-components";

import COLORS from "../../../../constants/colors";
import breakpoints from "../../../../constants/breakpoints";

const HeadingWrap = styled.header`
  position: fixed;
  font-size: 21px;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: left;
  align-items: center;
  line-height: 1;
  background-color: ${COLORS.WHITE};
  height: 70px;
  padding: 0 32px;
  border-bottom: solid 1px ${COLORS.GRAY_COLORS.GRAY_92};
  margin: 0;
  box-shadow: ${({ scroll }) =>
    scroll ? `0 1px 6px 0 rgba(0, 0, 0, 0.1)` : "none"};

  @media only screen and (max-width: ${breakpoints.PORTAL_MIDDLE}) {
    padding: 0 1em;
    padding-left: 55px;
  }
`;

const Heading = ({ children, ...props }) => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const callback = () => setScroll(window.scrollY > 20);
    document.addEventListener("scroll", callback);
    return () => document.removeEventListener("scroll", callback);
  }, []);

  return (
    <HeadingWrap {...props} scroll={scroll}>
      {children}
    </HeadingWrap>
  );
};

export default Heading;
