import React, { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { string, func, number, oneOfType, bool } from "prop-types";

import SearchField from "../SearchField/SearchField";

const Container = styled.div`
  display: inline-block;
`;

/**
 * A wrapper for SearchField that handles form changes, form submit, and form clearing the output.
 * @param {Number|String} id - a number or string representing the id of the search field component
 * @param {Function} onSearch - a function that runs on keypress enter or click Search button
 * @param {String} placeholder - [default = "Search"] a string set to the placeholder of the search field component
 * @param {String} value - [optional] a string used as the initial value and the value of the search field component
 * @param {Function} handleChange - [optional] a function that runs when the value of the search field component is changed
 * @param {Boolean} showButton - [default = true] a boolean that controls if the search button is rendered or not
 * @example
 *   <SearchBox
 *     id="search-box-1234"
 *     onSearch={filterResults}
 *     placeholder="Search"
 *     value={value}
 *     handleChange={setValue}
 *     showButton={true}
 *   />
 * @type {{( props: { id: (number|string), onSearch: function, placeholder?: string, handleChange?: function, value?: string, showButton?: boolean }): JSX.Element}}
 */
const SearchBox = forwardRef(({
  id,
  onSearch,
  placeholder,
  value = "",
  handleChange,
  showButton = true,
  className,
  ...props
}, ref) => {
  const [searchValue, setSearchValue] = useState(value);
  const intl = useIntl();

  const onSearchChange = value => {
    setSearchValue(value);
    handleChange && handleChange(value);
  };
  const onSearchClick = () => onSearch(searchValue);
  const onSearchRemove = event => {
    event.stopPropagation();
    onSearchChange("");
    onSearch("");
  };

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  return (
    <Container className={className}>
      <SearchField
        id={id}
        onSearch={onSearchClick}
        placeholder={placeholder || intl.formatMessage({ defaultMessage: "Search" })}
        value={searchValue}
        onChange={event => onSearchChange(event.target.value)}
        showButton={showButton}
        onRemove={onSearchRemove}
        inputRef={ref}
        {...props}
      />
    </Container>
  );
});

SearchBox.propTypes = {
  id: oneOfType([number, string]).isRequired,
  onSearch: func.isRequired,
  placeholder: string,
  value: string,
  handleChange: func, 
  showButton: bool
};

export default SearchBox;
