import React, { useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import COLORS from "../../../constants/colors";
import FONTS from "../../../constants/fonts";
import { svgList } from "../Icon/svgList";
import useKeyDown from "../../utils/useKeyDown";
import KEY_CODES from "../../../constants/keyCodes";

const ToggleButtonWrapper = styled.div`
  position: relative;
  margin: 4px 0;
  font-family: ${FONTS.FONT_FAMILY.DEFAULT};
  align-items: center;
  height: 26px;
  display: flex;
`;

const ToggleButtonSelect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 52px;
  height: 26px;
  border-radius: 15px;
  background: ${props =>
    props.disabled
      ? `${COLORS.GRAY_COLORS.GRAY_78}`
      : `${COLORS.STATUS_COLORS.ERROR}`};
  background-image: url(${svgList["Close-16-thick"]});
  background-repeat: no-repeat;
  background-position: 75% 50%;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 3px;
    background: ${props =>
      props.disabled ? `${COLORS.GRAY_COLORS.GRAY_90}` : `${COLORS.WHITE}`};
    box-shadow: ${props =>
      props.disabled ? "none" : "0 1px 2px 0 rgba(0, 0, 0, 0.15)"};
    transition: 0.2s;
  }
`;

const ToggleButtonInput = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 52px;
  height: 26px;
  margin: 0px;

  &:checked + ${ToggleButtonSelect} {
    background: ${props =>
      props.disabled
        ? `${COLORS.GRAY_COLORS.GRAY_78}`
        : `${COLORS.STATUS_COLORS.SUCCESS}`};
    background-image: url(${svgList["Checkmark-checkbox"]});
    background-repeat: no-repeat;
    background-position: 8px 50%;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin-left: 29px;
      transition: 0.2s;
    }
  }
`;

const ToggleButtonLabel = styled.label`
  font-size: 14px;
  display: inline-block;
  position: relative;
  line-height: 18px;
  color: ${props =>
    props.checked
      ? `${COLORS.GRAY_COLORS.GRAY_25}`
      : `${COLORS.GRAY_COLORS.GRAY_35}`};
  opacity: ${props => (props.disabled ? 0.3 : 1)};
  margin-left: 8px;
}`;

const ToggleButton = ({ id, value, onChange, checked, disabled, label }) => {
  const toggleButtonRef = useRef();

  const handleChange = () => {
    !disabled && onChange(id);
  };

  const onConfirm = event => {
    event.preventDefault(); //do not scroll the page
    event.stopPropagation();
    handleChange();
  };

  useKeyDown(toggleButtonRef, {
    [KEY_CODES.SPACE]: onConfirm
  });

  return (
    <>
      <ToggleButtonWrapper ref={toggleButtonRef}>
        <ToggleButtonInput
          id={id}
          value={value}
          onChange={handleChange}
          checked={checked}
          disabled={disabled}
          type="checkbox"
          data-testid={"toggle-button"}
          tabIndex="-1"
        />
        <ToggleButtonSelect disabled={disabled} tabIndex="0" />
        <ToggleButtonLabel htmlFor={id}>{label}</ToggleButtonLabel>
      </ToggleButtonWrapper>
    </>
  );
};

ToggleButton.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string
};

export default ToggleButton;
