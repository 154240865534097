import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import FONTS from "../../../constants/fonts";
import COLORS from "../../../constants/colors";
import SIZES from "../../../constants/sizes";
import {
  readableColor,
  getHoverColor,
  getActiveColor
} from "../../utils/colorUtils";

const PRIMARY_LOOK = "primary";
const REGULAR_LOOK = "regular";
const LINK_LOOK = "link";
const OUTLINE_LOOK = "outline";

const StyledButton = styled.button`
  ${props => getSizingDefinitions(props.size)};
  font-weight: 400;
  font-family: ${FONTS.FONT_FAMILY.DEFAULT};
  border-radius: 2px;
  letter-spacing: 0.4px;
  box-shadow: none;
  text-shadow: none;
  text-transform: capitalize;
  border: none;
  cursor: pointer;

  ${props => {
    const colors = resolveColorSchema(props.look, props.theme.accentColor);
    return css`
      color: ${colors.normal.color};
      background-color: ${colors.normal.backgroundColor};
      border: ${colors.normal.border};

      &:hover {
        color: ${colors.hover.color};
        background-color: ${colors.hover.backgroundColor};
        border: ${colors.hover.border};
      }

      &:active {
        color: ${colors.active.color};
        background-color: ${colors.active.backgroundColor};
        border: ${colors.active.border};
      }

      &:disabled,
      &[disabled] {
        opacity: 0.3;

        &:hover {
          color: ${colors.normal.color};
          background-color: ${colors.normal.backgroundColor};
          border: ${colors.normal.border};
        }
      }
    `;
  }};
`;

const getSizingDefinitions = size => {
  if (size === SIZES.LARGE) {
    return css`
      font-size: 16px;
      line-height: 20px;
      padding: 0 20px;
      height: 48px;
      min-width: 112px;
    `;
  } else {
    // medium (default)
    return css`
      font-size: 14px;
      line-height: 16px;
      padding: 0 16px;
      height: 38px;
      min-width: 88px;
    `;
  }
};

const resolveColorSchema = (
  look,
  accentColor = COLORS.ACTION_COLORS.BLUE_MAIN
) => {
  switch (look) {
    case PRIMARY_LOOK: {
      const textColor = readableColor(accentColor);
      return {
        normal: {
          backgroundColor: accentColor,
          color: textColor
        },
        hover: {
          backgroundColor: getHoverColor(accentColor),
          color: textColor
        },
        active: {
          backgroundColor: getActiveColor(accentColor),
          color: textColor
        }
      };
    }
    case OUTLINE_LOOK: {
      return {
        normal: {
          backgroundColor: "transparent",
          color: COLORS.GRAY_COLORS.GRAY_25,
          border: "solid 1px " + COLORS.GRAY_COLORS.GRAY_35
        },
        hover: {
          backgroundColor: COLORS.GRAY_COLORS.GRAY_35,
          color: "#ffffff",
          border: "solid 1px " + COLORS.GRAY_COLORS.GRAY_35
        },
        active: {
          backgroundColor: COLORS.GRAY_COLORS.GRAY_18,
          color: "#ffffff",
          border: "solid 1px " + COLORS.GRAY_COLORS.GRAY_18
        }
      };
    }
    case REGULAR_LOOK: {
      return {
        normal: {
          backgroundColor: COLORS.GRAY_COLORS.GRAY_97,
          color: COLORS.GRAY_COLORS.GRAY_35,
          border: "solid 1px " + COLORS.GRAY_COLORS.GRAY_84
        },
        hover: {
          backgroundColor: COLORS.GRAY_COLORS.GRAY_99,
          color: COLORS.ONELOGIN_GRAY_COLORS.ONELOGIN_BLACK,
          border: "solid 1px " + COLORS.ACTION_COLORS.ONELOGIN_BLUE
        },
        active: {
          backgroundColor: COLORS.GRAY_COLORS.GRAY_96,
          color: COLORS.ONELOGIN_GRAY_COLORS.ONELOGIN_BLACK,
          border: "solid 1px " + COLORS.ACTION_COLORS.BLUE_MID
        }
      };
    }
    default: {
      // link
      return {
        normal: {
          backgroundColor: "transparent",
          color: COLORS.ACTION_COLORS.BLUE_MAIN
        },
        hover: {
          backgroundColor: "rgba(0,0,0,0.05)",
          color: COLORS.ACTION_COLORS.BLUE_MAIN_HOVER
        },
        active: {
          backgroundColor: "rgba(0,0,0,0.1)",
          color: COLORS.ACTION_COLORS.BLUE_MAIN_ACTIVE
        }
      };
    }
  }
};

const Button = forwardRef((props, ref) => <StyledButton ref={ref} {...props} />);

Button.defaultProps = {
  tabIndex: "0",
  disabled: false,
  type: "button",
  size: SIZES.MEDIUM,
  look: LINK_LOOK
};

Button.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  tabIndex: PropTypes.string,
  disabled: PropTypes.bool,
  look: PropTypes.oneOf([PRIMARY_LOOK, REGULAR_LOOK, LINK_LOOK, OUTLINE_LOOK]),
  size: PropTypes.oneOf([SIZES.MEDIUM, SIZES.LARGE])
};

export default Button;
