import styled from "styled-components";
import COLORS from "../../../../constants/colors";

const ScreenHeader = styled.div`
  font-size: 22px;
  color: ${COLORS.GRAY_COLORS.GRAY_25};
  margin-top: 20px;
  margin-bottom: 16px;
  font-weight: normal;
`;

export default ScreenHeader;
