import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import COLORS from "../../../constants/colors";

/** Tooltip Component **
 *
 * This component allows any other component to have a tooltip with customizable location. Accepts these props:
 *
 * - Child (React node): This object is always rendered, and is what the tooltip is rendered relative to
 * - tooltip - (() -> React node): The object to be rendered inside the tooltip area (what effectively IS the tooltip)
 * - open (bool): Determines whether the tooltip is open or not. This component does not maintain its own state, it's
 * -    up to a parent component to manage that.
 * - onFocusLeave (func): When the tooltip is opened, it is also considered focused. When focus leaves the tooltip,
 * -    like when clicking elsewhere on the page, this function is called. Useful for signaling to the parent
 * -    that the tooltip should close
 * - dir ('top' | 'bottom' | 'left' | 'right'): Signals where the tooltip should be rendered relative to the child
 * -    (default is 'bottom')
 * - lean ('up' | 'down' | 'left' | 'right'): This allows the tooltip to be offset, rather than centered. 'left' and
 * -    'right' can only be used when dir is 'top' or 'bottom', and 'up' and 'down' can only be used when dir is
 * -    'left' or 'right'
 * -    (not providing this prop assumes a centered position)
 */

const TRIANGLE_WIDTH = "8px";
const TOOLTIP_MARGIN = "16px";

const getOpposite = dir => {
  switch (dir) {
    case "left":
      return "right";
    case "right":
      return "left";
    case "up":
    case "top":
      return "bottom";
    case "down":
    case "bottom":
      return "top";
    default:
      return null;
  }
};

const perpendicular = dir => {
  switch (dir) {
    case "top":
    case "bottom":
      return "left";
    case "left":
    case "right":
      return "top";
    default:
      return null;
  }
};

const getLean = ({ lean, perp, axis }) => {
  if (lean !== "center") {
    return `${getOpposite(lean)}: 0;`;
  }
  return `${perp}: 50%; transform: translate${axis}(-50%);`;
};

const getMarginProps = (opposite, margin) =>
  `${opposite}: 100%; margin-${opposite}: ${margin};`;

const getBorderProps = (perp, dir) =>
  `border-${perp}: ${TRIANGLE_WIDTH} solid transparent; 
   border-${getOpposite(perp)}: ${TRIANGLE_WIDTH} solid transparent;
   border-${dir}: ${TRIANGLE_WIDTH} solid white;
   ${perp}: 50%;`;

const getOppositeProp = opposite =>
  `${opposite}: calc(100% + ${TOOLTIP_MARGIN} - ${TRIANGLE_WIDTH})`;

const TooltipWrapper = styled.div`
  position: relative;
  outline: none;
`;

const TooltipSpacer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
`;

const TooltipBubble = styled.div`
  position: absolute;
  border-radius: 3px;
  background-color: white;
  pointer-events: auto;

  ${props => getMarginProps(props.opposite, TOOLTIP_MARGIN)}

  ${getLean}
  box-shadow: 0 0 25px ${COLORS.GRAY_COLORS.GRAY_68};
  z-index: 1;
`;

const Tip = styled.div`
  position: absolute;
  content: '';
  ${props => getBorderProps(props.perp, props.dir)}
  transform: translate${props => props.axis}(-50%);
  ${props => getOppositeProp(props.opposite)};
  z-index: 1;
`;

class Tooltip extends Component {
  constructor(props) {
    super(props);
    this.tooltipWrapper = createRef();
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleBodyClick, false);
  }

  componentDidUpdate() {
    if (this.props.open) {
      document.body.addEventListener("click", this.handleBodyClick, false);
    } else {
      document.body.removeEventListener("click", this.handleBodyClick, false);
    }
  }

  handleBodyClick = event => {
    const current = this.tooltipWrapper.current;
    const onFocusLeave = this.props.onFocusLeave;
    if (onFocusLeave && current && !current.contains(event.target)) {
      onFocusLeave();
    }
  };

  render = () => {
    const { children, open, tooltip, dir, lean, ariaLabel } = this.props;
    const opposite = getOpposite(dir);
    const perp = perpendicular(dir);
    const axis = perp === "left" ? "X" : "Y";

    return (
      <TooltipWrapper ref={this.tooltipWrapper} aria-label={ariaLabel}>
        {children}
        {open && (
          <TooltipSpacer>
            <TooltipBubble
              dir={dir}
              lean={lean}
              perp={perp}
              opposite={opposite}
              axis={axis}
            >
              {tooltip()}
            </TooltipBubble>
            <Tip dir={dir} perp={perp} opposite={opposite} axis={axis} />
          </TooltipSpacer>
        )}
      </TooltipWrapper>
    );
  };
}

Tooltip.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  tooltip: PropTypes.func.isRequired,
  onFocusLeave: PropTypes.func,
  dir: PropTypes.string,
  lean: PropTypes.string,
  ariaLabel: PropTypes.string
};

Tooltip.defaultProps = {
  dir: "top",
  lean: "center"
};

export default Tooltip;
