import { useMemo } from "react";
import { symmetricDifference, isEmpty } from "ramda";

import getPasswordValidator from "../../../lib/passwordValidator";

const PasswordComplexity = ({ value, rules, render, ...restProps }) => {
  //useMemo to avoid `incomplete` to be called because of 'different' function
  const passwordValidator = useMemo(() => getPasswordValidator(rules), [rules]);
  //useMemo probably not that much needed, as this renders because of 'value' changes most of the time
  const incomplete = useMemo(() => passwordValidator(value), [
    value,
    passwordValidator
  ]);
  const required = useMemo(() => passwordValidator(""), [passwordValidator]);
  return render({
    invalid: incomplete.includes('length') || 
      (!incomplete.includes('length') && !(rules.complexity.challenges.length - rules.complexity.min >= incomplete.length)),
    challenges: required,
    completedChallenges: symmetricDifference(incomplete, required),
    ...restProps
  });
};

export default PasswordComplexity;
