import styled from "styled-components";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px;
`;

Content.displayName = "Content";

export default Content;
