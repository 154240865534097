import PropTypes from "prop-types";
import styled from "styled-components";
import FONTS from "../../../constants/fonts";
import COLORS from "../../../constants/colors";
import SIZES from "../../../constants/sizes";
import getBorderColorDefinition from "../../utils/getBorderColorDefinition"
import getSizingDefinitions from "../../utils/getSizingDefinitions"
import getBackgroundColor from "../../utils/getBackgroundColor"

const heightMap = {
  [SIZES.MEDIUM]: 100,
  [SIZES.LARGE]: 200,
  [SIZES.BIG]: 200
};

const TextArea = styled.textarea`
  ${props => getSizingDefinitions(props.size, heightMap)};
  box-sizing: border-box;
  width: 100%;
  border: 1px solid ${COLORS.GRAY_COLORS.GRAY_88};
  transition: border-color 0.2s;
  border-radius: 2px;
  font-family: ${FONTS.FONT_FAMILY.DEFAULT};
  color: ${COLORS.TEXT_COLORS.EMPHASIZED};
  letter-spacing: 0.2px;
  padding: 12px;
  line-height: normal;
  background-color: ${props => getBackgroundColor(props)};
  ${props => props.restrictResize && "resize: none"};

  ${props => getBorderColorDefinition(props)};

  &::-ms-clear {
    display: none;
  }

  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
  }

  &::placeholder {
    color: #acadad;
  }
`;

TextArea.defaultProps = {
  autoFocus: false,
  disabled: false,
  invalid: false,
  autoComplete: "off",
  autoCorrect: "off",
  spellCheck: "false",
  large: false,
  focused: false,
  locked: false,
  size: SIZES.MEDIUM,
  restrictResize: false
};

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoCorrect: PropTypes.string,
  spellCheck: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf([SIZES.MEDIUM, SIZES.LARGE, SIZES.BIG]),
  focused: PropTypes.bool,
  locked: PropTypes.bool,
  restrictResize: PropTypes.bool
};

export default TextArea;
