import { useEffect } from "react";

/**
 * keydown hander for given ref element
 * - only works when element is active
 * - handlers is a map of {keyCode: handler}
 * - doesn't support special characters (ignores them)
 */
const useKeyDown = (ref, handlers = {}) => {
  const onKeyDown = event => {
    handlers[event.keyCode] && handlers[event.keyCode](event);
  };

  useEffect(() => {
    const current = ref.current;
    current && current.addEventListener("keydown", onKeyDown);

    return () => {
      current && current.removeEventListener("keydown", onKeyDown);
    };
  });
};

export default useKeyDown;
