import { css } from "styled-components";
import COLORS from "../../constants/colors"

const getBorderColorDefinition = ({
  focused,
  disabled,
  invalid,
  hovered
}) => {
  if (invalid) {
    return css`
      border-color: ${COLORS.STATUS_COLORS.ERROR};
    `;
  }
  if (focused) {
    return css`
      border-color: ${COLORS.ACTION_COLORS.ONELOGIN_BLUE};
    `;
  }
  if (disabled) {
    return css`
      border-color: ${COLORS.GRAY_COLORS.GRAY_94};
    `;
  }

  if (hovered) {
    return css`
      border-color: ${COLORS.GRAY_COLORS.GRAY_68};
    `;
  }

  return css`
    &:hover {
      border-color: ${COLORS.GRAY_COLORS.GRAY_68};
    }

    &:focus {
      border-color: ${COLORS.ACTION_COLORS.ONELOGIN_BLUE};
    }
  `;
};

export default getBorderColorDefinition;
