import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduce, filter, isEmpty, mapObjIndexed, complement } from "ramda";

class MultiValueForm extends Component {
  constructor(props) {
    super(props);
    this.state = reduce(
      (acc, fieldName) => {
        acc[fieldName] = { value: "", invalid: false };
        return acc;
      },
      {},
      props.fieldNames
    );
  }

  getOnChange = fieldName => value => {
    this.setState({
      [fieldName]: { value, invalid: false }
    });
  };

  isValid = fieldName => this.state[fieldName].value.length >= 1;

  onSubmit = event => {
    event.preventDefault();

    const invalidFieldNames = filter(
      complement(this.isValid),
      this.props.fieldNames
    );
    if (isEmpty(invalidFieldNames)) {
      // all forms input have valid value
      const fieldToValueMap = reduce(
        (acc, fieldName) => {
          acc[fieldName] = this.state[fieldName].value;
          return acc;
        },
        {},
        this.props.fieldNames
      );
      this.props.onSubmit(fieldToValueMap);
    } else {
      this.setState(
        reduce(
          (acc, fieldName) => {
            acc[fieldName] = { ...this.state[fieldName], invalid: true };
            return acc;
          },
          {},
          invalidFieldNames
        )
      );
    }
  };

  render = () => (
    <form onSubmit={this.onSubmit}>
      {this.props.render({
        // to make usage easier I inject onChange method to field data object
        ...mapObjIndexed(
          (value, key) => ({
            ...value,
            onChange: this.getOnChange(key)
          }),
          this.state
        )
      })}
    </form>
  );
}

MultiValueForm.displayName = "MultiValueForm";

MultiValueForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fieldNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  render: PropTypes.func.isRequired
};

export default MultiValueForm;
