import React from "react";
import Icon from "../Icon/Icon";
import styled from "styled-components";
import COLORS from "../../../constants/colors";

const UnorderedList = styled.ul`
  border-radius: 2px;
  padding: 16px;
  margin: 0;
  background-color: ${COLORS.GRAY_COLORS.GRAY_96};
`;

const ListItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  font-family: Roboto;
  font-size: 13px;
  line-height: 24px;
  color: ${props =>
    props.active ? COLORS.TEXT_COLORS.EMPHASIZED : COLORS.TEXT_COLORS.LABEL};
  margin-left: 8px;
  display: inline-block;
`;

export const ToDoItem = ({ done, label }) => {
  return (
    <ListItem>
      {done ? (
        <Icon icon="Success-16" size="16" />
      ) : (
        <Icon icon="Circle_disabled-16" size="16" />
      )}
      {done ? <Label active>{label}</Label> : <Label>{label}</Label>}
    </ListItem>
  );
};

export default ({ list }) => {
  const itemList = list.map((item, i) => (
    <ToDoItem label={item.text} done={item.complete} key={i} />
  ));

  return <UnorderedList>{itemList}</UnorderedList>;
};
