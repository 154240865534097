import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import SingleValueForm from "../../../../hocs/SingleValueForm/SingleValueForm";
import Label from "../../../../ui/FormField/Label";
import Button from "../../../../ui/Button/Button";
import Content from "../../../../ui/Content/Content";
import ContentBlock from "../../../../ui/ContentBlock/ContentBlock";
import Input from "../../../../ui/FormField/Input";
import { isValidEmail } from "../../../../utils/inputValidations";

const EnterEmail = ({ onSubmitEmail, email }) => (
  <SingleValueForm
    onSubmit={onSubmitEmail}
    validateFn={isValidEmail}
    value={email}
    render={({ invalid, value, onChange }) => (
      <Content data-testid="enter-email-screen">
        <ContentBlock>
          <Label forId="enter-email">
            <FormattedMessage defaultMessage="Enter your email" />
          </Label>
          <ContentBlock>
            <Input
              id="enter-email"
              onChange={event => onChange(event.target.value)}
              value={value}
              invalid={invalid}
            />
          </ContentBlock>
        </ContentBlock>
        <ContentBlock>
          <Button size="large" type="submit" look="primary">
            <FormattedMessage defaultMessage="Continue" />
          </Button>
        </ContentBlock>
      </Content>
    )}
  />
);

EnterEmail.displayName = "EnterEmail";

EnterEmail.propTypes = {
  onSubmitEmail: PropTypes.func.isRequired,
  email: PropTypes.string
};

export default EnterEmail;
