import { createContext, useContext } from "react";

const ProjectRootContext = createContext();

//just to have nicer provider name, instead of ProjectRootContext.Provider
const ProjectRootProvider = ProjectRootContext.Provider;

const useProjectRoot = () => {
  const projectRoot = useContext(ProjectRootContext);

  if (projectRoot === undefined) {
    throw new Error("useProjectRoot must be used within a ProjectRootProvider");
  }

  return projectRoot;
};

export { ProjectRootProvider, useProjectRoot };
