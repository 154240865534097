import styled from "styled-components";
import COLORS from "../../../constants/colors";

const MenuItem = styled.button`
  padding: 10px;
  color: #333333;
  cursor: pointer;
  transition: background-color 0.1s;
  background: #ffffff;
  border: none;
  font-size: 14px;
  display: block;
  width: 100%;
  text-align: left;

  &:hover {
    background-color: ${COLORS.ACTION_COLORS.BLUE_ITEM_HIGHLIGHT};
  }
`;

export default MenuItem;
