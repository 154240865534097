import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Label from "../../../../ui/FormField/Label";
import Input from "../../../../ui/FormField/Input";
import Button from "../../../../ui/Button/Button";
import Content from "../../../../ui/Content/Content";
import ContentBlock from "../../../../ui/ContentBlock/ContentBlock";
import MultiValueForm from "../../../../hocs/MultiValueForm/MultiValueForm";
import PasswordInput from "../../../../ui/FormField/PasswordInput";
  
const RegisterOath = ({ onSubmit }) => (
  <MultiValueForm 
    fieldNames={["serialNumber", "securityCode"]}
    onSubmit={onSubmit}
    render={({ serialNumber, securityCode }) => (
    <Content data-testid="register-oath-screen">
      <ContentBlock>
        <Label forId="serial-number">
            <FormattedMessage defaultMessage="Enter the serial number printed on the back of your device" />
        </Label>
        <Input
        id="serial-number"
        value={serialNumber.value}
        onChange={event => serialNumber.onChange(event.target.value)}
        invalid={serialNumber.invalid}
        autoFocus
        size="large"
        type="text"
        />
      </ContentBlock>
      <ContentBlock>
        <Label forId="security-code">
          <FormattedMessage defaultMessage="Press the button on your device and enter the displayed OTP code" />
        </Label>
        <PasswordInput
          data-testid="security-code"
          id="security-code"
          value={securityCode.value}
          onChange={event => securityCode.onChange(event.target.value)}
          invalid={securityCode.invalid}
          size="large"
          type="text"
        />
      </ContentBlock>
      <ContentBlock>
        <Button size="large" type="submit" look="primary">
          <FormattedMessage defaultMessage="Continue" />
        </Button>
      </ContentBlock>
    </Content>
    )}
  /> 
); 

RegisterOath.displayName = "RegisterOath"; 

RegisterOath.propTypes = {
  onSubmit: PropTypes.func.isRequired
}; 

export default RegisterOath; 
