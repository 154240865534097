import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Label from "../../../../ui/FormField/Label";
import Input from "../../../../ui/FormField/Input";
import Button from "../../../../ui/Button/Button";
import Content from "../../../../ui/Content/Content";
import ContentBlock from "../../../../ui/ContentBlock/ContentBlock";
import MultiValueForm from "../../../../hocs/MultiValueForm/MultiValueForm";
import PasswordInput from "../../../../ui/FormField/PasswordInput";

const RegisterSecureId = ({ onSubmit }) => (
  <MultiValueForm
    fieldNames={["username", "securityCode"]}
    onSubmit={onSubmit}
    render={({ username, securityCode }) => (
      <Content data-testid="register-secure-id-screen">
        <ContentBlock>
          <div>
            <FormattedMessage defaultMessage="Enter your username and security code from your SecurID token." />
          </div>
        </ContentBlock>
        <ContentBlock>
          <Label forId="username">
            <FormattedMessage defaultMessage="Username" />
          </Label>
          <Input
            id="username"
            value={username.value}
            onChange={event => username.onChange(event.target.value)}
            invalid={username.invalid}
            size="large"
          />
        </ContentBlock>
        <ContentBlock>
          <Label forId="security-code">
            <FormattedMessage defaultMessage="Security Code" />
          </Label>
          <PasswordInput
            data-testid="security-code"
            id="security-code"
            onChange={event => securityCode.onChange(event.target.value)}
            value={securityCode.value}
            invalid={securityCode.invalid}
            size="large"
          />
        </ContentBlock>
        <ContentBlock>
          <Button size="large" type="submit" look="primary">
            <FormattedMessage defaultMessage="Continue" />
          </Button>
        </ContentBlock>
      </Content>
    )}
  />
);

RegisterSecureId.displayName = "RegisterSecureId";

RegisterSecureId.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default RegisterSecureId;
