import { union } from "ramda";

const CHALLENGES = {
  lower: /[a-z]/,
  upper: /[A-Z]/,
  numbers: /[0-9]/,
  specials: /[^a-zA-Z0-9]/,
  letters: /[a-zA-Z]/
};

export default rules => {
  const { challenges, min, required } = rules.complexity;
  const { length: requiredPasswordLength } = rules;

  return password => {
    const errors = challengesMet(challenges, password, min);
    const requiredErrors = challengesMet(required, password);

    if (password.length < requiredPasswordLength) {
      requiredErrors.unshift("length");
    }

    if (requiredErrors.length) {
      return union(requiredErrors, errors);
    } else {
      return errors;
    }
  };
};

const challengesMet = (challenges, password, min = Infinity) => {
  const errors = challenges.filter(name => !CHALLENGES[name].test(password));
  return errors;
};
