import React, { useContext, useEffect, useState } from "react";
import styled, { ThemeContext } from "styled-components";

import Dropdown from "./Dropdown";
import MenuItemStyles from "./MenuItemStyles";
import MenuTitle from "./MenuTitle";
import PropTypes from "prop-types";
import { createNewEvent } from "../../utils/events";
import { useLocation } from "react-router-dom";

const Li = styled.li`
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;

  line-height: 1.43;
  cursor: pointer;
  text-align: left;

  outline-offset: -5px;
  //TODO: discuss max-width?; due to translations
  display: flex;
  flex-direction: ${props =>
    props.isMobile && props.hasDropdown ? "column" : "row"};

  align-items: center;
  justify-content: space-between;

  position: relative;
  margin: 0;
  margin-left: ${props => (props.isRight ? "auto" : 0)};
  background-color: ${({ toggled, theme }) =>
    toggled ? theme.dropdownBgColor : "inherit"};
  color: ${({ toggled, theme }) =>
    toggled ? theme.dropdownTextColor : theme.textColor};
  border-bottom: ${({ isMobile, theme }) =>
    isMobile ? `${theme.dropdownMobileItemSeparator} 1px solid` : "none"};

  :focus-within,
  :hover {
    background-color: ${({ isMobile, theme }) =>
    isMobile ? `${theme.primaryColor}` : `${theme.dropdownBgColor}`};
  }

  > a {
    ${MenuItemStyles}
    display: block;
  }

  > button {
    ${MenuItemStyles}
  }
`;

export default function Menu({
  listen,
  setListen,
  alwaysListen,
  isMobile,
  menu = []
}) {
  const [isActive, setIsActive] = useState(false);
  const [expanded, setMenuExpanded] = useState(false);
  const theme = useContext(ThemeContext);
  const location = useLocation();

  // this uses the useLocation() hook as an event to trigger each time
  // the location is updated. Unfortunately, the location pathname
  // doesn't include the basename so we have to compare with the
  // window.location.pathname. If there is a match, set it to active
  // so the MenuTitle can draw a blue line under the menu name
  useEffect(() => {
    const currentPath = window.location.pathname;
    const links = [];

    if (menu.href) {
      links.push({ href: menu.href });
    }

    if (menu.links) {
      links.push(...menu.links);
    }

    const matches = links.filter(link => currentPath.startsWith(link.href));
    setIsActive(matches.length > 0);
  }, [menu.href, menu.links, location]);

  // When a menu is clicked, dispatch a "hideMenu" event,
  // toggle listenting (stop if listening, start if not listenting)
  // and toggle show (show if hidden, hide if showing).
  const menuClicked = event => {
    event.stopPropagation();
    window.dispatchEvent(createNewEvent("hideMenu"));
    setListen(!listen);
    setMenuExpanded(!expanded);
  };

  // if a menu is entered, fire a "hideMenu" event to tell everyone
  // else to go away. If this menu is in listening mode, it should
  // show itself
  const mouseEnter = () => {
    if (isMobile) {
      return;
    }

    window.dispatchEvent(createNewEvent("hideMenu"));
    if (listen) {
      setMenuExpanded(true);
    }
  };

  // when leaving, hide, unless we are in Apple Menu Mode or
  // on mobile. This makes the menus "sticky". They stay open
  // until clicked
  const mouseLeave = () => {
    if (!alwaysListen || isMobile) {
      return;
    }
    setMenuExpanded(false);
  };

  // if any menu is focused, it fires a hideMenu event. Every menu
  // listening for this event will hide, including the one that
  // fired the event, but then it shows itself immediately after
  useEffect(() => {
    const focusHandler = () => setMenuExpanded(false);
    window.addEventListener("hideMenu", focusHandler);
    return () => window.removeEventListener("hideMenu", focusHandler);
  }, []);

  if (menu.length === 0) return null;

  const hasDropdown = menu.links && menu.links.length > 0;

  return (
    <Li
      isMobile={isMobile}
      theme={theme}
      toggled={expanded}
      isRight={menu.align === "right"}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      onClick={menuClicked}
      hasDropdown={hasDropdown}
    >
      <MenuTitle
        data={menu}
        expanded={expanded}
        hasDropdown={hasDropdown}
        isMobile={isMobile}
        isActive={isActive}
      />
      {hasDropdown && (
        <Dropdown
          isMobile={isMobile}
          expanded={expanded}
          data={menu.links}
          isRight={menu.alignDropdown === "right"}
        />
      )}
    </Li>
  );
}

Menu.propTypes = {
  listen: PropTypes.bool,
  setListen: PropTypes.func,
  alwaysListen: PropTypes.bool,
  isMobile: PropTypes.bool,
  menu: PropTypes.object
};
