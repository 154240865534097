import React, { Children } from "react";
import styled from "styled-components";

const StyledList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: left;
  margin: 0;
  padding: 0;
  line-height: 2;
  font-size: 14px;
`;

const StyledItem = styled.li`
  flex-basis: auto;
  padding: 0 5px;
  text-align: left;
`;

const VerticalList = ({ children }) => (
  <StyledList>
    {Children.map(children, child => child && <StyledItem>{child}</StyledItem>)}
  </StyledList>
);

VerticalList.propTypes = {};

export default VerticalList;
