import React from "react";
import PropTypes from "prop-types";

import ListItem from "../../ui/ListItem/ListItem";

import getIcon from "./iconProvider/IconProvider";

const SelectMFADevice = ({ devices, onSelect }) => (
  <div data-testid="devices-list">
    {devices.map(device => (
      <ListItem
        key={device.id}
        icon={device.customIconUrl || getIcon(device.factorTypeId)}
        text={device.factorName}
        onClick={() => onSelect(device.id)}
      />
    ))}
  </div>
);

SelectMFADevice.displayName = "SelectMFADevice";

SelectMFADevice.propTypes = {
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      /** Text representation of link */
      factorName: PropTypes.string.isRequired,
      /** On link click handler */
      id: PropTypes.number.isRequired,
      /** MFA device type id */
      factorTypeId: PropTypes.number.isRequired,
      /** Custom icon set for device factor */
      customIconUrl: PropTypes.string,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired
};

export default SelectMFADevice;
